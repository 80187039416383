<script setup lang="ts" generic="T">
import { computed } from 'vue';

const emit = defineEmits(['update', 'update:modelValue']);

const props = defineProps<{
  modelValue?: boolean | Set<T>;
  value?: number;

  disabled?: boolean;
  elevate?: boolean;
  noBorder?: boolean;
}>();

const isChecked = computed(() => {
  const isBoolean = typeof props.modelValue === 'boolean';
  return isBoolean ? props.modelValue : props.modelValue.has(props.value);
});

function update() {
  if (typeof props.modelValue === 'boolean') {
    emit('update:modelValue', !props.modelValue);
    emit('update', !props.modelValue);
  } else {
    const newSet = props.modelValue;

    props.modelValue.has(props.value) ? newSet.delete(props.value) : newSet.add(props.value);

    emit('update:modelValue', newSet);
    emit('update', newSet);
  }
}
</script>

<template>
  <label
    class="df-input"
    :class="{
      'df-input--disabled': disabled,
      'df-input--elevate': elevate,
      '--no-border': noBorder,
    }"
  >
    <input
      class="input-input"
      type="checkbox"
      v-on:input="update"
      :id="modelValue"
      :name="modelValue"
      :checked="isChecked"
      :disabled="disabled"
    />

    <div class="input-checkbox">
      <df-icon :code="isChecked ? 'f14a' : 'f0c8'" />
    </div>

    <div class="input-label" v-if="$slots.default">
      <slot></slot>
    </div>
  </label>
</template>

<style scoped>
.df-input {
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);
  padding: var(--gap-md);

  display: grid;
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  gap: var(--gap-md);
}

.df-input:hover:not(.df-input--disabled) {
  background-color: var(--color-hover);
  border-color: var(--color-hover);
}

.df-input--disabled {
  opacity: 0.5;
}

.df-input--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-sm);
  border: none;
}

.input-input {
  display: none;
}

.--no-border {
  padding: var(--gap-sm);
  border: none;
}
</style>
