import { createApp } from 'vue';
import App from './components/app/App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia';

import { globalRoutes } from './routes/global';
import { ressursRoutes } from './routes/ressurs';
import { timerRoutes } from './routes/timer';

import './utilities/api';
import './styles/main.css';

import './utilities/api';
import { awaitUser, useUserStore } from './stores/user';
import { Modules } from './types/ids';
import { installComponents } from './components';
import { useSentry } from './plugins/sentry';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js');
}

const app = createApp(App);
const router = createRouter({
  history: createWebHistory(),
  routes: [...globalRoutes, ...timerRoutes, ...ressursRoutes],
});
// Router module check
router.beforeEach(async (to) => {
  const userStore = useUserStore();
  await awaitUser;

  if (to.path.includes('timer')) {
    return userStore.hasModule(Modules.Timer) || '/resources/events';
  }

  if (to.path.includes('resources')) {
    return userStore.hasModule(Modules.Ressurs) || '/timer/week';
  }
});

// Vue plugins
app.use(router);
app.use(createPinia());

useSentry(app, router);

// Vue global components
installComponents(app);

// Mount vue app to DOM
app.mount('#app');
