<script setup lang="ts">
import { ref, computed } from 'vue';
import { useTenantStore } from '/@stores/tenant';
import { useProjectStore } from '/@features/project';
import { toast } from '/@utilities/toast';

const props = defineProps<{ projectId: number }>();
const emit = defineEmits(['close']);

const form = ref<{ installer: number | null; statusId: number | null }>({
  installer: null,
  statusId: null,
});
const formSubmit = ref(false);

const projectStore = useProjectStore();
const project = computed(() => projectStore.projects.get(props.projectId));

function submitForm() {
  formSubmit.value = true;
  return Promise.all([
    updateStatus(),
    projectStore.updateProject(props.projectId, {
      installer: tenantStore.installers.get(Number(form.value.installer))?.name,
    }),
  ])
    .then(() => projectStore.loadProjectById(props.projectId))
    .then(() => {
      emit('close');
      toast('Prosjekt oppdatert');
    })
    .finally(() => {
      formSubmit.value = true;
    });
}

function updateStatus() {
  if (form.value.statusId === project.value?.statusId) return;
  return projectStore.updateStatus(props.projectId, form.value.statusId);
}

const loading = ref(0);

const tenantStore = useTenantStore();

const projectStatuses = ref(new Map());

async function initialize() {
  loading.value++;

  return Promise.all([
    projectStore.loadStatuses({ projectTypeIds: [project.value?.typeId] }),
    tenantStore.loadInstallers(),
  ]).then(([pStatuses, _]) => {
    projectStatuses.value = pStatuses;

    form.value.statusId = project.value?.statusId;
    form.value.installer =
      [...tenantStore.installers.values()].find((i) => i.name === project.value?.installer)?.id ||
      null;

    loading.value--;
  });
}

initialize();
</script>

<template>
  <df-modal>
    <section class="project-edit">
      <DfHeader v-on:click="emit('close')" back no-route> Rediger prosjekt </DfHeader>

      <DfLoading v-if="loading" />

      <form class="project-form" v-on:submit.stop.prevent="submitForm()">
        <df-select3
          label="Ansvarlig montør"
          :entries="tenantStore.installers"
          display="name"
          v-model="form.installer"
          elevate
        />

        <df-select3
          label="Status"
          :entries="projectStatuses"
          v-model="form.statusId"
          display="name"
          elevate
          :use-sort="false"
        />

        <div class="actions">
          <df-button v-on:click="emit('close')">Avbryt</df-button>

          <df-button primary elevate type="submit" :disabled="formSubmit">Lagre</df-button>
        </div>
      </form>
    </section>
  </df-modal>
</template>

<style scoped>
.project-edit {
  display: grid;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
}

.project-form {
  display: grid;
  gap: var(--gap-lg);

  & > .actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: var(--gap-md);
  }
}
</style>
