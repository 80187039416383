<script setup lang="ts">
import { computed, ref, watch } from 'vue';

interface TabOptions {
  id: any;
  name: string;
  icon?: string;
}

const props = defineProps<{
  vertical?: boolean;
  modelValue?: string;

  tabs: TabOptions[];
}>();

const emit = defineEmits(['update:model-value']);

const selectedTab = ref(props.modelValue ?? null);
const activeTab = computed(() => selectedTab.value ?? props.tabs[0]?.id);

watch(
  () => props.modelValue,
  (value) => (selectedTab.value = value),
);
watch(activeTab, (value) => emit('update:model-value', value));
</script>

<template>
  <div class="tabs-container" :class="{ 'tabs-container--vertical': vertical }">
    <ul class="tab-list">
      <li v-for="{ id, name, icon } in tabs" :key="id">
        <button
          class="tab-button"
          :class="{ 'tab-button--active': activeTab === id }"
          v-on:click="selectedTab = id"
        >
          <df-icon v-if="icon" class="tab-icon" :code="icon" />
          <span class="tab-button__text">{{ name }}</span>
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <slot :name="`tab:${activeTab}`">
        <em>«{{ activeTab }}» er foreløpig tom</em>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.tabs-container {
  display: grid;
  gap: var(--gap-lg);

  @media (max-width: 900px) {
    grid-template-columns: 1fr;

    & .tab-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-right: none;
      padding-right: 0;
      padding-bottom: var(--gap-lg);
      border-bottom: 1px solid var(--color-divider);
    }
  }
}

.tabs-container--vertical {
  grid-template-columns: 30ch 1fr;

  & .tab-list {
    flex-direction: column;
    border-bottom: 0;
    padding-bottom: 0;
    border-right: 1px solid var(--color-divider);
    padding-right: var(--gap-lg);
  }
}

.tab-list {
  min-width: 0;
  display: flex;
  gap: var(--gap-sm);
  border-bottom: 1px solid var(--color-divider);
  padding-bottom: var(--gap-md);
}

.tab-button {
  min-width: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: var(--gap-md);
  border-radius: var(--radius-sm);
  color: var(--color-link);
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: var(--gap-md);

  &:hover {
    background-color: var(--color-hover);
  }
}

.tab-button--active {
  background-color: var(--color-active);
  color: var(--color-text);
}

.tab-button__text {
  flex: 1 1 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab-icon {
  font-size: 1.1rem;
}
</style>
