<script setup lang="ts">
import { Draggable } from '@fullcalendar/interaction';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  name: string;
  data: object;
}>();

const eventEl = ref<null | HTMLDivElement>(null);
const eventData = computed(() => ({
  create: true,
  title: props.name,
  extendedProps: props.data,
  startTime: '08:00',
  duration: '08:00',

  // Initialize it in a "loading"-state while it's being posted to server
  editable: false,
  backgroundColor: 'var(--color-event-locked)',
}));

watch(eventEl, (el) => {
  if (el != null) new Draggable(el.children.item(0) as HTMLElement, { eventData: eventData.value });
});
</script>

<template>
  <div class="event" :ref="(el: HTMLDivElement) => eventEl = el">
    <slot></slot>
  </div>
</template>

<style scoped>
.event {
  display: contents;
  font-size: 0.9rem;
}
</style>
