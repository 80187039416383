<script setup lang="ts">
function getSubdomain() {
  const url = window.location.href;
  if (url.includes('uat')) return 'uat.';
  if (url.includes('test')) return 'test.';
  return '';
}
</script>

<template>
  <div class="adm-project-page">
    <df-alert show persistent>
      <div style="display: grid; gap: var(--gap-md)">
        <p>Denne siden har blitt flyttet til administrasjon i webapp.</p>

        <df-button :href="`https://webapp.${getSubdomain()}dokflyt.no/admin/projects`" elevate>
          Gå til webapp
        </df-button>
      </div>
    </df-alert>
  </div>
</template>

<style scoped></style>
