<script setup lang="ts">
import { computed, ref } from 'vue';
import { api } from '/@utilities/api';
import { toast } from '/@utilities/toast';

const form = ref({
  oldPW: null,
  newPW: null,
  newPW2: null,
});

const showPW = ref(false);

const isMatch = computed(() => form.value.newPW && form.value.newPW === form.value.newPW2);

const isValid = computed(() => form.value.oldPW && isMatch.value);

function submitChangePassword() {
  if (!isValid.value) return;

  return changeUserPassword({
    oldPW: form.value.oldPW,
    newPW: form.value.newPW,
  }).then(() => {
    toast('Passord endret');
    form.value = {
      oldPW: null,
      newPW: null,
      newPW2: null,
    };
  });
}

function changeUserPassword({ oldPW, newPW }) {
  return api.post('account/changepassword', {
    OldPassword: oldPW,
    NewPassword: newPW,
  });
}
</script>

<template>
  <section class="sub-page">
    <df-header>Endre passord</df-header>

    <df-form :submit="submitChangePassword" :disabled="!isValid">
      <df-input label="Gammelt passord" v-model="form.oldPW" inputmode="password" elevate />

      <div class="group">
        <div class="input-group">
          <df-input
            label="Nytt passord"
            v-model="form.newPW"
            :inputmode="showPW ? null : 'password'"
            elevate
          />

          <df-button v-on:click="showPW = !showPW" elevate>
            <template v-slot:icon>
              <df-icon :code="showPW ? 'f06e' : 'f070'" />
            </template>
          </df-button>
        </div>

        <df-input
          label="Bekreft nytt passord"
          v-model="form.newPW2"
          :inputmode="showPW ? null : 'password'"
          elevate
        />
      </div>
    </df-form>
  </section>
</template>

<style scoped>
.group {
  display: grid;
  gap: var(--gap-md);
}
</style>
