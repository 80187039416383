<script lang="ts" setup>
import { ref, computed } from 'vue';
import DfCalendar from '/@elements/DfCalendar/DfCalendar.vue';

import ResourceItem from './ResourceItem.vue';
import { useResourceEvents } from './UseResourceEvents';
import { Resource, ResourceFilter } from '/@types/resources';
import { loadResourceFilter, loadResources } from '/@stores/resources';

const resources = ref<Array<Resource>>([]);
const filters = ref<Array<ResourceFilter>>([]);

const { loading, selectedDate, events, groupSearch, selectedGroupUsers, userSearch, dateChange } =
  useResourceEvents({
    byUser: false,
  });


const mappedResources = computed(() => {
  const filteredResources = selectedGroupUsers.value.length > 0 
    ? resources.value.filter((res) => selectedGroupUsers.value.includes(res.guid))
    : resources.value;

  return new Map(filteredResources.map((res) => [res.guid, { name: res.name }]));
});

const mappedGroups = computed(
  () => new Map(filters.value.map((res) => [res.id, { name: res.name }])),
);

function initialize() {
  Promise.all([
    loadResources({ ungrouped: true }).then((data) => (resources.value = data || [])),
    loadResourceFilter().then((res) => (filters.value = res || [])),
  ]);
}

initialize();
</script>

<template>
  <div class="page page--wide">
    <header>
      <h1>Alle avtaler</h1>

      <nav class="header-nav">
        <div class="header-nav__select">
          <df-select3
            elevate
            label="Gruppe"
            v-model="groupSearch"
            :entries="mappedGroups"
            display="name"
          >
          </df-select3>

          <df-button v-if="groupSearch" v-on:click="groupSearch = null" elevate>
            <template v-slot:icon>
              <df-icon code="f00d" />
            </template>
          </df-button>
        </div>

        <div class="header-nav__select">
          <df-select3
            elevate
            label="Bruker"
            v-model="userSearch"
            :entries="mappedResources"
            display="name"
          >
          </df-select3>

          <df-button v-if="userSearch" v-on:click="userSearch = null" elevate>
            <template v-slot:icon>
              <df-icon code="f00d" />
            </template>
          </df-button>
        </div>
      </nav>
    </header>

    <df-loading v-if="loading > 0" />

    <df-calendar
      class="page__calendar"
      view="dfListDay"
      buttons="dfListWeek,dfListDay"
      display-user
      :events="events"
      :resources="resources"
      v-on:date-change="dateChange($event)"
      v-on:day-click="selectedDate = $event"
    >
      <template v-slot:event="{ event, close }">
        <ResourceItem :event="event" :close="close" :day="selectedDate" />
      </template>
    </df-calendar>
  </div>
</template>

<style scoped>
.page {
  padding-top: calc(var(--gap-md) + env(safe-area-inset-top));
  padding-left: calc(var(--gap-md) + env(safe-area-inset-left));
  padding-right: calc(var(--gap-md) + env(safe-area-inset-right));
  padding-bottom: var(--gap-md);
  display: grid;
  grid-template-rows: max-content 1fr;
}

.page,
.page__calendar {
  max-width: 120ch;
  margin: 0 auto;
  min-height: 100%;
}

header {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
}

.header-nav {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.header-nav__select {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  gap: var(--gap-md);
}
</style>
