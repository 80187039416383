import { RouteRecordRaw } from 'vue-router';

import SchedulePage from '/@pages/SchedulePage/SchedulePage.vue';
import ResourcesSearchPage from '/@pages/ResourcesSearchPage/ResourcesSearchPage.vue';
import ResourceGroupsPage from '/@pages/ResourceGroupsPage/ResourceGroupsPage.vue';
import ResourceUserPage from '/@pages/ResourceEventsPage/ResourceUserPage.vue';
import ResourceAllPage from '/@pages/ResourceEventsPage/ResourceAllPage.vue';
import ScheduleFilterPage from '/@pages/ScheduleFilterPage/ScheduleFilterPage.vue';

export const ressursRoutes: Array<RouteRecordRaw> = [
  {
    path: '/resources/all',
    component: ResourceAllPage,
  },
  {
    path: '/resources/events',
    component: ResourceUserPage,
    props: ({ query }) => ({ view: query.view }),
  },
  {
    path: '/resources/schedule',
    component: SchedulePage,
  },
  {
    path: '/resources/search',
    component: ResourcesSearchPage,
  },
  {
    path: '/resources/groups/:filterId?',
    component: ResourceGroupsPage,
    props: ({ params }) => ({ filterId: Number(params.filterId) }),
  },
  {
    path: '/resources/schedulefilter',
    component: ScheduleFilterPage,
  },
];
