<script setup lang="ts">
import { inject } from 'vue';

const props = defineProps<{
  noIcon?: boolean;
  closeOnClick?: boolean;
}>();

const dropdownClose = inject('dropdown-close') as () => void;

function onClick() {
  if (props.closeOnClick) dropdownClose();
}
</script>

<template>
  <div class="dropdown-item" :class="{ 'dropdown-item--no-icon': noIcon }" v-on:click="onClick()">
    <slot name="icon" v-if="!noIcon">
      <div class="icon-spacer"></div>
    </slot>

    <slot></slot>
  </div>
</template>

<style scoped>
.dropdown-item {
  display: grid;
  grid-template-columns: [icon] 2rem [content] 1fr;
  width: 100%;
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  text-align: left;
  padding: var(--gap-md);
  gap: var(--gap-md);
}

.dropdown-item--no-icon {
  grid-template-columns: [content] 1fr;
}
</style>
