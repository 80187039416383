<script setup lang="ts">
import { provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '/@stores/user';
import { loadColors } from '/@stores/colors';
import { useTenantStore } from '/@stores/tenant';
import { useProjectStore } from '/@features/project';

import AppNav from './AppNav.vue';
import AppShortcuts from './AppShortcuts.vue';
import AppToasts from './AppToasts.vue';
import { useTimerStore } from '/@stores/timer';

const navOpen = ref(false);
const navCollapsed = ref(JSON.parse(localStorage.getItem('nav/collapsed')) ?? false);

function toggleNav(value = !navOpen.value) {
  navOpen.value = value;
}

provide('toggle-app-nav', toggleNav);

const route = useRoute();

watch(
  () => route.path,
  () => {
    toggleNav();
    navOpen.value = false;
  },
);

watch(navCollapsed, (value) => localStorage.setItem('nav/collapsed', String(value)));

const userStore = useUserStore();
const tenantStore = useTenantStore();
const projectStore = useProjectStore();
const timerStore = useTimerStore();

function initialize() {
  Promise.all([
    userStore.loadUser().then(() => timerStore.loadRejectedRegistrations(userStore.user.id)),
    tenantStore.loadTenant().then(() => tenantStore.loadModules()),
    projectStore.loadProjectTypes(),
    loadColors(),
  ]);
}

initialize();
</script>

<template>
  <div class="app" :class="{ 'app--nav-open': navOpen, 'app--nav-collapsed': navCollapsed }">
    <div class="app__nav-container" v-on:click.self="navOpen = false">
      <app-nav class="app__nav" v-model:collapsed="navCollapsed" />
    </div>

    <main>
      <router-view />
    </main>

    <app-shortcuts class="app__shortcuts" />
  </div>

  <app-toasts />
</template>

<style scoped>
.app {
  display: grid;

  @media (min-width: 1000px) {
    grid-template-columns: [nav] 15rem [main] 1fr;
  }

  @media (max-width: 999.5px) {
    grid-template-rows: [main] 1fr [shortcuts] 4rem;
  }
}

.app--nav-collapsed {
  @media (min-width: 1000px) {
    grid-template-columns: [nav] 3.25rem [main] 1fr;
  }
}

.app--nav-open .app__nav-container {
  background-color: var(--color-backdrop);
}

.app__nav-container {
  max-height: 100vh;
  overflow: auto;
  z-index: 1;

  @media (min-width: 1000px) {
    grid-column: nav;
    position: sticky;
    top: 0;
  }

  @media (max-width: 999.5px) {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 999.5px) {
  .app__nav-container {
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }

  .app--nav-open .app__nav-container {
    transform: translateX(0);
    transition: background-color 0.25s ease-in-out;
  }

  .app__nav {
    transform: translateX(-100%);
    transition: 0.25s ease-in-out;
  }

  .app--nav-open .app__nav {
    transform: translateX(0);
    transition: 0.25s ease-in-out;
  }
}

.app__shortcuts {
  @media (min-width: 1000px) {
    display: none;
  }

  @media (max-width: 999.5px) {
    grid-row: shortcuts;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}
</style>
