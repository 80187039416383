interface ValidationResult {
  ok: boolean;
  message: string | null;
}

export type ValidationRule = [boolean, string];

export function validateForm(validation: Array<ValidationRule>): ValidationResult {
  const fault = validation?.find(([rule]) => rule);

  if (fault != null) {
    return {
      ok: false,
      message: fault[1],
    };
  }

  return {
    ok: true,
    message: null,
  };
}
