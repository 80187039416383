import { ref } from 'vue';
import { api, genericApiErrorHandler } from '/@utilities/api';

interface Color {
  id: number,
  name: string,
  hex: string,
}

function mapColor(res): Color {
  return {
    id: res.Id,
    name: res.Name,
    hex: res.HexCode,
  }
}

export const colors = ref<Color[]>([]);

export function loadColors() {
  api
    .get('/colors')
    .then(({ data }) => colors.value = data.map((res) => mapColor(res)))
    .catch(genericApiErrorHandler);
}