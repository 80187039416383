import { Modules, Roles } from '/@types/ids';
import { CurrentUser } from '/@types/user';

export function hasRole(user: CurrentUser, roleId: Roles) {
  return user.roles.some(({ id }) => [roleId, Roles.AppOwner].includes(id));
}

export function hasRoles(user: CurrentUser, roleIds: Roles[]) {
  return user.roles.some(({ id }) => roleIds.includes(id));
}
