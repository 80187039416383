<template>
  <section class="aa" v-if="opt">
    <df-button v-on:click="opt.filters = []" :active="opt.filters.length > 0">
      Nullstill søk
    </df-button>

    <template v-for="category in filters" :key="category.label">
      <div class="title-sm">{{ category.label }}</div>

      <template v-for="entry in category.entries" :key="entry.filterKey">
        <df-input-search
          v-if="entry.filterType === FilterTypes.Search"
          :placeholder="entry.placeholder"
          :model-value="getFilterValue(opt.filters, entry.filterKey).join(', ')"
          v-on:input="opt.filters = replaceFilterValues(opt.filters, entry.filterKey, [$event])"
          v-on:clear="clearFilter(opt.filters, entry.filterKey)"
          elevate
        />

        <df-select
          v-else
          :label="entry.label"
          elevate
          :active="getFilterValue(opt.filters, entry.filterKey).length > 0"
        >
          <template v-slot:text>
            {{ entry.text || `Velg fra liste` }}
          </template>

          <df-dropdown2-item v-on:click="opt.filters = clearFilter(opt.filters, entry.filterKey)">
            Alle
          </df-dropdown2-item>

          <df-dropdown2-item
            v-for="[id, label] in entry.list"
            :key="id"
            v-on:click="opt.filters = toggleFilterValue(opt.filters, entry.filterKey, id)"
            no-close
          >
            {{ label }}

            <template v-slot:icon>
              <df-icon
                :code="getFilterValue(opt.filters, entry.filterKey)?.includes(id) ? 'f14a' : 'f0c8'"
              />
            </template>
          </df-dropdown2-item>
        </df-select>
      </template>
    </template>

    <slot default></slot>
  </section>
</template>

<script src="./FilterComp.ts" lang="ts"></script>
<style src="./FilterComp.css" scoped></style>
