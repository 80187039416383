<script setup lang="ts">
const emit = defineEmits(['click', 'input']);

const props = withDefaults(
  defineProps<{
    to?: string;
    href?: string;
    label?: boolean;
    target?: string;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit';
    stack?: boolean;
    value?: boolean;
    // style
    primary?: boolean;
    elevate?: boolean;
    danger?: boolean;
    warning?: boolean;
    success?: boolean;
    active?: boolean;
  }>(),
  {
    target: '_blank',
    type: 'button',
  },
);

function click(event: Event) {
  emit('click', event);
}
</script>

<template>
  <router-link
    v-if="to"
    :to="to"
    class="button"
    :class="{
      'button--disable': disabled,
      'button--stack': stack,
      'button--elevate': elevate,
      'button--fit': !$slots.default,
      'button--active': value || active,
      'button--fit-icon': $slots.icon,
      'button--primary': primary,
      'button--danger': danger,
      'button--success': success,
    }"
    v-on:click="click"
  >
    <div class="button__text" v-if="$slots.default">
      <slot></slot>
    </div>

    <div class="button__icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <div class="button__badge" v-if="$slots.badge">
      <slot name="badge"></slot>
    </div>
  </router-link>

  <a
    v-else-if="href"
    :href="href"
    :target="target"
    class="button"
    :class="{
      'button--disable': disabled,
      'button--stack': stack,
      'button--elevate': elevate,
      'button--fit': !$slots.default,
      'button--active': value || active,
      'button--fit-icon': $slots.icon,
      'button--primary': primary,
      'button--danger': danger,
      'button--success': success,
    }"
    v-on:click="click"
  >
    <div class="button__text" v-if="$slots.default">
      <slot></slot>
    </div>

    <div class="button__icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <div class="button__badge" v-if="$slots.badge">
      <slot name="badge"></slot>
    </div>
  </a>

  <label
    v-else-if="label"
    tabindex="0"
    class="button"
    :class="{
      'button--disable': disabled,
      'button--stack': stack,
      'button--elevate': elevate,
      'button--fit': !$slots.default,
      'button--active': value || active,
      'button--fit-icon': $slots.icon,
      'button--primary': primary,
      'button--danger': danger,
      'button--success': success,
    }"
  >
    <div class="button__text" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="button__icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
  </label>

  <button
    v-else
    class="button"
    :type="type"
    :class="{
      'button--disable': disabled,
      'button--stack': stack,
      'button--elevate': elevate,
      'button--fit': !$slots.default,
      'button--active': value || active,
      'button--fit-icon': $slots.icon,
      'button--primary': primary,
      'button--danger': danger,
      'button--warning': warning,
      'button--success': success,
    }"
    v-on:click="click"
    :disabled="disabled"
  >
    <div class="button__text" v-if="$slots.default">
      <slot></slot>
    </div>

    <div class="button__icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <div class="button__badge" v-if="$slots.badge">
      <slot name="badge"></slot>
    </div>
  </button>
</template>

<style scoped>
.button {
  position: relative;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  border: 1px solid var(--color-border);
  padding: calc(var(--button-padding-y) - 1px) var(--button-padding-x);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 2.5rem;
  border-radius: var(--radius-sm);
  color: var(--color-link);
  outline: none;
}

.button::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 0;
  height: calc(100% + 2px);
  background-color: currentColor;
  opacity: 0.175;
  border-radius: var(--radius-sm);
  filter: contrast(150%);
}

.button:focus {
  border-color: var(--color-link);
}

.button:hover {
  border-color: transparent;
}

.button:hover::before {
  width: calc(100% + 2px);
}

label.button {
  margin-bottom: 0; /* Remove bootstrap spacing */
}

.button--disable {
  opacity: 0.5;
  pointer-events: none;
}

.button--fit {
  padding: calc(var(--button-padding-y) - 1px);
}

.button--fit-icon {
  padding-right: calc(var(--button-padding-y) - 1px);
}

.button--stack {
  flex-direction: column;
  padding: calc(var(--button-padding-y) - 1px) var(--gap-md);
}

.button--elevate {
  border-color: transparent;
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
}

.button--active {
  background-color: var(--color-active);

  &.button--danger {
    background-color: var(--color-danger_bg);
  }

  &.button--warning {
    background-color: var(--color-warn_bg);
  }
}

.button__icon {
  display: grid;
  grid-auto-flow: column;
  gap: var(--gap-sm);
  color: var(--df-button-icon-color, var(--color-text));
}

.button__icon:nth-child(n + 2) {
  margin-left: var(--gap-md);
}

.button--stack .button__icon {
  margin-left: 0;
  order: -1;
}

.button__text {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.button--stack .button__text {
  margin-top: var(--button-padding-y);
}

.button--primary {
  border-color: var(--color-primary);
  color: var(--color-primary);
  --df-button-icon-color: var(--color-primary);
}

.button--danger {
  border-color: var(--color-danger_text);
  color: var(--color-danger_text);
  --df-button-icon-color: var(--color-danger_text);
}

.button--warning {
  border-color: var(--color-warn_text);
  color: var(--color-warn_text);
  --df-button-icon-color: var(--color-warn_text);
}

.button--success {
  border-color: var(--color-success_text);
  color: var(--color-success_text);
  --df-button-icon-color: var(--color-success_text);
}

.button--elevate.button--primary {
  background-color: var(--color-primary);
  color: white;
  --df-button-icon-color: white;
}

.button--elevate.button--danger {
  background-color: var(--color-danger_text);
  color: white;
  --df-button-icon-color: white;
}

.button--elevate.button--warning {
  background-color: var(--color-warn_text);
  color: white;
  --df-button-icon-color: white;
}

.button--elevate.button--success {
  background-color: var(--color-success_text);
  color: white;
  --df-button-icon-color: white;
}

.button--elevate.button--active::before {
  width: calc(100% + 2px);
}

.button__badge {
  position: absolute;
  top: calc(var(--gap-sm) * -1);
  right: calc(var(--gap-sm) * -1);
  display: grid;
}
</style>
