<script setup lang="ts">
import { nextTick, provide, reactive, ref, watch } from 'vue';

const margin = 8;

const props = defineProps<{
  label?: String;

  noOverlap?: boolean;
  elevate?: boolean;
  noIcon?: boolean;
}>();

const emit = defineEmits(['open']);

const triggerEl = ref<HTMLDivElement | null>(null);
const menuEl = ref<HTMLDivElement | null>(null);
const menu = ref('');
const isOpen = ref(false);
const pos = reactive({
  top: 0,
  right: 0,
  pushX: 0,
  pushY: 0,
  bottom: null,
});

watch(isOpen, (value) => (value ? onOpen() : onClose()));

function toggle(value = !isOpen.value) {
  isOpen.value = value;
}

function onOpen() {
  if (triggerEl.value == null) return;
  const { top, bottom, right } = triggerEl.value.children[0].getBoundingClientRect();

  pos.top = props.noOverlap ? bottom : top;
  pos.right = document.body.clientWidth - right;

  nextTick(() => {
    emit('open', true);

    if (!menuEl.value) return;

    const rect = menuEl.value.getBoundingClientRect();

    if (rect.left < margin) {
      pos.pushX = margin - rect.left;
    }

    if (rect.bottom > innerHeight) {
      pos.pushY = innerHeight - rect.bottom - margin;
    }
  });
}

function onClose() {
  menu.value = '';
  pos.top = 0;
  pos.right = 0;
  pos.pushX = 0;
  pos.pushY = 0;
  pos.bottom = null;
}

provide('dropdown-close', () => toggle(false));
provide('dropdown-menu', menu);
</script>

<template>
  <div class="dropdown" :ref="(el: HTMLDivElement) => triggerEl = el">
    <slot name="trigger" :toggle="toggle">
      <df-button :elevate="elevate" v-on:click="toggle()">
        <template v-slot:default v-if="label">
          {{ label }}
        </template>

        <template v-slot:icon v-if="!noIcon">
          <slot name="trigger-icon">
            <df-icon code="f142" />
          </slot>
        </template>
      </df-button>
    </slot>
  </div>

  <teleport to="#overlay" v-if="isOpen">
    <div class="overlay" v-on:click.self="toggle(false)">
      <div
        class="overlay__menu"
        :ref="(el) => (menuEl = el)"
        :style="{
          top: `${pos.top}px`,
          right: `${pos.right}px`,
          transform: `translate(${pos.pushX}px, ${pos.pushY}px)`,
        }"
      >
        <slot :close="() => toggle(false)">
          <em>Ingen innhold</em>
        </slot>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.dropdown {
  display: contents;
}

.overlay {
  filter: drop-shadow(var(--shadow-lg));
}

.overlay__menu {
  position: fixed;
  background-color: var(--color-cardbg);
  width: 14rem;
  max-width: calc(100% - var(--gap-sm) * 2);
  max-height: calc(100% - var(--gap-sm) * 2);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  border-radius: var(--radius-sm);
  padding: var(--radius-sm) 0;
  animation: in 0.2s;
  animation-fill-mode: forwards;
  transition: transform 0.2s;
  border: 1px solid var(--color-divider);
  outline: none;
}
</style>
