<script setup lang="ts">
const emit = defineEmits(['click', 'input']);

const props = defineProps<{
  to?: string;
}>();

function click(event: Event) {
  emit('click', event);
}
</script>

<template>
  <component
    :is="to ? 'router-link' : 'button'"
    class="button"
    type="button"
    v-on:click="click"
    :to="to"
  >
    <div class="button__icon">
      <slot name="icon"></slot>
    </div>
  </component>
</template>

<style scoped>
.button {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);
  place-content: center;

  font-size: 1.4rem;

  border: none;

  border-radius: var(--radius-sm);
  height: 4rem;
  width: 4rem;

  box-shadow: var(--shadow-md);

  position: sticky;
  bottom: var(--gap-md);
  right: var(--gap-md);
  background-color: var(--color-cardbg);

  color: inherit;
  text-decoration: none;

  place-items: center;

  &:hover {
    background-color: var(--color-hover);
    border-color: var(--color-hover);
  }
}
</style>
