<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useTenantStore } from '/@stores/tenant';
import { Roles } from '/@types/ids';

const loading = ref(0);

const tenantStore = useTenantStore();

const { users } = storeToRefs(tenantStore);

const raUsers = computed(() =>
  [...users.value.values()].filter(( user ) =>
    !user.isDisabled && user.roles.some(({ id }) => id === Roles.RessursAnsvarlig),
  ),
);

loading.value++;

tenantStore.loadUsers().then(() => {
  loading.value--;
});
</script>

<template>
  <div class="users">
    <div class="users__user card" v-for="user in raUsers" :key="user.id">
      <h2>{{ user.name }}</h2>

      <p>
        <a class="text-link" :href="`tel:${user.phone}`">
          {{ user.phone }}
        </a>
      </p>

      <p>
        <a class="text-link" :href="`mailto:${user.email}`">
          {{ user.email }}
        </a>
      </p>
    </div>

    <div class="loading-icon" v-if="loading > 0"></div>
  </div>
</template>

<style scoped>
.users {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-md);
  max-width: 1600px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.users__user {
  flex: 1 1 0;
  min-width: 25ch;
  max-width: 50ch;
}
</style>
