<script setup lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  addResourceToFilter,
  addResourceToGroup,
  createResourceFilter,
  createResourceGroup,
  loadResourceFilter,
  loadResourceGroups,
  loadResources,
  removeResourceFilter,
  removeResourceFromFilter,
  removeResourceFromGroup,
  removeResourceGroup,
  sortResourceFilter,
  sortResourceGroup,
  sortResourceGroupInFilter,
  updateResourceFilter,
  updateResourceGroup,
} from '/@stores/resources';
import { useUserStore } from '/@stores/user';
import { loadValuelistByGuid } from '/@stores/valuelist';
import { Resource, ResourceFilter, ResourceGroup } from '/@types/resources';
import { Valuelist } from '/@types/valuelist';
import { compare } from '/@utilities/intl';
import { toast } from '/@utilities/toast';
import { hasRole } from '/@utilities/user';
import DfInputSearch from '/@elements/DfInputSearch/DfInputSearch.vue';
import { Roles } from '/@types/ids';

const props = defineProps<{
  filterId?: number;
}>();

const router = useRouter();
const filters = ref<Array<ResourceFilter>>([]);
const groups = ref<Array<ResourceGroup>>([]);
const resources = ref<Array<Resource>>([]);
const users = ref<Array<Resource>>([]);
const activeFilterId = ref<null | number>(props.filterId);
const newGroupName = ref('');
const editFilterForm = ref({ name: '', type: '' });
const editFilterFormSubmitting = ref(false);
const editFilterFormError = ref(false);
const editGroupSubmitting = ref(null);
const editGroupError = ref(null);
const loading = ref(new Set());
const creatingFilter = ref(false);
const dragging = ref(null);
const draggingType = ref(null);
const droppingOn = ref(null);
const tagValueList = ref<Valuelist>(null);
const showEditFilter = ref(false);
const showUserInfoGuid = ref(null);
const unassignedUserSearch = ref('');

const activeFilter = computed(() => filters.value.find(({ id }) => id === activeFilterId.value));
const sortedFilters = computed(() => [...filters.value].sort((a, b) => compare(a.name, b.name)));
const activeResources = computed(() =>
  resources.value.filter(({ filters }) => filters.some(({ id }) => id === activeFilterId.value)),
);
const unassignedUsers = computed(() =>
  users.value.filter(
    ({ guid, name }) =>
      activeFilter.value?.resources.every((user) => user.guid !== guid) &&
      name.toLowerCase().includes(unassignedUserSearch.value.toLowerCase()),
  ),
);
const userStore = useUserStore();

function refresh() {
  return Promise.all([
    loadResourceGroups({ filterId: activeFilterId.value }).then(
      (res) => (groups.value = res || []),
    ),
    loadResources({ ungrouped: true }).then((res) => (users.value = res || [])),
    loadResources({ filterId: activeFilterId.value }).then(
      (data) =>
        (resources.value = (data || []).sort((a, b) => {
          if (a.type === 'group' && b.type === 'user') return 1;
          if (b.type === 'group' && a.type === 'user') return -1;

          const aIndex =
            a.sortIndex ?? a.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;
          const bIndex =
            b.sortIndex ?? b.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;

          return aIndex - bIndex;
        })),
    ),
    loadResourceFilter().then((res) => (filters.value = res || [])),
  ]);
}

watch(activeFilterId, (filterId) => {
  router.push({ params: { filterId } });
  loadResourceGroups({ filterId }).then((res) => (groups.value = res || []));
  loadResources({ filterId }).then(
    (data) =>
      (resources.value = (data || []).sort((a, b) => {
        if (a.type === 'group' && b.type === 'user') return 1;
        if (b.type === 'group' && a.type === 'user') return -1;

        const aIndex =
          a.sortIndex ?? a.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;
        const bIndex =
          b.sortIndex ?? b.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;

        return aIndex - bIndex;
      })),
  );
});

loadResources({ ungrouped: true }).then(
  (res) => (users.value = [...(res || [])].sort((a, b) => a.sortIndex - b.sortIndex)),
);

loadResourceFilter().then((res) => (filters.value = res || []));

if (activeFilterId.value != null) {
  loadResourceGroups({ filterId: activeFilterId.value }).then((res) => (groups.value = res || []));
}

function findGroupFromResourceGuid(resourceGuid: string) {
  return groups.value.find((group) => group.resourceGuid === resourceGuid);
}

function findGroupFromSubresourceGuid(resourceGuid: string) {
  return groups.value.find((group) =>
    group.resources.some((subgroup) => subgroup.guid === resourceGuid),
  );
}

function addFilter(name: string, { isUserSpecific }: { isUserSpecific: boolean }) {
  creatingFilter.value = true;
  createResourceFilter(name, { isUserSpecific })
    .then((res) => {
      if (!res) return;
      filters.value.push(res);
      activeFilterId.value = res.id;
      toggleEditFilterForm(true);
      toast(`Opprettet ny gruppe «${name}»`);
    })
    .catch(() => {
      toast('Kunne ikke opprette ny gruppe');
    })
    .finally(() => {
      creatingFilter.value = false;
    });
}

function editFilter(name: string, type: string) {
  const id = activeFilterId.value;
  editFilterFormSubmitting.value = true;
  editFilterFormError.value = false;

  updateResourceFilter(id, { name, type })
    .then(() => {
      const filter = filters.value.find((filter) => filter.id === id);
      filters.value = filters.value
        .filter((filter) => filter.id !== id)
        .concat([{ ...filter, name, type }]);
      toggleEditFilterForm(false);
    })
    .catch(() => {
      editFilterFormError.value = true;
    })
    .finally(() => {
      editFilterFormSubmitting.value = false;
    });
}

function removeFilter(filterId: number) {
  removeResourceFilter(filterId).then(() => {
    activeFilterId.value = null;
    toggleEditFilterForm(false);
    filters.value = filters.value.filter(({ id }) => id !== filterId);
  });
}

function addToFilter(resourceGuid: string) {
  loading.value.add(resourceGuid);

  addResourceToFilter(activeFilterId.value, resourceGuid)
    .then(() => refresh())
    .finally(() => loading.value.delete(resourceGuid));
}

function removeFromFilter(resourceGuid: string) {
  loading.value.add(resourceGuid);

  removeResourceFromFilter(activeFilterId.value, resourceGuid)
    .then(() => refresh())
    .finally(() => loading.value.delete(resourceGuid));
}

function createGroup(name: string) {
  createResourceGroup(name, activeFilterId.value).then(() => {
    refresh();
    newGroupName.value = '';
  });
}

function editGroup(resourceGuid: string, name: string, type: string) {
  const { id } = findGroupFromResourceGuid(resourceGuid);
  editGroupSubmitting.value = id;
  editGroupError.value = null;

  updateResourceGroup(id, { name, type, filterId: activeFilterId.value })
    .catch(() => {
      editGroupError.value = id;
    })
    .finally(() => {
      editGroupSubmitting.value = null;
      refresh();
    });
}

function removeGroup(resourceGuid: string) {
  const { id } = findGroupFromResourceGuid(resourceGuid);
  removeResourceGroup(id).then(() => refresh());
}

function onDragStart({ dataTransfer }: DragEvent, resource: Resource) {
  dragging.value = resource.guid;
  draggingType.value = resource.type;
  dataTransfer.effectAllowed = 'move';
  dataTransfer.setData('text/plain', JSON.stringify(resource));
}

function onDragEnd() {
  droppingOn.value = null;
  dragging.value = null;
  draggingType.value = null;
}

function onDragEnter(event: DragEvent, id: string | number) {
  if (dragging.value !== id) droppingOn.value = id;
}

function onDragLeave(event: DragEvent) {
  droppingOn.value = null;
}

async function onDrop({ dataTransfer }: DragEvent, droppedOnResource: Resource) {
  const draggedResource = JSON.parse(dataTransfer.getData('text/plain'));

  onDragEnd();

  // Terminate early if invalid drop
  if (draggedResource == null || draggedResource.guid === droppedOnResource.guid) return;
  if (draggedResource.type === 'group' && droppedOnResource.type !== 'group') return;

  loading.value.add(draggedResource.guid);

  switch (draggedResource.type) {
    case 'group': {
      const sortedResources = activeResources.value
        .filter(({ guid, type }) => type === 'group' && guid !== draggedResource.guid)
        .map(({ guid }) => guid);

      sortedResources.splice(
        sortedResources.findIndex((id) => id === droppedOnResource.guid) + 1,
        0,
        draggedResource.guid,
      );

      await sortResourceGroupInFilter(activeFilterId.value, sortedResources);
      break;
    }

    case 'user': {
      const draggedUserFromGroup = findGroupFromSubresourceGuid(draggedResource.guid);
      const droppedOnGroup =
        droppedOnResource.type === 'group'
          ? findGroupFromResourceGuid(droppedOnResource.guid)
          : findGroupFromSubresourceGuid(droppedOnResource.guid);

      // If moving from a group, to another, or to root
      if (draggedUserFromGroup != null && draggedUserFromGroup.id !== droppedOnGroup?.id) {
        await removeResourceFromGroup(draggedUserFromGroup.id, draggedResource.guid);
      }

      // If dropping on a different group, or moving from root
      if (droppedOnGroup != null && draggedUserFromGroup?.id !== droppedOnGroup.id) {
        await addResourceToGroup(droppedOnGroup.id, draggedResource.guid);
      }

      // If droppong group, sort group
      if (droppedOnGroup != null) {
        const sortedResources = droppedOnGroup.resources
          .filter(({ guid }) => guid !== draggedResource.guid)
          .map(({ guid }) => guid);

        sortedResources.splice(
          sortedResources.findIndex((guid) => guid === droppedOnResource.guid) + 1,
          0,
          draggedResource.guid,
        );

        await sortResourceGroup(droppedOnGroup.id, sortedResources);
      }

      // Else sort root
      else {
        const sortedResources = activeFilter.value.resources
          .filter(({ guid }) => guid !== draggedResource.guid)
          .map(({ guid }) => guid);

        sortedResources.splice(
          sortedResources.findIndex((guid) => guid === droppedOnResource.guid) + 1,
          0,
          draggedResource.guid,
        );

        await sortResourceFilter(activeFilterId.value, sortedResources);
      }

      break;
    }
  }

  // Get the updated users and groups then remove loading state
  refresh().finally(() => {
    loading.value.delete(draggedResource.guid);
  });
}

function globalOnDrop() {
  dragging.value = null;
}

function toggleEditFilterForm(value = !showEditFilter.value) {
  showEditFilter.value = value;

  if (value) {
    editFilterForm.value.name = activeFilter.value.name;
    editFilterForm.value.type = activeFilter.value.type;
  } else {
    editFilterForm.value.name = null;
    editFilterForm.value.type = null;
  }
}

loadValuelistByGuid({ valuelistGuid: 'c6e62101-a648-4c88-82c4-61cb2ac30e5c' }).then((res) => {
  if (!res) return;
  tagValueList.value = res;
});

onMounted(() => addEventListener('drop', globalOnDrop));
onUnmounted(() => removeEventListener('drop', globalOnDrop));
</script>

<template>
  <div class="groups-page" :class="{ 'groups-page--dragging': dragging != null }">
    <header class="groups-page__header">
      <h1>{{ activeFilter?.name ?? '(Velg gruppe)' }}</h1>

      <span class="badge" v-if="activeFilter?.type">{{ activeFilter.type }}</span>

      <df-button elevate :disabled="activeFilter == null" v-on:click="toggleEditFilterForm(true)">
        <template v-slot:icon>
          <df-icon code="f304" />
        </template>
      </df-button>

      <df-dropdown>
        <template v-slot:trigger="{ toggle }">
          <df-button elevate v-on:click="toggle()">
            <template v-slot:icon>
              <df-icon code="f107" />
            </template>
          </df-button>
        </template>

        <template v-slot:default="{ close }">
          <df-dropdown-item
            v-for="filter in sortedFilters"
            :key="filter.id"
            v-on:click="activeFilterId = filter.id"
            no-icon
            :disabled="
              userStore.user == null ||
              (!hasRole(userStore.user, Roles.RessursAnsvarlig) && !filter.isUserSpecific)
            "
          >
            <div>
              {{ filter.name }}
              <span class="badge" v-if="filter.type">{{ filter.type }}</span>
              <span class="badge badge--info" v-if="filter.isUserSpecific">Personlig</span>
            </div>
          </df-dropdown-item>
        </template>
      </df-dropdown>

      <df-dropdown>
        <template v-slot:trigger="{ toggle }">
          <df-button elevate style="margin-left: auto" v-on:click="toggle()">
            Ny gruppe

            <template v-slot:icon>
              <df-icon code="2b" />
            </template>
          </df-button>
        </template>

        <df-dropdown-item
          v-on:click="addFilter('Ny firmagruppe', { isUserSpecific: false })"
          :disabled="userStore.user == null || !hasRole(userStore.user, Roles.RessursAnsvarlig)"
        >
          <template v-slot:icon>
            <df-icon code="2b" />
          </template>

          Firmagruppe
        </df-dropdown-item>

        <df-dropdown-item v-on:click="addFilter('Ny personlig gruppe', { isUserSpecific: true })">
          <template v-slot:icon>
            <df-icon code="2b" />
          </template>

          Personlig gruppe
        </df-dropdown-item>

        <hr />

        <df-dropdown-item-block>
          <template v-slot:icon>
            <df-icon code="f05a" />
          </template>

          Firmagrupper er synlig for alle, og opprettes av ressursansvarlig.
        </df-dropdown-item-block>
      </df-dropdown>
    </header>

    <div class="group-page__info-a info">
      <p>
        Følgende er en liste over ressurser knyttet til gruppen, og hvilken undergruppe de tilhører.
        Gruppe styrer hvilke ressurser du ser i «Planlegg avtaler», mens undergrupper tilsvarer
        inndelingen i tabellen.
      </p>
      <br />
      <p v-if="activeFilter != null && activeFilter.resources.length > 30">
        <df-icon code="f06a" />
        Det er nå mer en 30 brukere på gruppen. Vi anbefaler å dele opp i mindre grupper da det kan
        oppleves litt seigt om man har mange avtaler per ressurs og ser på store tidsvinduer (f.eks.
        «kvartal») i «Planlegg avtaler».
      </p>
    </div>

    <ol class="group-page__list-a user-list card" v-if="activeFilter != null">
      <template v-for="resource in resources" :key="resource.guid">
        <li
          v-if="resource.type === 'user'"
          class="user-list__item user-list__item--draggable"
          draggable="true"
          :class="{
            'user-list__item--dropping': droppingOn === resource.guid,
            'user-list__item--disabled': draggingType === 'group' || dragging === resource.guid,
            'user-list__item--loading': loading.has(resource.guid),
          }"
          v-on:dragenter="onDragEnter($event, resource.guid)"
          v-on:dragleave="onDragLeave($event)"
          v-on:dragstart="onDragStart($event, resource)"
          v-on:dragend="onDragEnd()"
          v-on:dragover.prevent
          v-on:drop="onDrop($event, resource)"
        >
          <div class="loading-icon" v-if="loading.has(resource.guid)"></div>
          <df-icon code="e411" solid v-else />

          <span class="user-list-item-name">{{ resource.name }}</span>

          <df-button
            v-on:click="removeFromFilter(resource.guid)"
            :disabled="loading.has(resource.guid)"
          >
            <template v-slot:icon>
              <df-icon code="f00d" />
            </template>
          </df-button>
        </li>

        <template v-else-if="resource.type === 'group'">
          <li class="user-list__group">
            <!-- Dragging groups are temporary disabled until we find a solution for sortIndex on backend -->
            <!--
          draggable="true"
          v-on:dragstart="onDragStart($event, resource)"
        -->
            <div
              class="user-list__item"
              :class="{
                'user-list__item--dropping': droppingOn === resource.guid,
                'user-list__item--disabled': dragging === resource.guid,
              }"
              v-on:dragenter="onDragEnter($event, resource.guid)"
              v-on:dragleave="onDragLeave($event)"
              draggable="true"
              v-on:dragstart="onDragStart($event, resource)"
              v-on:dragend="onDragEnd()"
              v-on:dragover.prevent
              v-on:drop="onDrop($event, resource)"
            >
              <df-icon code="f500" />

              <df-input
                :model-value="resource.name"
                v-on:update:model-value="editGroup(resource.guid, $event, resource.groupType)"
                style="place-self: stretch"
              />

              <div class="loading-icon" v-if="editGroupSubmitting === resource.guid"></div>

              <div class="badge" v-if="resource.groupType">{{ resource.groupType }}</div>

              <df-dropdown>
                <template v-slot:trigger="{ toggle }">
                  <df-button v-on:click="toggle">
                    <template v-slot:icon>
                      <df-icon code="f02b" />
                    </template>
                  </df-button>
                </template>

                <df-dropdown-item-input
                  placeholder="Skriv her"
                  v-on:submit="editGroup(resource.guid, resource.name, $event)"
                >
                  <template v-slot:icon>
                    <df-icon code="f02b" />
                  </template>
                </df-dropdown-item-input>

                <hr style="height: 0; border: none; border-top: 1px solid var(--color-divider)" />

                <df-dropdown-item-block>
                  <div class="title-sm">Forslagsverdier</div>
                </df-dropdown-item-block>

                <df-dropdown-item v-on:click="editGroup(resource.guid, resource.name, '')">
                  <template v-slot:icon>
                    <df-icon code="f00d" />
                  </template>
                  Ingen
                </df-dropdown-item>

                <df-dropdown-item
                  v-for="[itemId, item] in tagValueList.valuelistItems"
                  :key="itemId"
                  v-on:click="editGroup(resource.guid, resource.name, item.name)"
                >
                  {{ item.name }}
                </df-dropdown-item>
              </df-dropdown>

              <df-button v-on:click="removeGroup(resource.guid)">
                <template v-slot:icon>
                  <df-icon code="f2ed" />
                </template>
              </df-button>
            </div>

            <ul class="user-list user-list--grouped">
              <li
                class="user-list__item user-list__item--draggable"
                draggable="true"
                :class="{
                  'user-list__item--dropping': droppingOn === subresource.guid,
                  'user-list__item--disabled':
                    draggingType === 'group' || dragging === resource.guid,
                  'user-list__item--loading': loading.has(subresource.guid),
                }"
                :key="subresource.guid"
                v-for="subresource in resource.subresources"
                v-on:dragenter="onDragEnter($event, subresource.guid)"
                v-on:dragleave="onDragLeave($event)"
                v-on:dragstart="onDragStart($event, subresource)"
                v-on:dragend="onDragEnd()"
                v-on:dragover.prevent
                v-on:drop="onDrop($event, subresource)"
              >
                <div class="loading-icon" v-if="loading.has(subresource.guid)"></div>
                <df-icon code="e411" solid v-else />

                <span class="user-list-item-name">{{ subresource.name }}</span>

                <df-button
                  v-on:click="removeFromFilter(subresource.guid)"
                  :disabled="loading.has(subresource.guid)"
                >
                  <template v-slot:icon>
                    <df-icon code="f00d" />
                  </template>
                </df-button>
              </li>
            </ul>
          </li>
        </template>
      </template>

      <li class="user-list__item">
        <df-icon code="f500" />

        <form
          v-on:submit.prevent="createGroup(newGroupName)"
          style="display: grid; gap: var(--gap-md); grid-template-columns: 1fr max-content"
        >
          <df-input placeholder="Ny undergruppe" v-model="newGroupName" />

          <df-button primary elevate type="submit" :disabled="newGroupName.length === 0">
            Opprett
            <template v-slot:icon>
              <df-icon code="2b" />
            </template>
          </df-button>
        </form>
      </li>
    </ol>

    <div class="card" v-else>Velg gruppe for å redigere</div>

    <p class="group-page__info-b info">
      Følgende er en liste over ledige ressurser. Klikk på hvilken som helst ressurs for å flytte
      den over til gruppen.
    </p>

    <div class="group-page__list-b card">
      <df-input-search v-model="unassignedUserSearch" />

      <ul class="user-list">
        <li
          class="user-list__item user-list__item--right"
          v-for="user in unassignedUsers"
          :key="user.guid"
        >
          <div
            class="user-list-button"
            :class="{ 'user-list-button--loading': loading.has(user.guid) }"
          >
            <button style="display: contents; text-align: left" v-on:click="addToFilter(user.guid)">
              <div class="user-list-button__icon loading-icon" v-if="loading.has(user.guid)"></div>
              <df-icon class="user-list-button__icon" code="f177" v-else />

              <div class="user-list-button__text">
                {{ user.name }}
                <small style="display: block" v-if="user.filters.length > 1"
                  >Er i {{ user.filters.length - 1 }} andre grupper</small
                >
              </div>
            </button>

            <button
              class="small-info-button fa-icon user-list-button__other"
              v-on:click="showUserInfoGuid = user.guid"
              v-if="user.filters.length > 1"
            >
              &#xf05a;
            </button>
          </div>

          <df-modal v-if="showUserInfoGuid === user.guid">
            <div class="dialog">
              <header class="head">
                <df-button v-on:click="showUserInfoGuid = null">
                  <template v-slot:icon>
                    <df-icon code="f060" />
                  </template>
                </df-button>

                <h1 class="title-md">{{ user.name }}</h1>
              </header>

              <dl class="dl card" style="padding: 0">
                <div class="dl__group dl__group--large">
                  <dt class="dl__topic">Grupper</dt>
                  <dd class="dl__description" v-for="filter in user.filters" :key="filter.id">
                    {{ filters.find(({ id }) => id === filter.id)?.name }}
                  </dd>
                </div>
              </dl>
            </div>
          </df-modal>
        </li>
      </ul>
    </div>

    <df-modal v-if="showEditFilter">
      <form
        class="dialog"
        v-on:submit.prevent="editFilter(editFilterForm.name, editFilterForm.type)"
      >
        <header class="head">
          <df-button v-on:click="toggleEditFilterForm(false)">
            <template v-slot:icon>
              <df-icon code="f060" />
            </template>
          </df-button>

          <h1 class="title-md">Rediger gruppe</h1>

          <df-delete-button v-on:confirm="removeFilter(activeFilterId)" />
        </header>

        <df-input type="text" v-model="editFilterForm.name" label="Navn" elevate no-delay />
        <df-input type="text" v-model="editFilterForm.type" label="Type" elevate no-delay>
          <template v-slot:action>
            <df-dropdown label="Forslagsverdi">
              <template v-slot:trigger="{ toggle }">
                <df-button v-on:click="toggle">
                  <template v-slot:icon>
                    <df-icon code="f02b" />
                  </template>
                </df-button>
              </template>

              <df-dropdown-item v-on:click="editFilterForm.type = ''">
                <template v-slot:icon>
                  <df-icon code="f00d" />
                </template>
                Ingen
              </df-dropdown-item>

              <df-dropdown-item
                v-for="[itemId, item] in tagValueList.valuelistItems"
                :key="itemId"
                v-on:click="editFilterForm.type = item.name"
              >
                {{ item.name }}
              </df-dropdown-item>
            </df-dropdown>
          </template>
        </df-input>

        <div class="loading-icon" v-if="editFilterFormSubmitting"></div>
        <div style="color: var(--color-danger_text)" v-if="editFilterFormError">
          Kunne ikke oppdatere gruppe
        </div>

        <df-button primary elevate type="submit" :disabled="editFilterFormSubmitting"
          >Lagre</df-button
        >
      </form>
    </df-modal>
  </div>
</template>

<style scoped>
.groups-page {
  display: grid;
  grid-template-columns: minmax(0, 60ch) minmax(0, 40ch);
  grid-template-areas:
    'header header'
    'info-a info-b'
    'list-a list-b';
  place-content: center;
  padding: var(--gap-lg) var(--gap-md);
  gap: var(--gap-lg) calc(var(--gap-lg) * 2);
}

.groups-page__header {
  grid-area: header;
  display: flex;
  align-items: center;
  gap: var(--gap-md);
}

.group-page__info-a {
  grid-area: info-a;
}

.group-page__info-b {
  grid-area: info-b;
}

.group-page__list-a {
  grid-area: list-a;
}

.group-page__list-b {
  grid-area: list-b;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.user-list--grouped {
  border-left: 1px solid var(--color-border);
  padding-left: var(--gap-md);
  margin-left: 1.5rem;
}

.user-list__item {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: var(--gap-md);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 0.5rem;
    background-color: transparent;
    border-radius: 1rem;
    pointer-events: none;
  }
}

.user-list__item--draggable {
  cursor: grab;
}

.user-list__item--disabled,
.user-list__item--loading {
  opacity: 0.5;
}

.user-list__item--loading {
  pointer-events: none;
}

.user-list__item--right {
  grid-template-columns: 1fr;
}

.groups-page--dragging .user-list__item {
  & > * {
    pointer-events: none;
  }
}

.user-list__item--dropping {
  &::after {
    background-color: orange;
  }
}

.user-list__group {
  list-style: none;
  display: grid;
  gap: var(--gap-md);
}

.user-list-item-name {
  pointer-events: none;
}

@keyframes other-in {
  from {
    transform: translateX(calc(var(--shift) * -1));
  }
}

.user-list-button {
  --shift: 0;
  text-align: left;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: var(--gap-md);
  padding: var(--gap-md);
  background-color: var(--color-cardbg);
  border: none;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  width: calc(100% + var(--shift));
  transform: translateX(calc(var(--shift) * -1));
  transition: transform 0.15s;

  &:is(:hover, .user-list-button--loading) {
    --shift: var(--gap-lg);
    background-color: var(--color-hover);

    & .user-list-button__icon {
      opacity: 1;
    }

    & .user-list-button__text {
      transform: translateX(var(--shift));
    }

    & .user-list-button__other {
      animation: other-in 0.15s;
    }
  }
}

.user-list-button__icon {
  opacity: 0;
  transition: opacity 0.15s;
}

.user-list-button__text {
  transition: transform 0.15s;
}

.title-badge {
  background-color: var(--color-darken);
  border-radius: var(--radius-sm);
  font-weight: normal;
  display: inline-block;
  padding: 0 var(--gap-sm);
}

.small-info-button {
  border-radius: var(--radius-sm);
  background-color: var(--color-cardbg);
  width: 1.5em;
  height: 1.5em;
  display: inline-grid;
  place-items: center;
  border: 0 solid transparent;
  box-shadow: var(--shadow-md);

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
