import { createAuth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import { Opt, OptType } from '/@types/opt';
import { sendError } from '../plugins/sentry';

let resolveToken: (token: string) => void;
let logoutFn: (shouldReturn: boolean) => void;

const token = new Promise((resolve) => {
  resolveToken = resolve;
});
const client = createAuth0Client({
  domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
  clientId: import.meta.env.VITE_AUTH0_CLIENTID as string,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
  },
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
});

client
  .then(async (auth0) => {
    logoutFn = (shouldReturn) =>
      auth0.logout(
        shouldReturn
          ? {
              logoutParams: {
                returnTo: `${import.meta.env.VITE_SIGNOUT_URL as string}?from=${location.origin}`,
              },
            }
          : undefined,
      );

    if (location.pathname.includes('auth0-callback')) {
      const { appState } = await auth0.handleRedirectCallback();

      if (appState?.location) {
        location.href = appState.location;
      }
    } else if (location.pathname.toLowerCase() === '/logout') {
      parent.postMessage('signout', '*');
      return logoutFn(false);
    } else if (!(await auth0.isAuthenticated())) {
      return auth0.loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${location.origin}/api/auth0-callback`,
        },
        appState: { location: location.href },
      });
    }

    resolveToken(await auth0.getTokenSilently());
  })
  .catch(() => {
    // If anything uncaught fails, then try to reload the app a couple of times, else log out
    const query = new URLSearchParams(location.search);
    let retries: number = Number(query.get('auth-retries'));

    if (retries == null || Number.isNaN(retries)) {
      retries = 0;
    }

    if (retries >= 2) {
      return logoutFn(true);
    }

    query.set('auth-retries', String(++retries));
    location.href = `${location.origin}${location.pathname}?${query}`;
  });

export function logout(shouldReturn = true) {
  logoutFn?.(shouldReturn);
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASEURL as string,
});

api.interceptors.request.use(async (config) => {
  if (config.headers != null) {
    config.headers['Authorization'] = `Bearer ${await token}`;
  }

  return config;
});

export function createQueryParams(properties: Map<string, any>) {
  const query = new URLSearchParams();

  [...properties].forEach(([name, value]) => {
    if (value == null || (typeof value === 'string' && value.length === 0)) return;

    if (typeof value === 'object') {
      [...value].forEach((val) => {
        if (!val) return;
        query.append(name, val);
      });
    } else {
      query.append(name, value);
    }
  });
  return query;
}

export function filterQuery(opt: Opt, columns: Array<any /*Column*/>): URLSearchParams {
  const searchParams = new URLSearchParams();
  const nFilters = opt.filters.filter(({ columnId }) => typeof columnId === 'number');
  const sFilters = opt.filters.filter(({ columnId }) => typeof columnId !== 'number');

  if (opt.type == OptType.List) {
    searchParams.append('limit', String(opt.limit));
    searchParams.append('offset', String(opt.offset));
    searchParams.append('orderBy', String(opt.sortBy));
    searchParams.append('orderDesc', String(opt.sortDesc));
  }

  if (opt.type === OptType.Map) {
    searchParams.append('limit', String(100_000)); // 100_000 is max limit from API
  }

  if (opt.search.length > 0) {
    searchParams.append('search', String(opt.search));
  }

  if (opt.tenantId != null) {
    searchParams.append('partnerId', String(opt.tenantId));
  }

  if (nFilters.length > 0) {
    const base64 = btoa(
      JSON.stringify(
        nFilters.map(({ columnId, values }) => {
          const column = columns.find(({ id }) => id === columnId);
          return {
            id: column.filterKey,
            values:
              ('filterList' in column || 'getFilterList' in column) != null
                ? values
                : values.map((value) => ([-100, '#EMPTY'].includes(value) ? value : `%${value}%`)),
          };
        }),
      ),
    );
    searchParams.append('pFilterBase64', base64);
  }

  sFilters.forEach(({ values, columnId }) => {
    const column = columns.find(({ id }) => id === columnId);
    if (column) {
      values.forEach((value) => searchParams.append(column.filterKey, String(value)));
    }
  });

  if (opt.filters2.length > 0) {
    opt.filters2.forEach((f, index) => {
      const prefix = `Prop[${index}]`;
      searchParams.append(prefix + '.Id', String(f.columnId));
      searchParams.append(prefix + '.Filter', f.type);
      searchParams.append(prefix + '.Value', f.value);
    });
  }

  return searchParams;
}

export function genericApiErrorHandler(error: any, custom = null): Promise<any> {
  sendError(error, {
    requestId: error?.response?.headers?.requestid,
    requestMethod: error?.config?.method,
    requestPath: error?.config?.url,
    requestData: error?.config?.data && JSON.parse(error?.config?.data),
    message: error?.response?.data,
    custom,
    error,
  });

  throw error;
}

export function errorText() {
  // generic message based on future status response
  // if (status === 400) {}
  // if (status === 500) {}
  return `Ingen endring ble lagret, grunnet ukjent feil. Dokflyt er informert om feilen.`;
}
