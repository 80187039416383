<script lang="ts">
type AlertObject = { show: boolean; message: string; type: AlertType };
type AlertType = 'info' | 'success' | 'warning' | 'danger';

export function useAlert() {
  const obj = ref<AlertObject>({
    show: false,
    message: '',
    type: 'danger',
  });

  function write(message: string, type?: AlertType) {
    if (type) obj.value.type = type;
    obj.value.message = message;
    obj.value.show = true;
  }

  function close() {
    obj.value.show = false;
  }

  return { obj, write, close };
}
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Return } from '/@types/helpers';

const model = defineModel<Return<typeof useAlert> | undefined>();

const props = withDefaults(
  defineProps<{
    show?: boolean;
    type?: AlertType;
    persistent?: boolean;
  }>(),
  { type: 'info' },
);

const emit = defineEmits(['close']);

const pShow = ref(props.show);
watch(
  () => props.show,
  () => {
    pShow.value = props.show;
  },
);

const typeStyle = computed(() => {
  const type = model.value?.obj.value.type || props.type;
  switch (type) {
    case 'danger':
      return {
        '--color-alert-text': 'var(--color-danger_text)',
        '--color-alert-bg': 'var(--color-danger_bg)',
      };
    case 'warning':
      return {
        '--color-alert-text': 'var(--color-warn_text)',
        '--color-alert-bg': 'var(--color-warn_bg)',
      };
    case 'success':
      return {
        '--color-alert-text': 'var(--color-success_text)',
        '--color-alert-bg': 'var(--color-success_bg)',
      };
    case 'info':
    default:
      return {
        '--color-alert-text': 'var(--color-info_text)',
        '--color-alert-bg': 'var(--color-info_bg)',
      };
  }
});

function onClose() {
  if (model.value) model.value.close();
  pShow.value = false;
  emit('close');
}
</script>

<template>
  <div v-if="model?.obj.value?.show || pShow || persistent" class="df-alert" :style="typeStyle">
    <section class="df-alert__content" :class="{ 'df-alert--icon': $slots.icon }">
      <div class="df-alert__icon" v-if="$slots.icon">
        <slot name="icon"></slot>
      </div>

      <div class="df-alert__text">
        {{ model?.obj.value.message }}
        <slot name="default"></slot>
      </div>

      <df-button v-on:click="onClose()" v-if="!persistent">
        <template v-slot:icon>
          <df-icon code="f00d" />
        </template>
      </df-button>
    </section>

    <footer class="df-alert__action" v-if="$slots.action">
      <slot name="action"></slot>
    </footer>
  </div>
</template>

<style scoped>
.df-alert {
  border-radius: var(--radius-sm);
  padding: var(--gap-md);
  display: grid;
  gap: var(--gap-sm);
  box-shadow: var(--shadow-md);
  /* dynamic based on type prop */
  color: var(--color-alert-text);
  background-color: var(--color-alert-bg);
}

.df-alert__content {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-sm);
  align-items: start;
}

.df-alert__action {
  display: grid;
}

.df-alert--icon {
  grid-template-columns: max-content 1fr max-content;
}

.df-alert__text {
  font-weight: 500;
  align-self: center;
}
</style>
