<script setup lang="ts">
import { ref, watch } from 'vue';
import { date, isValidDate } from '/@utilities/intl';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

const props = defineProps<{
  modelValue?: any;

  value?: any;
  label?: string;

  placeholder?: string;
  elevate?: boolean;
  required?: boolean;
  disabled?: boolean;
  hideWeekday?: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'update']);

// values
const iDate = ref(props.modelValue || props.value || null);
// settings
const locale = ref('nb-no');
const popoverSettings = ref({ placement: 'bottom', visibility: 'click' });

watch(iDate, (newDate) => {
  emit('update:modelValue', newDate);
  emit('update', newDate);
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (!newValue) {
      iDate.value = null;
      return;
    }

    const convertedDate = new Date(newValue);
    convertedDate.setHours(12);
    const convertedDateString = isValidDate(newValue) ? convertedDate.toISOString() : null;

    iDate.value = convertedDateString;
  },
);
</script>

<template>
  <div
    class="datepicker-wrapper"
    :class="{
      'datepicker-wrapper--elevate': elevate,
      'datepicker-wrapper--disabled': disabled,
    }"
  >
    <date-picker v-model="iDate" :locale="locale" :popover="popoverSettings">
      <template v-slot="{ togglePopover }">
        <button
          class="button-reset"
          v-on:click="togglePopover()"
          type="button"
          :disabled="disabled"
        >
          <label class="datepicker">
            <div class="datepicker__label">{{ label }}</div>

            <div class="datepicker__value" v-if="iDate">
              {{ date(iDate, { weekday: props.hideWeekday ? false : true }) }}
            </div>

            <div class="datepicker__placeholder" v-else>
              {{ placeholder }}
            </div>

            <df-icon class="datepicker__icon" code="f133" />
          </label>
        </button>
      </template>
    </date-picker>

    <df-button v-if="iDate && !required" v-on:click="iDate = null">
      <template v-slot:icon>
        <df-icon code="f00d" />
      </template>
    </df-button>
  </div>
</template>

<style scoped>
.datepicker-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-sm);
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  padding: var(--gap-sm) var(--gap-md);
  border-radius: var(--radius-sm);
  border: 1px solid var(--color-border);
}

.datepicker-wrapper:hover {
  background-color: var(--color-hover);
}

.datepicker-wrapper--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

.datepicker-wrapper--disabled {
  background-color: var(--color-darken);
}

.button-reset {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  outline: none;
  align-items: center;
}

.datepicker {
  display: grid;
  grid-template-areas: 'label icon' 'value icon';
  grid-template-columns: 1fr max-content;
  align-content: center;
  align-items: center;
  justify-items: start;
  width: 100%;
  margin: 0;
}

.datepicker__label {
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: left;
}

.datepicker__icon {
  grid-area: icon;
}

.datepicker__value {
  grid-area: value;

  &::first-letter {
    text-transform: capitalize;
  }
}

.datepicker__placeholder {
  grid-area: value;
  opacity: 0.5;
}

.reset-icon {
  cursor: default;
  display: grid;
}
</style>
