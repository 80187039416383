<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { loadResourceEventsByDate } from '/@stores/resources';
import { useUserStore } from '/@stores/user';
import { Modules, ProjectProperties } from '/@types/ids';
import { ResourceEvent } from '/@types/resources';
import { dateToString } from '/@utilities/date';
import { time, date } from '/@utilities/intl';
import { projectsLinkFromModuleIds } from '/@features/project/project.tools';
import { Project, ProjectProperty } from '/@features/project/project.types';
import { useProjectStore } from '/@features/project';

function removeTime(date: Date) {
  return date.getDate();
}

const props = defineProps({
  event: {
    type: Object as PropType<ResourceEvent>,
  },

  close: Function,

  day: Date,
});

const userStore = useUserStore();
const projectStore = useProjectStore();
const { user } = storeToRefs(userStore);

const loading = ref(0);

const activeEvents = ref([]);

const showAll = ref(false);

const showProjectInfo = ref(false);
const project = ref<Project | null>(null);
const projectProperties = ref<Map<number, ProjectProperty>>(new Map());
const projectInfo = ref<Array<{ label: string; value: string }>>([]);

async function showProjectInfoData(projectId: number) {
  showProjectInfo.value = true;

  if (project != null && project.value?.id !== projectId) {
    project.value = null;

    Promise.all([
      projectStore.loadProjectById(projectId),
      projectStore.loadProjectProperties(projectId),
    ]).then(([project, properties]) => {
      project.value = project;
      projectProperties.value = properties;

      projectInfo.value = [
        { label: 'Status', value: project.statusName },
        { label: 'Saksbehandler', value: project.caseworkerName },
        { label: 'Adresse', value: project.address },
        { label: 'Oppdragsgiver', value: project.value.contactCompanyName },
        { label: 'Kontrakts-/ordrenummer', value: project.ref1 },
        { label: 'Internnummer', value: project.ref2 },
        { label: 'Ansvarlig montør', value: project.installer },
        { label: 'Avtale opprettet av', value: props.event.createdByUserName },
        ...[...properties]
          .filter(([id, p]) =>
            [ProjectProperties.RessursPrioritet, ProjectProperties.RessursKommentar].includes(id),
          )
          .map(([id, p]) => ({ label: p.name, value: p.value })),
        { label: 'Beskrivelse', value: project.description, large: true },
      ];
    });
  }
}

const initialize = () => {
  const event = props.event;
  loading.value++;
  loadResourceEventsByDate(event.fromDate, event.toDate)
    .then((res) => {
      if (!res) return;

      const a = res
        .filter((event2) => event2.projectId === event.projectId)
        .map((event) => {
          const s = removeTime(props.day);
          return {
            ...event,
            isSelectedDate:
              s >= event.fromDate.getDate() && s <= event.toDate.getDate() ? true : false,
          };
        })
        .sort((a, b) => +a.fromDate - +b.fromDate);

      activeEvents.value = a;
    })
    .finally(() => {
      loading.value--;
    });
};

initialize();
</script>

<template>
  <div class="resource-dialog">
    <header class="head">
      <df-button v-on:click="close()">
        <template v-slot:icon>
          <df-icon code="f060" />
        </template>
      </df-button>

      <h1 class="title-md">{{ event.projectName }}</h1>
    </header>

    <dl class="dl card" style="padding: 0">
      <div class="dl__group dl__group--large dl__group--actions">
        <h2 class="dl__topic">Prosjekt</h2>
        <p class="dl__description">{{ event.projectName }}</p>

        <div class="dl__actions">
          <df-button
            v-on:click="
              showProjectInfo
                ? (showProjectInfo = !showProjectInfo)
                : showProjectInfoData(event.projectId)
            "
            target="_blank"
            :active="showProjectInfo"
          >
            <template v-slot:icon>
              <df-icon code="f05a" />
            </template>
          </df-button>

          <template v-if="event.projectAccess && event.projectModuleIds.length > 0">
            <!-- This is a single item loop so we prevent calling the function twice (once for v-if and another for :href) -->
            <df-button
              v-for="link in [
                projectsLinkFromModuleIds(event.projectModuleIds, event.projectId),
              ].filter((x) => x)"
              target="_blank"
              :href="link"
            >
              <template v-slot:icon>
                <df-icon code="f08e" />
              </template>
            </df-button>
          </template>
        </div>
      </div>

      <template v-if="showProjectInfo && projectInfo == null">
        <div class="dl__group dl__group--large dl__group--horizontal">
          <div class="dl__description">
            <div class="loading-icon"></div>
          </div>
        </div>
      </template>

      <template v-else-if="showProjectInfo">
        <template v-for="entry in projectInfo" :key="entry.label">
          <div class="dl__group dl__group--sub" :class="{ 'dl__group--large': entry.large }">
            <h2 class="dl__topic">{{ entry.label }}</h2>
            <p class="dl__description">{{ entry.value || '-' }}</p>
          </div>
        </template>
      </template>

      <div class="dl__group dl__group--large" v-if="event.userName">
        <dt class="dl__topic">Ressurs</dt>
        <dd class="dl__description">{{ event.userName }}</dd>
      </div>

      <div class="dl__group">
        <dt class="dl__topic">Tid</dt>
        <dd class="dl__description">{{ time(event.fromDate) }}</dd>
      </div>

      <div class="dl__group">
        <dt class="dl__topic">Dato</dt>
        <dd class="dl__description">{{ date(day) }}</dd>
      </div>

      <div class="dl__group dl__group--large">
        <dt class="dl__topic">Avtalekommentar</dt>
        <dd class="dl__description">{{ event.comment || 'Ingen kommentar' }}</dd>
      </div>
    </dl>

    <div class="resource-dialog__actions">
      <df-button
        v-if="user?.tenant.moduleIds.includes(Modules.Timer)"
        :to="`/timer/new?projectId=${event.projectId}&date=${dateToString(day)}`"
        elevate
      >
        Registrer timer

        <template v-slot:icon>
          <df-icon code="f017" />
        </template>
      </df-button>

      <template v-if="event.projectAccess && event.projectModuleIds.length > 0">
        <!-- This is a single item loop so we prevent calling the function twice (once for v-if and another for :href) -->
        <df-button
          v-for="link in [
            projectsLinkFromModuleIds(event.projectModuleIds, event.projectId),
          ].filter((x) => x)"
          elevate
          target="_blank"
          :href="link"
        >
          Åpne prosjekt

          <template v-slot:icon>
            <df-icon code="f08e" />
          </template>
        </df-button>
      </template>
    </div>

    <div class="card user-list" v-if="activeEvents.length > 1">
      <header class="user-list-header">
        <h2 class="title-sm">Personer på samme avtale</h2>

        <df-checkbox v-model="showAll">Alle</df-checkbox>
      </header>

      <ul>
        <li
          v-for="event in activeEvents.filter((e) => (showAll ? true : e.isSelectedDate))"
          :class="{ '--bold': event.isSelectedDate }"
        >
          <div>{{ event.userName }}</div>
          <div>
            {{
              event.fromDate.getDate() === event.toDate.getDate()
                ? date(event.fromDate, { short: true })
                : [date(event.fromDate, { short: true }), date(event.toDate, { short: true })].join(
                    ' - ',
                  )
            }}
          </div>
        </li>
      </ul>

      <div class="loading-icon" v-if="loading > 0"></div>
    </div>
  </div>
</template>

<style scoped>
.resource-dialog {
  display: grid;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
}

.resource-dialog__actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-md);
}

ul {
  font-size: 0.8rem;
  max-height: 300px;
  overflow: auto;
}

li {
  display: flex;
  justify-content: space-between;
  padding: var(--gap-sm);
  opacity: 0.5;
}

.--bold {
  font-weight: 500;
  opacity: 1;
}

.user-list-header {
  display: flex;
  justify-content: space-between;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.dl__group {
  /* background-color: pink; */
}
</style>
