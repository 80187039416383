<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlert } from '/@elements/DfAlert/DfAlert.vue';
import { useTimerStore } from '/@stores/timer';
import { useTenantStore } from '/@stores/tenant';
import { RegistrationStatusIds } from '/@types/ids';
import { errorText } from '/@utilities/api';
import { date } from '/@utilities/intl';
import { search } from '/@utilities/general';

type FilterLists = {
  users: Map<string, { label: string }>;
  articles: Map<string, { label: string }>;
};

const loading = ref(0);

const alert = useAlert();

const timerStore = useTimerStore();
const tenantStore = useTenantStore();

const { errorRegistrations } = storeToRefs(timerStore);
const { departments } = storeToRefs(tenantStore);

const searchVal = ref(null);
const userVal = ref(null);
const articleVal = ref(null);

const filterLists = computed<FilterLists>(() => {
  const one = [...errorRegistrations.value.values()].reduce<{
    users: Set<string>;
    articles: Set<string>;
  }>(
    (acc, cv) => {
      acc.users.add(cv.user);
      acc.articles.add(cv.article);
      return acc;
    },
    {
      users: new Set(),
      articles: new Set(),
    },
  );

  return {
    users: new Map([...one.users].map((u) => [u, { label: u }])),
    articles: new Map([...one.articles].map((a) => [a, { label: a }])),
  };
});

const filteredRows = computed(() => {
  return new Map(
    [...errorRegistrations.value].filter(([k, v]) => {
      const fSearch = searchVal.value ? search(searchVal.value, v.project) : true;
      const fUsers = userVal.value ? v.user === userVal.value : true;
      const fArticles = articleVal.value ? v.article === articleVal.value : true;
      return fSearch && fUsers && fArticles;
    }),
  );
});

const regStatuses = reactive(
  new Map([
    [RegistrationStatusIds.Created, 'Opprettet'],
    [RegistrationStatusIds.Approved, 'Godkjent'],
  ]),
);

const formStatus = reactive({
  started: false,
  total: 0,
  success: new Set(),
  errors: new Set(),
});

async function changeAll(statusId: number) {
  const regIds = [...errorRegistrations.value.keys()];
  formStatus.total = regIds.length;
  formStatus.started = true;

  for (const regId of [...errorRegistrations.value.keys()]) {
    try {
      await timerStore.changeRegistrationStatus(regId, statusId);
      formStatus.success.add(regId);
    } catch {
      formStatus.errors.add(regId);
    }
  }
}

async function changeRegistrationStatus(regId: number, statusId: number) {
  loading.value++;
  timerStore
    .changeRegistrationStatus(regId, statusId)
    .catch(() => {
      alert.write(errorText());
    })
    .finally(() => {
      loading.value--;
    });
}

function initialize() {
  loading.value++;
  Promise.all([timerStore.loadFailedRegistrations(), tenantStore.loadDepartments()]).then(() => {
    loading.value--;
  });
}

initialize();
</script>

<template>
  <div class="page">
    <df-header back>Registreringer som har feilet</df-header>

    <df-loading v-if="loading > 0" />

    <section class="card info">
      <p>Denne siden viser timeregistreringer som har feilet i integrasjonen.</p>
      <p>Du kan endre status til "Opprettet" eller "Godkjent".</p>
      <p>En godkjent registrering vil automatisk gå gjennom integrasjonen på nytt.</p>
    </section>

    <df-alert v-model="alert" />

    <section class="filters card">
      <df-input-search v-model="searchVal" placeholder="Prosjektnavn" />

      <div class="filter-group">
        <df-select3
          placeholder="Bruker"
          :entries="filterLists.users"
          display="label"
          v-model="userVal"
        />

        <df-button v-if="userVal" v-on:click="userVal = null">
          <template v-slot:icon><df-icon code="f00d" /></template>
        </df-button>
      </div>

      <div class="filter-group">
        <df-select3
          placeholder="Artikkel"
          :entries="filterLists.articles"
          display="label"
          v-model="articleVal"
        />

        <df-button v-if="articleVal" v-on:click="articleVal = null">
          <template v-slot:icon><df-icon code="f00d" /></template>
        </df-button>
      </div>
    </section>

    <div class="card grid" v-if="formStatus.started">
      <div class="row" v-if="formStatus.total === formStatus.success">
        <df-loading no-float />
        Endrer status
      </div>

      <df-progress
        action-text="endret"
        :current-value="formStatus.success.size"
        :totalValue="formStatus.total"
        :failed-Values="formStatus.errors.size"
      />
    </div>

    <section class="tb">
      <table class="card">
        <thead>
          <tr>
            <th>Prosjekt</th>
            <th>Dato</th>
            <th>Bruker</th>
            <th>Avdeling</th>
            <th>Artikkel</th>
            <th>Antall</th>
            <th>Status</th>
            <th style="width: 30%">Feilmelding</th>
            <th>
              <div class="action-row">
                <df-dropdown label="Behandle alle" no-icon>
                  <df-dropdown-item
                    v-for="[statusId, status] in regStatuses"
                    :key="statusId"
                    v-on:click="changeAll(statusId)"
                  >
                    {{ status }}
                  </df-dropdown-item>
                </df-dropdown>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="[regId, reg] in [...filteredRows].slice(0, 100)" :key="regId">
            <td>{{ reg.project }}</td>
            <td>{{ date(reg.date) }}</td>
            <td>{{ reg.user }}</td>
            <td :class="{ 'empty-cell': !departments.get(reg.userDepartmentId) }">
              {{ departments.get(reg.userDepartmentId) || '–' }}
            </td>
            <td>{{ reg.article }}</td>
            <td style="text-align: right">{{ reg.hours }}</td>
            <td>
              <div
                class="badge"
                :class="{
                  'badge--success': reg.statusId >= RegistrationStatusIds.Approved,
                  'badge--danger': reg.statusId === RegistrationStatusIds.TransferFailed,
                }"
              >
                {{ reg.status }}
              </div>
            </td>
            <td>
              {{ reg.error }}
            </td>
            <td>
              <div class="action-row">
                <df-button :to="`admin/${regId}`">
                  <template v-slot:icon>
                    <df-icon code="f05a" />
                  </template>
                </df-button>

                <df-dropdown label="Behandle" no-icon>
                  <df-dropdown-item
                    v-for="[statusId, status] in regStatuses"
                    :key="statusId"
                    v-on:click="changeRegistrationStatus(regId, statusId)"
                  >
                    Endre til {{ status }}
                  </df-dropdown-item>
                </df-dropdown>
              </div>
            </td>
          </tr>

          <tr v-if="filteredRows.size === 0 && loading === 0">
            <td class="empty-cell" colspan="8">Ingen registreringer funnet</td>
          </tr>
        </tbody>
      </table>
    </section>

    <df-modal v-if="$route.matched.length > 1">
      <router-view></router-view>
    </df-modal>
  </div>
</template>

<style scoped>
.page > * {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

table {
  width: 100%;
  padding: 0;
  border-collapse: collapse;
}

th {
  background-color: var(--color-cardbg);
  border-bottom: 1px solid var(--color-border);
  text-align: left;
  padding: var(--gap-md);
  top: 0;
  position: sticky;
  z-index: 1;
}

tr {
  content-visibility: auto;
}

td {
  font-size: 0.8rem;
  padding: var(--gap-md);
}

.action-row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: var(--gap-sm);
}

.info {
  background-color: var(--color-info_bg);
  color: var(--color-info_text);
}

.grid {
  display: grid;
  gap: var(--gap-sm);
}

.empty-cell {
  padding: var(--gap-md);
  text-align: center;
}

.row {
  display: flex;
  gap: var(--gap-md);
}

.filters {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);

  & > .filter-group {
    display: flex;
    gap: var(--gap-sm);
  }
}

.tb {
  max-height: 1000px;
  overflow: auto;
}
</style>
