<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useTimerStore } from '/@stores/timer';
import { awaitUser, useUserStore } from '/@stores/user';
import { useRoute } from 'vue-router';
import { date } from '/@utilities/intl';

const loading = ref(0);

const route = useRoute();

const timerStore = useTimerStore();
const { user } = storeToRefs(useUserStore());

async function initialize() {
  loading.value++;
  await awaitUser;
  await timerStore.loadRejectedRegistrations(user.value?.id);
  loading.value--;
}

initialize();
</script>

<template>
  <section class="page">
    <df-loading v-if="loading" />

    <section class="rejected-registrations">
      <h2>Avviste registreringer</h2>

      <df-alert persistent show>
        <p>Registreringer som har blitt avvist av saksbehandler</p>
        <p>Åpne registrering og les kommentar for årsak.</p>
      </df-alert>

      <ul>
        <li class="card reg" v-for="[id, reg] in timerStore.rejectedRegistrations" :key="id">
          <div class="reg-text">
            <div>{{ reg.projectName }}</div>
            <div class="text--small">
              {{ [reg.articleName, reg.activityName].filter((i) => i).join(' - ') }}
            </div>
          </div>

          <ul class="reg-badges">
            <li class="badge">
              <df-icon code="f017" />
              {{ date(reg.date) }}
            </li>

            <li class="badge">
              <df-icon code="e202" />
              {{ reg.notesCount }}
            </li>
          </ul>

          <menu class="reg-actions">
            <li><df-button :to="`/notifications/${id}`">Åpne</df-button></li>
          </menu>
        </li>

        <li class="placeholder" v-if="timerStore.rejectedRegistrations.size === 0">
          Ingen registreringer er avvist
        </li>
      </ul>
    </section>
  </section>

  <df-modal v-if="route.matched.length > 1">
    <router-view></router-view>
  </df-modal>
</template>

<style scoped>
.rejected-registrations {
  display: grid;
  gap: var(--gap-md);
}

li {
  list-style: none;
}

.reg {
  display: grid;
  grid-template-areas: 'text actions' 'badges actions';
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: var(--gap-sm);
  padding: var(--gap-sm) var(--gap-md);
}

.reg-text {
  grid-area: text;
}

.reg-badges {
  grid-area: badges;
  display: flex;
  gap: var(--gap-md);
  flex-wrap: wrap;
}

.reg-actions {
  grid-area: actions;
}
</style>
