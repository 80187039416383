<script setup lang="ts">
import DfTabs from '/@elements/DfTabs/DfTabs.vue';
import { useUserStore } from '/@stores/user';

import UserProfileUser from './UserProfileUser.vue';
import UserProfilePassword from './UserProfilePassword.vue';
import UserProfileTimer from './UserProfileTimer.vue';

const userStore = useUserStore();
</script>

<template>
  <div class="page page--wide">
    <df-header>Min profil</df-header>

    <df-tabs
      :tabs="[
        { name: 'Profil', id: 'profile', icon: 'f007' },
        { name: 'Passord', id: 'password', icon: 'f023' },
      ]"
    >
      <template #tab:profile>
        <UserProfileUser />
      </template>

      <template #tab:password>
        <UserProfilePassword />
      </template>

      <template #tab:time>
        <UserProfileTimer />
      </template>
    </df-tabs>
  </div>
</template>

<style scoped></style>
