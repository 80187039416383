<script setup lang="ts" generic="T extends {}">
import { computed, ref, watch } from 'vue';
import { search } from '/@utilities/general';

const props = withDefaults(
  defineProps<{
    label?: string;
    // used for reference in case the real entries are filtered and input is in read mode
    entriesList?: Map<number | string, T>;
    entries: Map<number | string, T>;
    filterItems?: string[];
    display: keyof T;
    value?: number;
    placeholder?: string;
    modelValue?: number | null;
    disabled?: boolean;
    remoteSearch?: boolean;
    remoteLoading?: boolean;
    search?: string;
    useSort?: boolean;
    elevate?: boolean;
  }>(),
  {
    placeholder: 'Velg fra liste',
    search: '',
    useSort: true,
  },
);

const emit = defineEmits(['select', 'update:modelValue', 'update:search']);

const showModal = ref(false);
const searchQuery = ref(props.search);

function sortMap(i: Map<number, T>) {
  if (!props.useSort) return i;
  const sortName = [...i].sort((a, b) => a[1][props.display].localeCompare(b[1][props.display]));

  return sortName.sort((a, b) => {
    const x = a[1].sortOrder;
    const y = b[1].sortOrder;
    if (x === null) return 1;
    if (y === null) return -1;
    if (y < x) return 1;
    if (y > x) return -1;
  });
}

const filterValue = ref<string | null>(null);

const filteredEntries = computed(() => {
  const entries = new Map(sortMap(props.entries));

  return new Map(
    [...entries].filter(([id, prop]) => {
      const useSearch = props.remoteSearch ? true : search(searchQuery.value, prop[props.display]);
      const useFilter = filterValue.value ? prop.labels.has(filterValue.value) : true;
      return useSearch && useFilter;
    }),
  );
});

const selectedValue = computed(() => {
  if (props.modelValue) {
    return (
      props.entries?.get(props.modelValue)?.[props.display] ||
      props.entriesList?.get(props.modelValue)?.[props.display] ||
      'Ukjent verdi'
    );
  }

  return props.placeholder;
});

function toggle() {
  if (props.disabled) return;
  showModal.value = !showModal.value;
}

function close() {
  showModal.value = false;
}

function select(value: any) {
  emit('select', value);
  emit('update:modelValue', value);
  close();
}

watch(searchQuery, (value) => emit('update:search', value));
</script>

<template>
  <slot name="trigger" :toggle="toggle" :value="selectedValue">
    <button
      class="select"
      :class="{ 'select--disabled': disabled, 'select--elevate': elevate }"
      v-on:click="toggle()"
      type="button"
    >
      <div class="select-label">
        {{ label }}
      </div>

      <div class="select-value">
        {{ selectedValue }}
      </div>

      <div class="select-icon">
        <slot name="icon">
          <df-icon code="f078" />
        </slot>
      </div>
    </button>
  </slot>

  <teleport to="#overlay" v-if="showModal">
    <div class="select-modal-container" v-on:click.self="showModal = false">
      <div class="select-modal">
        <header>
          <div class="modal-label">{{ label }}</div>

          <button type="button" v-on:click="close()">
            <df-icon code="f00d" />
          </button>
        </header>

        <label class="search" v-if="remoteSearch || entries.size > 10">
          <div><df-icon code="f002" /></div>

          <input v-model="searchQuery" class="search-input" inputmode="search" placeholder="Søk" />

          <button v-if="searchQuery" type="button" v-on:click="searchQuery = ''">
            <df-icon code="f00d" />
          </button>
        </label>

        <div class="tags" v-if="filterItems && filterItems.length > 0">
          <div
            class="s-button"
            :class="{ '--active': filterValue === i }"
            style="cursor: default"
            v-for="i in filterItems"
            v-on:click="filterValue === i ? (filterValue = null) : (filterValue = i)"
            type="button"
          >
            {{ i }}
          </div>
        </div>

        <div class="entries">
          <div class="entry" v-if="remoteLoading">
            <div class="loading-icon"></div>
          </div>

          <button
            v-for="[id, entry] in filteredEntries"
            :key="id"
            v-on:click="select(id)"
            type="button"
            class="entry"
            :class="{ 'entry--selected': id === modelValue }"
          >
            <div>{{ display ? entry[display] : entry }}</div>

            <div class="extra" v-if="$slots.extra">
              <slot name="extra" :entry="entry"></slot>
            </div>
          </button>

          <div class="noresult" v-if="filteredEntries.size === 0">
            {{ searchQuery ? 'Ingen funn på søk' : 'Liste er tom' }}
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.select-modal-container {
  background-color: var(--color-backdrop);
}

.select-modal {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-lg);
  display: grid;
  align-content: start;
  border-radius: var(--radius-sm);
  z-index: 2;
  position: fixed;
  top: 40%;
  width: 100%;
  bottom: 0;

  animation-name: slidein;
  animation-duration: 0.25s;
}

button {
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

button:hover:not(.select--disabled) {
  background-color: var(--color-hover);
  border-color: var(--color-hover);
}

header button:hover {
  border-top-right-radius: var(--radius-sm);
}

.select {
  display: grid;
  grid-template-areas: 'label icon' 'value icon';
  grid-template-columns: 1fr max-content;
  border: 1px solid var(--color-border);
  padding: var(--gap-sm) var(--gap-md);
  border-radius: var(--radius-sm);
  gap: 0 var(--gap-md);
  width: 100%;
}

.select--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

.select--disabled {
  opacity: 0.5;
}

.select-label {
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
}

.modal-label {
  font-weight: 600;
}

.select-value {
  grid-area: value;
}

.select-icon {
  grid-area: icon;
  align-self: center;
}

header {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: var(--gap-md);
  border-bottom: 1px solid var(--color-border);
}

header > * {
  padding: var(--gap-md);
}

.entries {
  display: grid;
  overflow: auto;
  border-top: 1px solid var(--color-border);

  @media (min-width: 900px) {
    max-height: 400px;
  }
}

.entry {
  padding: var(--gap-sm);
  padding: var(--gap-md);
}

.entry--selected {
  background-color: var(--color-hover);
}

.entries > .entry {
  border-bottom: 1px solid var(--color-border);
}

.search {
  border-bottom: 3px solid var(--color-border);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  border: 1px solid var(--color-border);
  margin: var(--gap-md);
  border-radius: var(--radius-sm);
}

.search > * {
  padding: var(--gap-md);
}

.search:focus-within {
  outline: 1px solid var(--color-hover);
}

.search-input {
}

.search > button {
  border-radius: var(--radius-sm);
}

input {
  border: none;
  padding: 0;
  margin: 0;
  background: inherit;
}

input:focus {
  outline: none;
}

.noresult {
  padding: var(--gap-md);
  text-align: center;
}

.tags {
  display: flex;
  padding: var(--gap-md);
  gap: var(--gap-md);
}

.s-button {
  border: 1px solid var(--color-link);
  padding: var(--gap-sm) var(--gap-md);
  border-radius: var(--radius-sm);
  min-width: 2rem;
  text-align: center;
  &:hover,
  &.--active {
    background-color: var(--color-link);
  }
}

@media (min-width: 900px) {
  .select-modal {
    top: 70vh;
    left: 50%;
    transform: translate(-50%, -50vh);
    width: 500px;
    height: auto;
    bottom: auto;
    animation-name: none;
  }

  .entries > .entry:last-child {
    border-bottom: none;
  }
}
</style>
