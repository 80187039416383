<script lang="ts" setup>
import { groupBy } from 'lodash-es';
import { computed, reactive, ref, watch } from 'vue';
import { loadResourceEventsBySearch } from '/@stores/resources';
import { date } from '/@utilities/intl';
import { Return } from '/@types/helpers';
import { ResourceEvent } from '/@types/resources';

const events = ref<Return<typeof loadResourceEventsBySearch>>([]);

const searches = reactive({
  userName: '',
  projectName: '',
});

watch(
  searches,
  () => {
    search();
  },
  { immediate: true },
);

function search() {
  loadResourceEventsBySearch({
    projectName: searches.projectName,
    userName: searches.userName,
  }).then((aaa) => {
    events.value = aaa;
  });
}

const projectId = ref<number | null>(null);

function setProject(event: ResourceEvent) {
  projectId.value = event.projectId;
  searches.projectName = event.projectName;
}

const projectEntries = computed(() => {
  const groupedEvents = groupBy(events.value, 'projectId');
  const arrayedEvents = new Map(Object.entries(groupedEvents));
  const selectedProjectEntries = arrayedEvents.get(String(projectId.value));

  return selectedProjectEntries;
});

const projectData = computed(() => {
  const e = projectEntries.value?.reduce(
    (acc, cv) => {
      return {
        first: cv.fromDate < acc.first || acc.first === null ? cv.fromDate : acc.first,
        last: cv.toDate > acc.last || acc.last === null ? cv.toDate : acc.last,
      };
    },
    {
      first: null,
      last: null,
    },
  );

  if (!projectEntries.value) return [];

  return [
    {
      label: 'Prosjektnavn',
      value: projectEntries.value[0]?.projectName,
    },
    {
      label: 'Første avtaledato',
      value: date(e.first),
    },
    {
      label: 'Siste avtaledato',
      value: date(e.last),
    },
  ];
});
</script>

<template>
  <div class="page">
    <h1>Søk avtaler</h1>

    <header class="search">
      <df-input-search elevate placeholder="Søk på prosjektnavn" v-model="searches.projectName" />

      <df-input-search elevate placeholder="Søk på brukernavn" v-model="searches.userName" />
    </header>

    <dl v-if="projectId">
      <div v-for="entry in projectData" :key="entry.label">
        <dt>{{ entry.label }}</dt>
        <dd>{{ entry.value }}</dd>
      </div>
    </dl>

    <div class="table-wrapper">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 40%">Prosjekt</th>
            <th style="width: 30%">Bruker</th>
            <th style="width: 10%" class="--right">Ansattnr.</th>
            <th style="width: 10%" class="--right">Fra</th>
            <th style="width: 10%" class="--right">Til</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="event in events" :key="event.guid">
            <td v-on:click="setProject(event)" class="name-col">{{ event.projectName }}</td>
            <td>{{ event.userName }}</td>
            <td class="--right">{{ event.createdByUserEmployeeNo }}</td>
            <td class="--right">{{ date(event.fromDate, { weekday: false, short: true }) }}</td>
            <td class="--right">{{ date(event.toDate, { weekday: false, short: true }) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.page > * {
  max-width: 999px;
}

.search {
  display: grid;
  gap: var(--gap-md);
  grid-template-columns: repeat(2, 1fr);
}

dl {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-md);
}

dt {
  font-size: 0.8rem;
  font-weight: 500;
}

dd {
}

.name-col:hover {
  background-color: var(--color-hover);
}

.table-wrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
}

tr {
  text-align: left;
}

.--right {
  text-align: right;
}
</style>
