<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  code: string;

  solid?: boolean;
  fit?: boolean;
}>();

const html = computed(() => `&#x${props.code};`);
</script>
<template>
  <div
    class="fa-icon"
    :class="{ 'fa-icon--solid': solid, 'fa-icon--fit': fit }"
    v-html="html"
  ></div>
</template>
