<script setup lang="ts">
import { useRouter } from 'vue-router';

const emit = defineEmits(['click', 'input']);

const props = defineProps<{
  to?: string;
  back?: boolean;
  noRoute?: boolean;
}>();

const router = useRouter();

function goBack() {
  if (props.noRoute) {
    emit('click');
  } else {
    router.back();
  }
}
</script>

<template>
  <header class="header" :class="{ 'header--back': back }">
    <div class="header__back" v-if="$slots.back || back">
      <slot name="back">
        <df-button v-on:click="goBack()">
          <template v-slot:icon>
            <df-icon code="f060" />
          </template>
        </df-button>
      </slot>
    </div>

    <div class="header__text">
      <span class="header__title title-md">
        <slot name="default"></slot>
      </span>

      <span class="header__subtitle" v-if="$slots.subtitle">
        <slot name="subtitle"></slot>
      </span>
    </div>

    <div class="header__actions" v-if="$slots.actions">
      <slot name="actions"></slot>
    </div>
  </header>
</template>

<style scoped>
.header {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: var(--gap-md);
  align-items: start;
  align-content: start;
}

.header--back {
  grid-template-columns: max-content 1fr max-content;
}

.header__back {
}

.header__text {
  align-self: center;
  display: grid;
}

.header__title {
  font-size: 1rem;

  &::first-letter {
    text-transform: capitalize;
  }
}

.header__subtitle {
  font-size: 0.8rem;
  &::first-letter {
    text-transform: capitalize;
  }
}

.header__actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);
}
</style>
