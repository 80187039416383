import { getISOWeek } from 'date-fns';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default function useWeek() {
  const route = useRoute();
  const router = useRouter();

  const today = new Date();

  const weekNo = ref(route.query.weekNo ? Number(route.query.weekNo) : getISOWeek(today));
  watch(weekNo, () => setRouterQueries());
  watch(
    () => route.query.weekNo,
    () => {
      if (route.query.weekNo && weekNo.value !== Number(route.query.weekNo)) {
        weekNo.value = Number(route.query.weekNo);
      }
    },
  );

  const year = ref(route.query.year ? Number(route.query.year) : today.getFullYear());
  watch(year, () => setRouterQueries());
  watch(
    () => route.query.year,
    () => {
      if (route.query.year && year.value !== Number(route.query.year)) {
        year.value = Number(route.query.year);
      }
    },
  );

  function setRouterQueries() {
    router.replace({
      query: {
        year: year.value,
        weekNo: weekNo.value,
      },
    });
  }

  function setWeekNo(next: boolean) {
    const weeksTotal = 52;

    if (next) {
      if (weekNo.value === weeksTotal) {
        weekNo.value = 1;
        year.value = year.value + 1;
      } else {
        weekNo.value = weekNo.value + 1;
      }
    } else {
      if (weekNo.value === 1) {
        year.value = year.value - 1;
        weekNo.value = weeksTotal;
      } else {
        weekNo.value = weekNo.value - 1;
      }
    }
  }

  function resetWeekNo() {
    weekNo.value = getISOWeek(today);
    year.value = today.getFullYear();
  }

  return {
    weekNo,
    year,
    setWeekNo,
    resetWeekNo,
  };
}
