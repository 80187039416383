import {
  addDays,
  endOfWeek,
  getISODay,
  getISOWeek,
  getMonth,
  getYear,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import { days, getDateOfWeek, getTimeFromDate, months } from './date';
import { Registration, RegistrationForm } from '/@types/timer';
import { RegistrationStatusIds } from '/@types/ids';
import { useTenantStore } from '/@stores/tenant';

export function populateRegistrationForm(registration: Registration): RegistrationForm {
  const reg = registration;

  return {
    date: reg.date,
    hours: reg.hours,
    articleId: reg.articleId,
    projectId: reg.projectId,
    description: reg.description,
    fromDateTime: getTimeFromDate(reg.fromDateTimeOffset),
    toDateTime: getTimeFromDate(reg.toDateTimeOffset),
    userId: reg.userId,
    articleIdCopy: null,
    activities: reg.activityId
      ? [
          {
            id: reg.activityId,
            hours: reg.hours,
            description: reg.description,
          },
        ]
      : [],
  };
}

export interface EventItem {
  id: number;
  date: Date;
  name: string;
  project: string;
  correction: boolean;
  approved: boolean;
  count: string;
  countNo: number;
  notesCount: number;

  isTime: boolean;
  isLabel: boolean;
  isDay: boolean;
  isExpense: boolean;
}

function mapEvents(registrations: Array<Registration>): Array<EventItem> {
  return registrations
    .map((reg) => ({
      id: reg.id,
      date: reg.date,
      name: reg.articleName,
      project: reg.projectName,

      isLabel: reg.isLabel,
      isTime: reg.isTime,
      isDay: reg.isDay,
      isExpense: reg.isExpense,

      correction: reg.isCorrection,
      approved: [
        RegistrationStatusIds.Approved,
        RegistrationStatusIds.TransferReceived,
        RegistrationStatusIds.TransferSuccess,
      ].includes(reg.statusId),
      count: maskHours(reg.hours),
      countNo: reg.hours,
      notesCount: reg.notesCount,
    }))
    .sort((a, b) => {
      return a.isExpense ? -1 : 1;
    });
}

// creates event ready for use in week / day

function filterByDate(list: Map<number, Registration>, date: Date) {
  return new Map(
    [...list].filter(([_, reg]) => {
      const year = date.getFullYear() === reg.date.getFullYear();
      const month = date.getMonth() === reg.date.getMonth();
      const day = date.getDate() === reg.date.getDate();
      return year && month && day;
    }),
  );
}

export function maskHours(value): string {
  return value ? String(value).replace('.', ',') : value;
}

function countHours(list: Array<Registration>) {
  return maskHours(list.reduce((acc, cv) => acc + cv.hours, 0));
}

function getDateData(weekNo: number, year: number) {
  const date = getDateOfWeek(year, weekNo);
  const fromDate = startOfWeek(date, { weekStartsOn: 1 });
  const weekDays = Array.from(Array(7)).map((e, i) => addDays(fromDate, i));

  return {
    year,
    month: months[getMonth(fromDate)],
    weekNo,
    fromDate,
    toDate: endOfWeek(date, { weekStartsOn: 1 }),
    days: weekDays.map((day, index) => {
      return {
        date: day,
        name: days[index],
      };
    }),
  };
}

function countRemainingHours(daysCount, hoursCount) {
  if (Number(String(daysCount).replace(',', '.')) > 0) return 0;
  const tenantStore = useTenantStore();
  const totalWorkingHours = tenantStore.tenant?.totalWorkingHours || 7.5;
  return maskHours(totalWorkingHours - Number(String(hoursCount).replace(',', '.')));
}

function countRegistrations(registrations: Map<number, Registration>) {
  const regValues = [...registrations.values()];
  const daysCount = countHours(regValues.filter((i) => i.isDay));
  const hoursCount = countHours(regValues.filter((i) => !i.isDay && !i.isExpense));

  return {
    daysCount,
    hoursCount,
    expensesCount: countHours(regValues.filter((i) => i.isExpense)),
    totalCount: countHours(regValues),
    remainingCount: countRemainingHours(daysCount, hoursCount),
  };
}

function configureWeek(
  weekNo: number,
  year: number,
  registrations: Map<number, Registration>,
  finishedWeek: { days: Array<number> },
  showWeekends: boolean,
) {
  const dateData = getDateData(weekNo, year);
  const weekRegistrations = new Map(
    [...registrations].filter(([id, reg]) =>
      dateData.days.slice(0, showWeekends ? 7 : 5).some(({ date }) => isSameDay(date, reg.date)),
    ),
  );

  return {
    ...dateData,

    ...countRegistrations(weekRegistrations),

    daysFinished: finishedWeek?.days,
    weekFinished: finishedWeek?.days.length === 7,

    events: mapEvents([...weekRegistrations.values()]),
    // overwrites getDateData days
    days: dateData.days.slice(0, showWeekends ? 7 : 5).map((day, index) => {
      const dayRegistrations = filterByDate(registrations, day.date);
      return {
        date: day.date,
        day: day.date,
        registrations: dayRegistrations,
        events: mapEvents([...dayRegistrations.values()]),
        dayFinished: finishedWeek?.days.includes(index + 1),
        ...countRegistrations(dayRegistrations),
      };
    }),
  };
}

function configureDay(date: Date, registrations: Map<number, Registration>, finishedWeek) {
  const dayRegistrations = new Map(
    [...registrations].filter(([id, reg]) => isSameDay(date, reg.date)),
  );
  const dayNo = getISODay(date);

  return {
    dayNo,
    weekNo: getISOWeek(date),
    year: getYear(date),
    ...countRegistrations(dayRegistrations),
    dayFinished: finishedWeek?.days.includes(dayNo),
    registrations: dayRegistrations,
    events: mapEvents([...dayRegistrations.values()]),
  };
}

export function useRegistrations() {
  return { configureWeek, configureDay, getDateData };
}
