import { h } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { timerRoutes } from './timer';

import NotFoundPage from '/@pages/NotFoundPage/NotFoundPage.vue';
import UserProfilePage from '/@pages/UserProfilePage/UserProfilePage.vue';
import SettingsPage from '/@pages/SettingsPage/SettingsPage.vue';
import NotificationPage from '/@pages/NotificationPage/NotificationPage.vue';
import RegistrationItemPage from '/@pages/RegistrationItemPage/RegistrationItemPage.vue';
import TestPage from '/@pages/TestPage/TestPage.vue';

export const globalRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: NotFoundPage,
    redirect: timerRoutes[0].path,
  },
  {
    path: '/user',
    component: UserProfilePage,
  },
  {
    path: '/notifications',
    component: NotificationPage,
    children: [
      {
        path: ':registrationId',
        component: RegistrationItemPage,
        meta: { isModal: true },
      },
    ],
  },
  {
    path: '/settings/:tab?',
    component: SettingsPage,
    props: ({ params }) => ({
      tab: params.tab,
    }),
  },
  {
    path: '/test',
    component: TestPage,
  },
  {
    // This path doesnt need to do anything, it's just for information. See `api.ts` for actual logout logic
    path: '/logout',
    component: {
      setup() {
        return h('h1', 'Logger ut...');
      },
    },
  },
  {
    path: '/:path(.*)',
    component: NotFoundPage,
  },
];
