import { Modules } from '/@types/ids';

export const projectModulePrefixes = new Map([
  // List is ordered by what module to find first
  [Modules.Energi, 'energi'],
  [Modules.Veilys, 'veilys'],
  [Modules.Ett, 'ett'],
  [Modules.Byggestrøm, 'byggestrom'],
  [Modules.Telekom, 'telekom'],
  [Modules.Jernbane, 'jernbane'],
  [Modules.Elektro, 'elektro'],
]);

export function projectsLinkFromModuleIds(moduleIds: number[], projectId?: number) {
  for (const [id, prefix] of projectModulePrefixes) {
    if (!moduleIds.includes(id)) continue;

    return `${import.meta.env.VITE_WEBAPP_URL}/${prefix}/projects${
      projectId != null ? `/${projectId}` : ''
    }`;
  }
}
