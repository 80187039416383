import { ref } from 'vue';

export interface Toast extends ToastOptions {
  id: number,
  message: string,
  close: () => void,
  text: (msg: string) => void,
}

interface ToastOptions {
  /** Milliseconds of time before the toast dismisses */
  duration?: number,

  /** Does not timeout and have no close button: must be closed with close() */
  persistent?: boolean,

  /** Additional buttons */
  actions?: Array<ToastAction>,
}

interface ToastAction {
  label: string,
  onClick: (toast: Toast) => void,
}

export const toasts = ref<Array<Toast>>([]);

let id = 0;

export function toast(message: string, { duration = 14_000, persistent = false, actions = [] }: ToastOptions = {}): Toast {
  let timeout: any;
  const toastId = ++id;
  const toast = <Toast> {
    id: toastId,
    message,
    persistent,
    actions,

    text(message: string) {
      const index = toasts.value.findIndex(({ id }) => id === toastId);

      if (index >= 0) {
        toasts.value.splice(index, 1, {
          ...toast,
          message,
        });
      }
    },

    close() {
      const index = toasts.value.findIndex(({ id }) => id === toastId);

      if (index >= 0) {
        toasts.value.splice(index, 1);
        clearTimeout(timeout);
      }
    }
  };

  toasts.value.push(toast);

  if (!persistent && duration !== Infinity) {
    timeout = setTimeout(() => {
      toast.close();
    }, duration);
  }

  return toast;
}