<script setup lang="ts">
import { computed, ref } from 'vue';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';
import { add, set, sub } from 'date-fns';
import { date, time } from '/@utilities/intl';

type Booking = {
  guid: string;
  projectId: number;
  comment: string;
  resourceType: string;
  bookedUsername: string;
  fromDate: Date;
  toDate: Date;
};

const bookings = ref<Map<string, Booking>>(new Map());

function mapBooking(booking: any): Booking {
  return {
    guid: booking.Guid,
    projectId: booking.ProjectId,
    comment: booking.Comment,
    resourceType: booking.ResourceType,
    bookedUsername: booking.BookedUserFullName,
    fromDate: new Date(booking.FromDate),
    toDate: new Date(booking.ToDate),
  };
}

function setTime(date: Date, start = false) {
  return set(
    date,
    start ? { hours: 2, minutes: 0, seconds: 0 } : { hours: 23, minutes: 59, seconds: 59 },
  ).toISOString();
}

type LoadProjectBookingsOpt = { projectId: number; fromDate: Date; toDate: Date };
function loadProjectBookings({ projectId, fromDate, toDate }: LoadProjectBookingsOpt) {
  const queries = createQueryParams(
    //@ts-expect-error createqueryparams map bug
    new Map([
      ['projectIds', projectId],
      ['fromDate', setTime(fromDate, true)],
      ['toDate', setTime(toDate)],
    ]),
  );

  return api
    .get(`ressurs/bookings?${queries}`)
    .then(({ data }) => {
      bookings.value = new Map([
        ...bookings.value,
        ...data
          .map((b: any) => [b.Guid, mapBooking(b)])
          .sort((a: any, b: any) => b[1].fromDate - a[1].fromDate),
      ]);
    })
    .catch(genericApiErrorHandler);
}

const props = defineProps<{ projectId: number }>();

const projectBookings = computed(
  () =>
    new Map(
      [...bookings.value].filter(
        ([id, b]) => props.projectId === b.projectId && b.resourceType !== 'group',
      ),
    ),
);

const interval = { months: 6 };
const fromDate = ref(new Date());
const startDate = fromDate.value;
const toDate = ref(add(new Date(), interval));

function loadOlder() {
  fromDate.value = sub(fromDate.value, interval);
  toDate.value = sub(toDate.value, interval);
  loadBookings();
}
const loading = ref(0);

function loadBookings() {
  loading.value++;

  return loadProjectBookings({
    projectId: props.projectId,
    fromDate: fromDate.value,
    toDate: toDate.value,
  }).then(() => {
    loading.value--;
  });
}

loadBookings();
</script>

<template>
  <section class="project-booking">
    <h3>Avtaler på prosjekt</h3>

    <table class="card">
      <thead>
        <tr>
          <th>Navn</th>
          <th class="--right">Fra</th>
          <th class="--right">Til</th>
          <th class="--right">Tid</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="[guid, booking] in projectBookings" :key="guid">
          <td>{{ booking.bookedUsername }}</td>
          <td class="--right">{{ date(booking.fromDate, { short: true }) }}</td>
          <td class="--right">{{ date(booking.toDate, { short: true }) }}</td>
          <td class="--right">{{ [time(booking.fromDate), time(booking.toDate)].join(' - ') }}</td>
        </tr>

        <tr v-if="projectBookings.size === 0">
          <td colspan="4" style="text-align: center">Ingen avtaler på prosjekt</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="2">
            Viser fra
            {{ [date(fromDate, { short: true }), date(startDate, { short: true })].join(' - ') }}
          </td>
          <td class="--right" colspan="2">
            <df-button v-on:click="loadOlder()" :disabled="!!loading">
              Hent eldre
              <template v-if="!!loading" v-slot:icon><DfLoading no-float /></template>
            </df-button>
          </td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<style scoped>
.project-booking {
  display: grid;
  gap: var(--gap-md);
}

table {
  text-align: left;
  border-collapse: collapse;
  font-size: 0.9rem;

  & th {
    font-size: 0.8rem;
    font-weight: normal !important;
  }

  & th,
  & td {
    padding: var(--gap-sm) var(--gap-md);
  }
}

tr:not(tfoot tr) {
  border-bottom: 1px solid var(--color-divider);
}

.--right {
  text-align: right;
}
</style>
