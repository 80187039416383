<script setup lang="ts">
import { inject, ref } from 'vue';

const props = defineProps<{
  noIcon?: boolean;
  placeholder?: string;
}>();

const emit = defineEmits(['submit']);

const close = inject('dropdown-close') as () => void;
// const getMenu = inject('dropdown-getmenu');
// const setMenu = inject('dropdown-setmenu');
const value = ref('');

function onSubmit() {
  emit('submit', value.value);
}
</script>

<template>
  <form
    class="dropdown-item"
    :class="{ 'dropdown-item--no-icon': noIcon }"
    v-on:submit.prevent.stop="onSubmit()"
  >
    <label class="dropdown-item__label">
      <slot name="icon" v-if="!noIcon">
        <div class="icon-spacer"></div>
      </slot>

      <input
        class="dropdown-item__input"
        type="text"
        v-model="value"
        :placeholder="placeholder || 'Skriv her'"
      />
    </label>
  </form>
</template>

<style scoped>
.dropdown-item {
  display: grid;
  border: none;

  &:hover {
    background-color: var(--color-hover);
  }
}

.dropdown-item__label {
  display: grid;
  grid-template-columns: [icon] 2rem [content] 1fr;
  align-items: center;
  padding: var(--gap-md);
  gap: var(--gap-md);
}

.dropdown-item--no-icon .dropdown-item__label {
  grid-template-columns: [content] 1fr;
}

.dropdown-item__input {
  width: 100%;
  padding: 0;
  line-height: inherit;
  border: none;
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}
</style>
