export type KeyValue = {
  id: number;
  name: string;
};

export type CurrentUser = {
  id: number;
  name: string;
  tenant: CurrentTenant;
  hiddenModuleIds: number[];
  roles: KeyValue[];

  firstName: string;
  lastName: string;
  phoneNumber: string;

  employeeNumber: string;
  birthdate: string;
  hmsCardNumber: string;
  betaUser: boolean;
  showStatusNotificationPage: boolean;
  qualificationIds: number[];
  permissions: RolePermission[];
};

export type CurrentTenant = {
  id: number;
  moduleIds: Array<number>;
};

export type UserForm = {
  name: string;
  phoneNumber: string;
};

export enum RolePermission {
  RegistrationExport = '03118a8d-a2bf-4cf6-963c-c1daad2fed3f',
  RegistrationDelete = '6b05de99-3915-4357-82d0-1f4ca8ee73f5',
}
