<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useTimerStore } from '/@stores/timer';
import { dateToString } from '/@utilities/date';
import useWeek from '/@composables/useWeek';
import { useRegistrations } from '/@utilities/registrations';
import { isToday } from 'date-fns';
import EventItem from '/@elements/EventItem/EventItem.vue';

const formatWeekday = new Intl.DateTimeFormat('nb', { weekday: 'short' }).format;
const formatDay = new Intl.DateTimeFormat('nb', { day: 'numeric' }).format;
const formatMonth = new Intl.DateTimeFormat('nb', { month: 'long' }).format;

const route = useRoute();

const loading = ref(0);

const showDescriptionsModal = ref(false);

const { weekNo, year, setWeekNo, resetWeekNo } = useWeek();
watch(weekNo, () => load());

const timerStore = useTimerStore();
const { configureWeek } = useRegistrations();

const showWeekends = computed((): boolean => {
  const ls = localStorage.getItem('showWeekends');
  return ls ? JSON.parse(ls) : true;
});

const weekData = computed(() =>
  configureWeek(
    weekNo.value,
    year.value,
    timerStore.userRegistrations,
    timerStore.finishedWeek,
    showWeekends.value,
  ),
);

async function toggleWeekDone() {
  loading.value++;

  const finishedWeek = timerStore.finishedWeek;

  const endpoint = finishedWeek
    ? finishedWeek.days.length === 7
      ? timerStore.deleteFinishedWeek(finishedWeek.id)
      : timerStore.updateFinishedWeek(finishedWeek.id, [1, 2, 3, 4, 5, 6, 7])
    : timerStore.createFinishedWeek(weekNo.value, year.value, [1, 2, 3, 4, 5, 6, 7]);

  endpoint.then(() => {
    load();
    loading.value--;
  });
}

async function load() {
  loading.value++;

  return Promise.all([
    timerStore.loadRegistrations({
      fromDate: dateToString(weekData.value.fromDate),
      toDate: dateToString(weekData.value.toDate),
    }),
    timerStore.loadFinishedWeek(weekNo.value, year.value),
  ]).then(() => {
    loading.value--;
  });
}

load();
</script>

<template>
  <div class="page">
    <df-loading v-if="loading > 0" />

    <div class="week-view">
      <df-header>
        <template v-slot:default>Uke {{ weekNo }}</template>
        <template v-slot:subtitle>{{ weekData.month }}</template>

        <template v-slot:actions>
          <df-button-group
            :entries="[
              {
                icon: 'f053',
                click: () => setWeekNo(false),
              },
              {
                icon: 'f054',
                click: () => setWeekNo(true),
              },
            ]"
          />

          <df-button v-on:click="resetWeekNo()" elevate>
            <template v-slot:icon>
              <df-icon code="f783" />
            </template>
          </df-button>
        </template>
      </df-header>

      <section class="week-info">
        <df-button v-on:click="toggleWeekDone()" :disabled="loading !== 0">
          Ferdig

          <template v-slot:icon>
            <df-icon :code="weekData.weekFinished ? 'f14a' : 'f0c8'" />
          </template>
        </df-button>

        <div class="info-actions">
          <div class="badge badge--lg -day" title="Antall dager">{{ weekData.daysCount }}d</div>
          <div class="badge badge--lg -event" title="Antall timer">{{ weekData.hoursCount }}t</div>
          <div class="badge badge--lg -expense" title="Antall utgifter">
            {{ weekData.expensesCount }}
          </div>
          <df-button v-on:click="showDescriptionsModal = true">
            <template v-slot:icon> <df-icon code="f05a" /> </template>
          </df-button>
        </div>
      </section>

      <df-modal v-if="showDescriptionsModal">
        <div class="modal-content">
          <header class="head">
            <df-button class="head__back" v-on:click="() => (showDescriptionsModal = false)">
              <template v-slot:icon>
                <df-icon code="f060" />
              </template>
            </df-button>

            <df-header :close="true" v-on:click="showDescriptionsModal = false"
              >Beskrivelser</df-header
            >
          </header>

          <dl class="list">
            <div class="list-item">
              <dt class="title">Antall dager</dt>
              <df-icon code="f111" solid style="color: var(--color-day)" />
            </div>
            <div class="list-item">
              <dt class="title">Antall timer</dt>
              <df-icon code="f111" solid style="color: var(--color-event)" />
            </div>
            <div class="list-item">
              <dt class="title">Antall utgifter</dt>
              <df-icon code="f111" solid style="color: var(--color-expense)" />
            </div>
          </dl>
        </div>
      </df-modal>

      <div class="week-table">
        <div class="day" v-for="(day, index) in weekData.days" :key="index">
          <header class="day__header" :class="{ today: isToday(day.date) }">
            <div class="title-topic">{{ formatWeekday(day.date).slice(0, 2) }}</div>
            <div class="text-bold">{{ formatDay(day.date).replace('.', '') }}</div>
            <div class="text-small">
              <template v-if="day.daysCount">{{ day.daysCount }}d</template>
              {{ day.hoursCount }}t
              <template v-if="day.expensesCount">{{ day.expensesCount }}</template>
            </div>
          </header>

          <div
            class="day__event-wrapper"
            v-for="event in day.events"
            :style="{
              '--event-height': `${
                event.isExpense || event.isDay ? 6.5 : (event.countNo / 2) * 6.5
              }rem`,
            }"
            :key="event.id"
          >
            <EventItem :event-item="event" small />
          </div>

          <df-button :to="`/timer/new?date=${dateToString(day.date)}`" v-if="!day.dayFinished">
            <template v-slot:icon>
              <df-icon code="2b" />
            </template>
          </df-button>

          <div class="day-finished" v-else>Ferdig</div>
        </div>
      </div>
    </div>

    <df-modal v-if="route.matched.length > 1">
      <router-view></router-view>
    </df-modal>
  </div>
</template>

<style scoped>
.week-view {
  display: grid;
  gap: var(--gap-lg);
}

.week-info {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: var(--gap-md);
  align-items: center;
  justify-content: space-between;
}

.info-actions {
  display: flex;
  gap: var(--gap-md);
  align-items: center;
}

.week-table {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: var(--gap-sm);

  @media (min-width: 1000px) {
    gap: var(--gap-md);
  }
}

.day {
  display: flex;
  flex-direction: column;
}

.day__header {
  text-align: center;
  margin-bottom: var(--gap-md);
}

.day__event-wrapper {
  height: var(--event-height, auto);
  padding-bottom: var(--gap-sm);
  display: grid;
}

.today {
  color: var(--color-info_bg);
  background-color: var(--color-info_text);
  border-radius: var(--radius-sm);
}

.day-finished {
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);
  padding: var(--gap-sm);
  text-align: center;
  font-size: 0.8rem;
}

@media (max-width: 900px) {
  .event__text.text-small {
    font-size: 0.7rem;
  }
}

.modal-content {
  display: grid;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
}

.list {
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.list-item {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: var(--gap-md);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
}
</style>
