<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Modules, StatusGroups } from '/@types/ids';
import { newListOpt } from '/@utilities/opt';
import { cloneDeep, debounce } from 'lodash-es';
import { useAlert } from '/@elements/DfAlert/DfAlert.vue';
import { ValidationRule, validateForm } from '/@utilities/form';
import { Project } from '/@features/project/project.types';
import { useProjectStore } from '/@features/project';
import { useTenantStore } from '/@stores/tenant';
import { ScheduleResource } from '/@types/resources';

const tenantStore = useTenantStore();

const columns = [
  {
    id: 'statusGroupId',
    filterKey: 'statusGroupId',
  },
  {
    id: 'typeId',
    filterKey: 'typeId',
  },
  {
    id: 'caseworkerId',
    filterKey: 'caseworkerId',
  },
];

const props = defineProps<{
  selectedResource: ScheduleResource;
  saving: boolean;
}>();

const emit = defineEmits(['create', 'close']);

const projectStore = useProjectStore();

const eventForm = ref(cloneDeep(props.selectedResource));

const newBookingSearchOpt = ref(
  newListOpt({
    filters: [
      { columnId: 'statusGroupId', values: [StatusGroups.Aktive, StatusGroups.Prosjektering] },
    ],
    sortBy: 'id',
    limit: 10,
  }),
);
const newBookingSearchLoading = ref(false);
const newBookingSearchProjects = ref<Project[]>([]);

watch(
  newBookingSearchOpt.value,
  debounce(() => {
    newBookingSearchLoading.value = true;
    projectStore
      .loadProjectPage({
        moduleId: Modules.Ressurs,
        opt: newBookingSearchOpt.value,
        columns,
      })
      .then((data) => {
        newBookingSearchProjects.value = data.entries;
      })
      .finally(() => {
        newBookingSearchLoading.value = false;
      });
  }, 500),
  { deep: true },
);

watch(
  () => eventForm.value,
  (newVal) => {
    if (isNaN(newVal.fromDate.getTime())) {
      eventForm.value.fromDate = props.selectedResource.fromDate;
    }
    if (isNaN(newVal.toDate.getTime())) {
      eventForm.value.toDate = props.selectedResource.toDate;
    }
  },
  { deep: true },
);

const alert = useAlert();

const isGroup = computed(() => props.selectedResource.resourceType === 'group');

function submitEvent(event: ScheduleResource) {
  const validation: ValidationRule[] = [
    [!event.projectId, 'Prosjekt kreves'],
    [!event.fromDate || !event.toDate, 'Fra og til tid kreves'],
    [event.fromDate > event.toDate, 'Fra tid må være før til tid'],
    [isGroup.value ? false : selectedUsers.value.size === 0, 'Bruker kreves'],
  ];

  const { ok, message } = validateForm(validation);

  if (ok) {
    if (selectedUsers.value.size > 0) {
      event.additionalUsers = [...selectedUsers.value].map((id) => tenantStore.users.get(id)?.guid);
    }

    emit('create', event);
  } else {
    alert.write(String(message));
  }
}

const selectedUsers = ref<Set<number>>(new Set());

function toggleUser(id: number) {
  selectedUsers.value.has(id) ? selectedUsers.value.delete(id) : selectedUsers.value.add(id);
}

function initialize() {
  return Promise.all([
    tenantStore.loadUsers().then(() => {
      const userId = [...tenantStore.users.values()].find(
        (u) => u.guid === eventForm.value.resourceGuid,
      )?.id;
      if (!userId) return;
      selectedUsers.value = new Set([userId]);
    }),
    projectStore
      .loadProjectPage({
        moduleId: Modules.Ressurs,
        opt: newBookingSearchOpt.value,
        columns,
      })
      .then((data) => {
        newBookingSearchProjects.value = data.entries;
      }),
  ]).finally(() => {
    newBookingSearchLoading.value = false;
  });
}

initialize();
</script>

<template>
  <form class="dialog" v-on:submit.prevent="submitEvent(eventForm)">
    <header class="head">
      <df-button v-on:click="emit('close')">
        <template v-slot:icon>
          <df-icon code="f060" />
        </template>
      </df-button>

      <h1 class="title-md">Ny avtale</h1>
    </header>

    <df-alert v-model="alert" />

    <div class="dl card" style="padding: 0">
      <label class="dl__group dl__group--large dl__group--actions ghost-label">
        <h2 class="dl__topic">Prosjekt</h2>

        <df-select3
          v-model="eventForm.projectId"
          v-model:search="newBookingSearchOpt.search"
          remote-search
          :remote-loading="newBookingSearchLoading"
          :entries="new Map(newBookingSearchProjects.map((p) => [p.id, p]))"
          label="Prosjekter"
          display="name"
        >
          <template v-slot:trigger="{ toggle, value }">
            <button class="ghost-input" type="button" v-on:click="toggle()">
              {{ value }}
            </button>
          </template>
        </df-select3>

        <div class="dl__actions">
          <df-icon code="f107" style="align-self: center" />
        </div>
      </label>

      <df-select class="dl__group dl__group--large" label="Brukere" v-if="!isGroup">
        <template v-slot:text>
          {{
            [...selectedUsers]
              .map((id) => tenantStore.users.get(id)?.name || 'ukjent bruker')
              .join(', ')
          }}
        </template>

        <df-dropdown2-item
          v-for="[id, user] in tenantStore.users"
          :key="id"
          v-on:click="toggleUser(id)"
          no-close
        >
          <div class="listitem">
            <df-icon :code="selectedUsers.has(id) ? 'f14a' : 'f0c8'" />
            <div>{{ user.name }}</div>
          </div>
        </df-dropdown2-item>
      </df-select>

      <label class="dl__group ghost-label">
        <h2 class="dl__topic">Fra</h2>
        <input
          class="dl__description ghost-input"
          type="datetime-local"
          v-on:input="
            eventForm.fromDate = new Date(
              $event.target.valueAsNumber + eventForm.fromDate.getTimezoneOffset() * 1000 * 60,
            )
          "
          :valueAsNumber="
            eventForm.fromDate.getTime() - eventForm.fromDate.getTimezoneOffset() * 1000 * 60
          "
        />
      </label>

      <label class="dl__group ghost-label">
        <h2 class="dl__topic">Til</h2>
        <input
          class="dl__description ghost-input"
          type="datetime-local"
          v-on:input="
            eventForm.toDate = new Date(
              $event.target.valueAsNumber + eventForm.toDate.getTimezoneOffset() * 1000 * 60,
            )
          "
          :valueAsNumber="
            eventForm.toDate.getTime() - eventForm.toDate.getTimezoneOffset() * 1000 * 60
          "
        />
      </label>

      <label class="dl__group dl__group--large ghost-label">
        <h2 class="dl__topic">Avtalekommentar</h2>
        <textarea
          class="dl__description ghost-input"
          v-model="eventForm.comment"
          placeholder="Ingen kommentar"
        ></textarea>
      </label>
    </div>

    <df-button type="submit" primary elevate :disabled="saving">
      Opprett
      <template v-slot:icon v-if="saving">
        <df-loading no-float />
      </template>
    </df-button>
  </form>
</template>

<style scoped>
.ghost-label:focus-within {
  box-shadow: inset 0 0 0 1px black;
}

.ghost-label .ghost-input {
  outline: none;
}

.ghost-input {
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  resize: none;
}

input[type='datetime-local'] {
  @media (max-width: 1000px) {
    font-size: 0.85rem;
  }
}

.listitem {
  display: grid;
  gap: var(--gap-md);
  grid-template-columns: max-content 1fr;
  align-items: center;
}
</style>
