<script setup lang="ts">
import { debounce as esDebounce } from 'lodash-es';
import { computed, inject, onMounted, Ref, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string;

    placeholder?: string;

    disabled?: boolean;

    elevate?: boolean;
    // Adds dropdown-item style when true
    dropdownItem?: boolean;

    inMenu?: string;

    autofocus?: boolean;

    debounce?: boolean;
  }>(),
  {
    placeholder: 'Søk',
    debounce: true,
  },
);

const emit = defineEmits(['update:modelValue', 'input', 'clear']);

const value = ref(props.modelValue);

const debouncedEmit = esDebounce(emitter, 700);

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue;
  },
);

const placeholderCheck = computed(() => {
  return !value.value && !props.disabled;
});

const menu = inject('menu') as Ref<string>;

const isVisible = computed(() => {
  if (!props.dropdownItem) return true;

  return props.inMenu ? menu.value === props.inMenu : menu.value === '';
});

function input() {
  emitters(value.value);
}

function clear() {
  // Skip debounce on clear
  emitter('');
  emit('clear');
}

const inputRef = ref<HTMLInputElement | null>(null);

function focus() {
  inputRef.value?.focus();
}

function emitter(value: string) {
  emit('update:modelValue', value);
  emit('input', value);
}

function emitters(value: string) {
  props.debounce ? debouncedEmit(value) : emit(value);
}

onMounted(() => {
  if (props.autofocus) {
    focus();
  }
});
</script>

<template>
  <div :class="{ 'dropdown-style': dropdownItem }" v-if="isVisible" role="menuitem" ref="item">
    <label
      class="input"
      :class="{
        'input--elevate': elevate,
      }"
    >
      <div class="input__icon">
        <df-icon code="f002" />
      </div>

      <input
        class="input__input"
        v-model="value"
        v-on:input="input()"
        ref="inputRef"
        type="text"
        inputmode="search"
        :autofocus="autofocus"
      />

      <div v-if="placeholderCheck" class="input__placeholder">
        {{ placeholder }}
      </div>

      <button class="input__clear" v-if="value" v-on:click="clear()" title="Tøm felt" type="button">
        <df-icon code="f00d" />
      </button>
    </label>
  </div>
</template>

<style scoped>
.dropdown-style {
  padding: var(--gap-sm);
  border-bottom: 1px solid var(--color-divider);
}

.input {
  margin-bottom: 0; /* Reset input */
  border: 1px solid var(--color-border);

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: 'icon input action';
  align-items: center;
  gap: var(--gap-md);
  border-radius: var(--radius-sm);
  height: 100%;
}

.input--elevate {
  border-color: transparent;
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
}

.input__icon {
  grid-area: icon;
  color: inherit;

  padding: calc(var(--button-padding-y) - 1px) 0;
  padding-left: calc(var(--button-padding-y) - 1px);
}

.input:focus-within {
  border-color: var(--color-border);
}

/* Reset default input */
.input__input {
  background-color: transparent;
  color: inherit;
  font: inherit;
  border: none;
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  padding: 0;
  overflow: hidden;
  contain: strict;
}

.input__input {
  grid-area: input;
  padding: calc(var(--button-padding-y) - 1px) 0;
}

.input__placeholder {
  grid-area: input;
  pointer-events: none;
  -webkit-user-select: none;
  color: var(--color-input-placeholder);

  padding: calc(var(--button-padding-y) - 1px) 0;
}

.input__clear {
  grid-area: action;
  display: grid;
}

.input__clear::before {
  background-color: var(--color-hover);
  content: '';
  width: 1px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: calc(var(--button-padding-y) - 1px) 0;
}

/* Reset default button */
.input__clear {
  background-color: transparent;
  padding: 0;
  border: 1px solid transparent;
  display: block;
  outline: none;
}

.input__clear {
  position: relative;
  display: grid;
  color: inherit;
  border-radius: var(--radius-sm);

  padding: calc(var(--button-padding-y) - 1px);

  &:hover {
    background-color: var(--color-hover);
    border-color: var(--color-hover);
    &::before {
      display: none;
    }
  }

  &:focus {
    border-color: var(--color-link);
    &::before {
      display: none;
    }
  }
}
</style>
