<script setup lang="ts">
import { ref, watch } from 'vue';

const extract = (value, type) => {
  switch (type) {
    case 'numeric': {
      if (!/^[0-9]*$/.test(value)) {
        const match = value.match(/[0-9]+/g);
        if (Array.isArray(match)) {
          return match.join('');
        } else {
          return '';
        }
      } else {
        return value;
      }
    }

    default:
      return value;
  }
};

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    label?: string;
    elevate?: boolean;
    disabled?: boolean;
  }>(),
  { modelValue: '' },
);

const emit = defineEmits(['update:modelValue', 'update']);

const textValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (val) => {
    textValue.value = extract(val, null);
  },
);

function input({ target }) {
  const { value } = target;

  textValue.value = value;
  emit('update:modelValue', value);
  emit('update', value);
}
</script>

<template>
  <div>
    <label
      class="input"
      :class="{
        'input--elevate': elevate,
        'input--disabled': disabled,
      }"
    >
      <div v-if="label" class="input__label">{{ label }}</div>

      <input
        type="time"
        v-model="textValue"
        v-on:input="input"
        :disabled="disabled"
        class="input__input"
      />
    </label>
  </div>
</template>

<style scoped>
.input {
  border: 1px solid var(--color-border);
  display: grid;
  width: 100%;
  color: var(--color-text);
  border-radius: var(--radius-sm);
  margin-bottom: 0; /* RESET BOOTSTRAP LABEL */
  padding: calc(var(--gap-sm) - 1px);
  padding-left: calc(var(--gap-md) - 1px);
  grid-template-areas: 'label' 'input';
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-auto-columns: max-content;
  grid-column-gap: var(--gap-md);
  cursor: text;
}

.input--disabled {
  background-color: var(--color-darken);
}

.input--disabled .input__input {
  color: hsla(0, 0%, 0%, 0.5);
}

.input--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

.input:focus-within {
  border-color: #5c6f84;
}

.input__label {
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
}

.input__input {
  grid-area: input;
  background-color: transparent;
  color: inherit;
  font: inherit;
  border: none;
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  padding: 0;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
</style>
