<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useTimerStore } from '/@stores/timer';
import { dateToString } from '/@utilities/date';
import { addDays, subDays } from 'date-fns';
import { getISOWeek, getYear } from 'date-fns';
import { useRegistrations } from '/@utilities/registrations';
import { date } from '/@utilities/intl';
import EventItem from '/@elements/EventItem/EventItem.vue';

const route = useRoute();

const loading = ref(0);

const selectedDay = ref(new Date());
watch(selectedDay, () => load());

const showDescriptionsModal = ref(false);

const timerStore = useTimerStore();
const { configureDay } = useRegistrations();

const dayData = computed(() =>
  configureDay(selectedDay.value, timerStore.userRegistrations, timerStore.finishedWeek),
);

function toggleDayDone() {
  loading.value++;
  const daySet: Set<number> = new Set(timerStore.finishedWeek?.days || []);

  dayData.value.dayFinished ? daySet.delete(dayData.value.dayNo) : daySet.add(dayData.value.dayNo);

  const endpoint = timerStore.finishedWeek
    ? timerStore.updateFinishedWeek(timerStore.finishedWeek.id, [...daySet])
    : timerStore.createFinishedWeek(dayData.value.weekNo, dayData.value.year, [...daySet]);

  endpoint
    .then(() => {
      load();
    })
    .finally(() => {
      loading.value--;
    });
}

function changeDay({ next = false, reset = false }) {
  const day = selectedDay.value;

  selectedDay.value = next ? addDays(day, 1) : reset ? new Date() : subDays(day, 1);
}

async function load() {
  loading.value++;

  await Promise.all([
    timerStore.loadRegistrations({
      fromDate: dateToString(selectedDay.value),
      toDate: dateToString(selectedDay.value),
    }),
    timerStore.loadFinishedWeek(getISOWeek(selectedDay.value), getYear(selectedDay.value)),
  ]);

  loading.value--;
}

load();
</script>

<template>
  <div class="page">
    <df-loading v-if="loading > 0" />

    <div class="day-view">
      <df-header>
        <template v-slot:default>{{ date(selectedDay) }}</template>

        <template v-slot:actions>
          <df-button-group
            :entries="[
              {
                icon: 'f053',
                click: () => changeDay({}),
              },
              {
                icon: 'f054',
                click: () => changeDay({ next: true }),
              },
            ]"
          />

          <df-button v-on:click="changeDay({ reset: true })" elevate>
            <template v-slot:icon>
              <df-icon code="f783" />
            </template>
          </df-button>
        </template>
      </df-header>

      <div class="day-info">
        <df-button v-on:click="toggleDayDone()" :disabled="loading !== 0">
          Ferdig

          <template v-slot:icon>
            <df-icon :code="dayData.dayFinished ? 'f14a' : 'f0c8'" />
          </template>
        </df-button>

        <div class="info-actions">
          <div class="badge badge--lg -day" title="Antall dager">{{ dayData.daysCount }}d</div>
          <div class="badge badge--lg -event" title="Antall timer">{{ dayData.hoursCount }}t</div>
          <div class="badge badge--lg -expense" title="Antall utgifter">
            {{ dayData.expensesCount }}
          </div>
          <div class="badge badge--lg -remaining" title="Gjenværende timer">
            {{ dayData.remainingCount }}t
          </div>
          <df-button v-on:click="showDescriptionsModal = true" class="info-button">
            <template v-slot:icon> <df-icon code="f05a" /> </template>
          </df-button>
        </div>
      </div>

      <df-modal v-if="showDescriptionsModal">
        <div class="modal-content">
          <header class="head">
            <df-button class="head__back" v-on:click="() => (showDescriptionsModal = false)">
              <template v-slot:icon>
                <df-icon code="f060" />
              </template>
            </df-button>

            <df-header :close="true" v-on:click="showDescriptionsModal = false"
              >Beskrivelser</df-header
            >
          </header>

          <dl class="list">
            <div class="list-item">
              <dt class="title">Antall dager</dt>
              <df-icon code="f111" solid style="color: var(--color-day)" />
            </div>
            <div class="list-item">
              <dt class="title">Antall timer</dt>
              <df-icon code="f111" solid style="color: var(--color-event)" />
            </div>
            <div class="list-item">
              <dt class="title">Antall utgifter</dt>
              <df-icon code="f111" solid style="color: var(--color-expense)" />
            </div>
            <div class="list-item">
              <dt class="title">Gjenværende timer</dt>
              <df-icon code="f111" solid style="color: var(--color-remaining)" />
            </div>
          </dl>
        </div>
      </df-modal>

      <div class="events">
        <EventItem v-for="(event, index) in dayData.events" :key="index" :event-item="event" />

        <div class="placeholder" v-if="dayData.events.length === 0">Ingen timer ført på dag.</div>
      </div>

      <div class="fab" style="justify-self: end">
        <df-fab :to="`/timer/new?date=${dateToString(selectedDay)}`" v-if="!dayData.dayFinished">
          Ny registrering
          <template v-slot:icon>
            <df-icon code="2b" />
          </template>
        </df-fab>
      </div>
    </div>

    <df-modal v-if="route.matched.length > 1">
      <router-view></router-view>
    </df-modal>
  </div>
</template>

<style scoped>
.day-info {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: var(--gap-md);
  align-items: center;
  justify-content: space-between;
}

.day-view {
  display: grid;
  gap: var(--gap-lg);
}

.events {
  display: grid;
  gap: var(--gap-md);
}

.info-actions {
  display: flex;
  gap: var(--gap-md);
  align-items: center;
}

.info-button {
  @media (max-width: 380px) {
    display: none !important;
  }
}

.modal-content {
  display: grid;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
}

.list {
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.list-item {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: var(--gap-md);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
}
</style>
