import {
  ListOpt,
  Opt,
  OptType,
  MapOpt,
  OptT,
  OptFilter,
  ColumnId,
  OptFilter2,
  FilterType,
} from '/@types/opt';

export function newListOpt(opt?: Partial<ListOpt>): ListOpt {
  return {
    type: OptType.List,
    tenantId: opt?.tenantId ?? null,
    sortDesc: opt?.sortDesc ?? false,
    sortBy: opt?.sortBy ?? 'id',
    offset: opt?.offset ?? 0,
    limit: opt?.limit ?? 100,
    search: opt?.search ?? '',
    filters: opt?.filters ?? [],
    filters2: opt?.filters2 ?? [],
    columns: opt?.columns ?? [],
    marked: opt?.marked ?? [],
    version: 1,
  };
}

export function newMapOpt(opt?: Partial<MapOpt>): MapOpt {
  return {
    type: OptType.Map,
    tenantId: opt?.tenantId ?? null,
    coordinates: opt?.coordinates ?? [12, 60],
    zoom: opt?.zoom ?? 0,
    search: opt?.search ?? '',
    filters: opt?.filters ?? [],
    filters2: opt?.filters2 ?? [],
    columns: opt?.columns ?? [],
    marked: opt?.marked ?? [],
    version: 1,
  };
}

export function optFromLocalStorage<T extends OptType>(
  type: T,
  key: string,
  defaults?: Partial<OptT<T>>,
): OptT<T> {
  const currentVersion = 1;
  const ls = JSON.parse(localStorage.getItem(key));
  const data = {
    ...defaults,
    ...(ls?.version === currentVersion ? ls : {}),
  };

  // remove instances of filters not using multi-select system
  // data.filters.forEach((i, index) => {
  //   if (['statusGroupId', 'caseworkerId'].includes(i.columnId)) {
  //     if (!Array.isArray(i.values)) {
  //       data.filters.splice(index, 1)
  //     }
  //   }
  // });

  switch (type) {
    case OptType.List:
      return newListOpt(data) as OptT<T>;

    case OptType.Map:
      return newMapOpt(data) as OptT<T>;

    default:
    // throw new UnreachableError(type);
  }
}

export function optToLocalStorage(key: string, opt: Opt): void {
  const oldOpt = JSON.parse(localStorage.getItem(key));
  localStorage.setItem(
    key,
    JSON.stringify({
      ...oldOpt,
      ...opt,
    }),
  );
}

export function getFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
): Array<number | string> {
  return filters.find(({ columnId: id }) => id === columnId)?.values ?? [];
}

export function hasFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  value: number | string,
): boolean {
  return filters.find(({ columnId: id }) => id === columnId)?.values.includes(value) ?? false;
}

export function addFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues = values.filter((x) =>
    x != null && typeof x === 'string' ? x.length > 0 : true,
  );

  return newValues.length > 0
    ? [
        ...otherFilters,
        {
          columnId,
          values:
            filter != null
              ? [...newValues, ...filter.values.filter((value) => !values.includes(value))]
              : newValues,
        },
      ]
    : otherFilters;
}

export function replaceFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
): Array<OptFilter> {
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues = values.filter((x) =>
    x != null && typeof x === 'string' ? x.length > 0 : true,
  );

  return newValues.length > 0 ? [...otherFilters, { columnId, values: newValues }] : otherFilters;
}

export function toggleFilterValue(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  value: number | string,
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const hasValue = filter?.values.includes(value) ?? false;

  return hasValue
    ? removeFilterValues(filters, columnId, [value])
    : addFilterValues(filters, columnId, [value]);
}

export function removeFilterValues(
  filters: Array<OptFilter>,
  columnId: ColumnId,
  values: Array<number | string>,
): Array<OptFilter> {
  const filter = filters.find(({ columnId: id }) => id === columnId);
  const otherFilters = filters.filter(({ columnId: id }) => id !== columnId);
  const newValues = filter?.values.filter((value) => !values.includes(value)) ?? [];

  return newValues.length > 0 ? [...otherFilters, { columnId, values: newValues }] : otherFilters;
}

export function clearFilter(filters: Array<OptFilter>, columnId: ColumnId): Array<OptFilter> {
  return filters.filter(({ columnId: id }) => id !== columnId);
}

export const FilterTools2 = {
  get: function (filters: OptFilter2[], columnId: number, type: FilterType) {
    const f = filters.find((f) => f.columnId === columnId && f.type === type)?.value;
    return f ? new Date(f) : null;
  },

  set: function (filters: OptFilter2[], columnId: number, type: FilterType, value: any) {
    const otherFilters = filters.filter((f) => {
      if (f.columnId !== columnId) return true;
      if (f.type !== type) return true;
      return false;
    });

    const obj = {
      columnId,
      type,
      value,
    };

    return [...otherFilters, obj];
  },

  clear: function (filters: OptFilter2[], columnId: number, type: FilterType) {
    return filters.filter((f) => {
      if (f.columnId !== columnId) return true;
      if (f.type !== type) return true;
      return false;
    });
  },
};
