<script setup lang="ts">
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: number | null;

    label?: string;
    step?: number;
    minValue?: number | null;
    enableDecimals?: boolean;
    disabled?: boolean;
    hideActions?: boolean;
    elevate?: boolean;
  }>(),
  {
    step: 1,
    minValue: null,
    enableDecimals: true,
  },
);

const emit = defineEmits(['update:modelValue']);

const iValue = ref<any>(props.modelValue);

watch(
  () => props.modelValue,
  () => {
    iValue.value = props.modelValue;
  },
);

function change(increase?: boolean) {
  if (props.disabled) return;
  if (props.minValue !== null && !increase && iValue.value - props.step < props.minValue) return;

  iValue.value = increase ? iValue.value + props.step : iValue.value - props.step;
  emitIValue();
}

function update({ target }: any) {
  const { value } = target;
  iValue.value = validate(value);
  target.value = iValue.value.replace('.', ',');
}

function validate(value: any) {
  let v = value;

  // neg numbers && , and . && no multiple . ,
  const regex = props.enableDecimals ? /^-?([\d])*[.,]?\d{0,2}/g : /\d/g;
  const match = v.match(regex);

  v = match.join('');
  // remove first 0
  v = v.replace(/^(0+)[\d]/g, '');
  // convert , to .
  v = v.replace(',', '.');
  // add 0
  v = /[\d]\./g.test(v) ? v : v.replace('.', '0.');

  return v;
}

function emitIValue() {
  // round to nearest half + ensure value
  iValue.value = Math.round(Number(iValue.value) / 0.5) * 0.5;
  iValue.value = isNaN(iValue.value) ? null : iValue.value;
  emit('update:modelValue', iValue.value);
}
</script>

<template>
  <div class="counter" :class="{ 'counter--disabled': disabled, 'counter--elevate': elevate }">
    <label>
      <div class="label" v-if="label">{{ label }}</div>

      <input
        :value="iValue !== null ? String(iValue).replace('.', ',') : null"
        inputmode="numeric"
        type="text"
        v-on:input="update"
        :disabled="disabled"
        v-on:change="emitIValue()"
      />
    </label>

    <template v-if="!hideActions">
      <button
        v-on:click="change()"
        class="decrease"
        :class="{ 'button--disabled': disabled }"
        :disabled="disabled"
        type="button"
      >
        <df-icon code="f068" />
      </button>

      <button
        v-on:click="change(true)"
        :class="{ 'button--disabled': disabled }"
        type="button"
        :disabled="disabled"
      >
        <df-icon code="2b" />
      </button>
    </template>
  </div>
</template>

<style scoped>
.counter {
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);
  padding: var(--gap-sm) var(--gap-md);

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);
  width: 100%;
}

.counter:focus-within {
  border-color: var(--color-focus);
}

.counter--disabled {
  opacity: 0.5;
}

.counter--elevate {
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

label {
  cursor: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

input {
  border: none;
  background: none;
  color: inherit;
  width: 100%;
}

input:focus {
  outline: none;
}

.label {
  font-size: 0.8rem;
  font-weight: 600;
}

button {
  border: none;
  background: none;
  margin: calc(var(--gap-sm) * -1) calc(var(--gap-md) * -1);
  padding: var(--gap-md);
  border-left: 1px solid var(--color-border);
  min-width: 3rem;
}

.decrease {
  margin-right: 0;
}

button:hover:not(.button--disabled) {
  background-color: var(--color-hover);
}
</style>
