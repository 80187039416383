import { ref } from 'vue';
import { defineStore } from 'pinia';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';

function mapNotes(notes: Array<any>, registrationId: number) {
  return new Map(
    (notes || [])
      .sort((a, b) => +new Date(b.CreatedAt) - +new Date(a.CreatedAt))
      .map((note: any) => [
        note.Id,
        {
          content: note.Content,
          text: note.Text,
          id: note.Id,
          registrationId,
          createdAt: new Date(note.CreatedAt),
          createdByUser: note.CreatedByUserFullName,
          createdByUserId: note.CreatedByUserId,
          modifiedAt: new Date(note.ModifiedAt),
          modifiedByUser: note.ModifiedByUserFullName,
          modifiedByUserId: note.ModifiedByUserId,
          resolvedAt: note.ResolvedAt ? new Date(note.ResolvedAt) : null,
          resolvedByUser: note.ResolvedByUserFullName,
          resolvedByUserId: note.ResolvedByUserId,
        },
      ]),
  );
}

export const useNotes = defineStore('notes', () => {
  const notes = ref(new Map());

  function loadRegistrationNotes(registrationId: number) {
    return api
      .get(`timer/registrations/${registrationId}/notes`)
      .then(({ data }) => {
        notes.value = mapNotes(data, registrationId);
      })
      .catch(genericApiErrorHandler);
  }

  function createRegistrationNote(
    registrationId: number,
    text: string,
    isTask: boolean,
    sendEmail: boolean,
  ) {
    const queries = createQueryParams(
      new Map([
        ['task', isTask],
        ['emailRegistrationOwner', sendEmail],
      ]),
    );

    return api
      .post(`timer/registrations/${registrationId}/notes?${queries}`, { text })
      .catch(genericApiErrorHandler);
  }

  function resolveRegistrationNote(registrationId: number, noteId: number) {
    return api
      .patch(`timer/registrations/${registrationId}/notes/${noteId}/resolve`)
      .then(() => {
        loadRegistrationNotes(registrationId);
      })
      .catch(genericApiErrorHandler);
  }

  return {
    notes,
    loadRegistrationNotes,
    createRegistrationNote,
    resolveRegistrationNote,
  };
});
