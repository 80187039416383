import { App } from 'vue';

// Components
import DfDropdown from '/@elements/DfDropdown/DfDropdown.vue';
import DfIcon from '/@elements/DfIcon/DfIcon.vue';
import DfButton from '/@elements/DfButton/DfButton.vue';
import DfFab from '/@elements/DfFab/DfFab.vue';
import DfSelect from '/@elements/DfSelect/DfSelect.vue';
import DfSelect3 from '/@elements/DfSelect3/DfSelect3.vue';
import DfDropdown2Item from '/@elements/DfDropdown2Item/DfDropdown2Item.vue';
import DfDropdownItem from '/@elements/DfDropdownItem/DfDropdownItem.vue';
import DfDropdownItemBlock from '/@elements/DfDropdownItemBlock/DfDropdownItemBlock.vue';
import DfDropdownItemInput from '/@elements/DfDropdownItemInput/DfDropdownItemInput.vue';
import DfDropdownItemConfirm from '/@elements/DfDropdownItemConfirm/DfDropdownItemConfirm.vue';
import DfInput from '/@elements/DfInput/DfInput.vue';
import DfDatepicker from '/@elements/DfDatepicker/DfDatepicker.vue';
import DfCounter from '/@elements/DfCounter/DfCounter.vue';
import DfModal from '/@elements/DfModal/DfModal.vue';
import DfTime from '/@elements/DfTime/DfTime.vue';
import DfHeader from '/@elements/DfHeader/DfHeader.vue';
import DfInputSearch from '/@elements/DfInputSearch/DfInputSearch.vue';

import DfLoading from '/@elements/DfLoading/DfLoading.vue';
import DfDeleteButton from '/@elements/DfDeleteButton/DfDeleteButton.vue';
import DfCheckbox from '/@elements/DfCheckbox/DfCheckbox.vue';
import DfAlert from '/@elements/DfAlert/DfAlert.vue';
import DfForm from '/@elements/DfForm/DfForm.vue';
import DfButtonGroup from '/@elements/DfButtonGroup/DfButtonGroup.vue';
import DfConfirmModal from '/@elements/DfConfirmModal/DfConfirmModal.vue';
import DfProgress from '/@elements/DfProgress/DfProgress.vue';

export function installComponents(app: App<Element>) {
  app
    .component('DfIcon', DfIcon)
    .component('DfDropdown', DfDropdown)
    .component('DfDropdownItem', DfDropdownItem)
    .component('DfDropdownItemBlock', DfDropdownItemBlock)
    .component('DfDropdownItemInput', DfDropdownItemInput)
    .component('DfDropdownItemConfirm', DfDropdownItemConfirm)
    .component('DfDropdown2Item', DfDropdown2Item)
    .component('DfButton', DfButton)
    .component('DfFab', DfFab)
    .component('DfDeleteButton', DfDeleteButton)
    .component('DfModal', DfModal)
    .component('DfLoading', DfLoading)
    .component('DfAlert', DfAlert)
    .component('DfForm', DfForm)
    .component('DfHeader', DfHeader)
    .component('DfConfirmModal', DfConfirmModal)
    // inputs
    .component('DfSelect', DfSelect)
    .component('DfSelect3', DfSelect3)
    .component('DfInput', DfInput)
    .component('DfDatepicker', DfDatepicker)
    .component('DfCounter', DfCounter)
    .component('DfTime', DfTime)
    .component('DfCheckbox', DfCheckbox)
    .component('DfButtonGroup', DfButtonGroup)
    .component('DfInputSearch', DfInputSearch)
    .component('DfProgress', DfProgress);
}
