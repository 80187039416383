<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue';

defineProps({
  size: {
    type: String,
    default: 'md',
  },
});

const overlayKey = performance.now(); // vue key to preserve the scoped css key

onMounted(() => {
  const html = document.querySelector('html');

  if (html == null) return;

  html.style.paddingRight = `${window.innerWidth - html.scrollWidth}px`;
  html.style.overflow = 'hidden';
});

onBeforeUnmount(() => {
  const html = document.querySelector('html');

  if (html == null) return;

  html.style.removeProperty('overflow');
  html.style.removeProperty('padding-right');
});
</script>

<template>
  <teleport to="#overlay">
    <div class="df-modal" :class="`df-modal--${size}`" :key="overlayKey">
      <div class="df-modal__content">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
/* ************************************* *
* PLEASE NOTE THESE STYLES MUST BE      *
* LOOKED AT TOGETHER WITH APP.CSS MODAL *
* ************************************* */

@keyframes slide-in {
  from {
    transform: translateY(-2rem);
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }
}

.df-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: start;
  justify-content: center;
  gap: var(--gap-md);
  padding: var(--gap-md);
  padding-top: calc(var(--gap-md) + env(safe-area-inset-top));
  background-color: var(--color-backdrop);
  overflow-y: auto;
  overflow-x: hidden;
  animation: fade-in 0.3s;
}

.df-modal__content {
  position: relative;
  background-color: var(--color-bg);
  animation: slide-in 0.3s;
  grid-column: 1;
  grid-row: 1;
  box-shadow: var(--shadow-md);

  &:nth-child(n + 2) {
    position: sticky;
    top: 0;
  }
}

.df-modal--xl {
  grid-template-columns: minmax(0, 120rem);
}

.df-modal--lg {
  grid-template-columns: minmax(0, 60rem);
}

.df-modal--md {
  grid-template-columns: minmax(0, 30rem);
}

.df-modal--sm {
  grid-template-columns: minmax(0, 20rem);
}
</style>
