import { Opt } from '/@types/opt';

export type Project = {
  id: number;
  name: string;
  address: string;
  typeId: number;
  statusId: number;
  statusName: string;
  description: string;
  caseworkerId: number;
  caseworkerName: string;
  installer: string;
  ref1: string;
  ref2: string;
  colorId: string;
  colorHex: string;
  createdAt: Date;
  updatedAt: Date;
  folder: string | null;
  department: string | null;
  contactCompanyName: string;
  deadlineAt: Date | null;
  properties?: Map<number, { dataType: string; value: string }>;
  registrationsLocked?: boolean;
};

export enum ProjectColumnKeys {
  Caseworker = 'caseworkerId',
  Department = 'departmentId',
  StatusId = 'statusId',
  StatusGroup = 'statusGroupId',
  TypeId = 'typeId',
  Ref2 = 'referenceNo2',
}

export interface ProjectColumn {
  // dynamic props are id numbered
  id: ProjectColumnKeys | number;
  label: string;
  // filterlist takes arg from _resolvedDependencies
  // are both used by FilterItems cmp to create valuelist with count
  filterList?: (items: any) => FilterlistItem[];
  filterListCount?: (
    opt: Opt,
    columns: ProjectColumn[],
    col: ProjectColumn,
  ) => Promise<Array<{ Value: string | number; ValueName: string; ValueCount: number }>>;
  filterKey?: string | number;
  // used with initDependencies
  dependencies?: Array<() => Promise<any>>;
  // populated with initDependencies
  _resolvedDependencies?: any;
}

export interface FilterlistItem {
  id: number;
  label: string;
  value: any;
}

export interface ProjectType {
  id: number;
  name: string;
  properties: Array<{
    id: number;
    name: string;
    showAlways: boolean;
    valuelistGuid: string | null;
  }>;
}

export interface Valuelist {
  guid: string;
  valuelistItems: Map<number, ValuelistItem>;
}

export interface ValuelistItem {
  id: number;
  name: string;
  sortIndex: number | null;
}

export interface CreateProjectOpts {
  name: string;
  typeId: number;
  description: string;
}

export interface LoadProjectPageOpts {
  moduleId: number;
  opt: Opt;
  columns: Array<ProjectColumn>;
  incPropIds?: Array<number>;
  incLabelIds?: Array<number>;
  ids?: Array<number>;
}

export type ProjectProperty = {
  id: number;
  name: string;
  sortIndex: number;
  value: string;
  dataType: string;
};
