import { ref } from 'vue';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';

type OutlookEvent = {
  guid: string;
  resourceGuid: string;
  resourceType: string;
  projectName: string;
  fromDate: Date;
  toDate: Date;
  editable: boolean;
  clickable: boolean;
  source: 'outlook';
};

function mapEvent(e: any): OutlookEvent {
  return {
    guid: e.Guid,
    resourceGuid: e.ResourceGuid,
    resourceType: e.ResourceType,
    projectName: `${e.Title} (Outlook)`,
    fromDate: new Date(e.FromDate),
    toDate: correctFullDay(e.ToDate),
    editable: false,
    clickable: false,
    source: 'outlook',
  };
}

function correctFullDay(date: string) {
  const d = new Date(date);
  if (!date.includes('00:00:00')) return d;
  d.setDate(d.getDate() - 1);
  d.setHours(23, 59, 59, 59);
  return d;
}

export function useOutlook() {
  const events = ref<Map<string, OutlookEvent>>(new Map());

  function loadEvents(filterId: number, from: Date, to: Date) {
    const queries = createQueryParams(
      new Map([
        ['filterId', String(filterId)],
        ['fromDate', from.toISOString()],
        ['toDate', to.toISOString()],
      ]),
    );

    return api
      .get(`ressurs/bookings/externaloutlookevents?${queries}`)
      .then(({ data }) => {
        data.forEach((e: any) => {
          events.value.set(e.Guid, mapEvent(e));
        });
      })
      .catch(genericApiErrorHandler);
  }

  return { events, loadEvents };
}
