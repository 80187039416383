<script setup lang="ts"></script>

<template>
  <div class="test-page"></div>
</template>

<style scoped>
.test-page {
  display: grid;
  gap: var(--gap-md);
  padding: var(--gap-md);
}
</style>
