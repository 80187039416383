<script setup lang="ts">
import { computed, ref } from 'vue';
import { date } from '/@utilities/intl';
import { useProjectStore, ProjectEdit, ProjectBookings } from '/@features/project';
import { ProjectProperty } from '/@features/project/project.types';
import { useTenantStore } from '/@stores/tenant';
import { projectsLinkFromModuleIds } from './project.tools';

const props = defineProps<{ projectId: number }>();
const emit = defineEmits(['back']);

const loading = ref(0);

const pStore = useProjectStore();
const project = computed(() => pStore.projects.get(props.projectId));

type DataEntry = {
  label: string;
  value: any;
  wide?: boolean;
};

const projectData = computed<DataEntry[]>(() => {
  const p = project.value;
  if (!p) return [];
  return [
    {
      label: 'Status',
      value: p.statusName,
    },
    {
      label: 'Adresse',
      value: p.address,
    },
    {
      label: 'Kontrakts-/ordrenummer',
      value: p.ref1,
    },
    {
      label: 'Internnummer',
      value: p.ref2,
    },
    {
      label: 'Ansvarlig montør',
      value: p.installer,
    },
    {
      label: 'Saksbehandler',
      value: p.caseworkerName,
    },
    {
      label: 'Mappe',
      value: p.folder,
    },
    {
      label: 'Avdeling',
      value: p.department,
    },
    {
      label: 'Dato opprettet',
      value: date(p.createdAt),
    },
    {
      label: 'Frist for utførelse',
      value: date(p.deadlineAt),
    },
    {
      label: 'Beskrivelse',
      value: p.description,
      wide: true,
    },
  ];
});

const projectProperties = ref<Map<number, ProjectProperty>>(new Map());
const projectPropertiesData = computed<DataEntry[]>(() =>
  [...projectProperties.value].map(([k, v]) => {
    return {
      label: v.name,
      value: v.dataType === 'datetime' ? date(v.value) : v.value,
    };
  }),
);

const tenantStore = useTenantStore();

const typeInModuleIds = computed(() => {
  return [...tenantStore.modules]
    .filter(([id, m]) => m.projectTypeIds.has(Number(project.value?.typeId)))
    .map(([id, m]) => id);
});

const showEdit = ref(false);

function initialize() {
  loading.value++;

  return Promise.all([
    pStore.loadProjectById(props.projectId),
    pStore.loadProjectProperties(props.projectId),
  ])
    .then(([p, pp]) => {
      projectProperties.value = pp;
    })
    .finally(() => {
      loading.value--;
    });
}

initialize();
</script>

<template>
  <div class="modal-content">
    <DfHeader @click="emit('back')" back no-route>
      {{ project?.name || 'Prosjekt' }}

      <template v-slot:actions>
        <df-button v-on:click="showEdit = true">
          <template v-slot:icon><df-icon code="f304" /></template>
        </df-button>
      </template>
    </DfHeader>

    <DfLoading v-if="loading" />

    <template v-for="(cat, index) in [projectData, projectPropertiesData]">
      <dl class="dl card" style="padding: 0" v-if="cat.length > 0" :key="index">
        <template v-for="entry in cat" :key="entry.label">
          <div class="dl__group" :class="{ 'dl__group--wide': entry.wide }">
            <dt class="dl__topic">{{ entry.label }}</dt>

            <dd class="dl__description">{{ entry.value || '-' }}</dd>
          </div>
        </template>
      </dl>
    </template>

    <div class="actions">
      <df-button
        v-for="url in [projectsLinkFromModuleIds(typeInModuleIds, projectId)].filter((i) => i)"
        :key="url"
        :href="url"
        target="_blank"
      >
        Til prosjekt
        <template v-slot:icon><df-icon code="f08e" /></template>
      </df-button>
    </div>

    <ProjectBookings :project-id="projectId" />

    <ProjectEdit v-if="showEdit" :project-id="projectId" v-on:close="showEdit = false" />
  </div>
</template>

<style scoped>
.modal-content {
  padding: var(--gap-lg);
  display: grid;
  gap: var(--gap-lg);
}

.dl__group--wide {
  grid-column: span 2;
}
</style>
