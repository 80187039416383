export enum StatusGroups {
  Aktive = 1,
  Prosjektering = 4,
}

export enum Modules {
  Timer = 6,
  Ressurs = 7,
  Energi = 14,
  Veilys = 9,
  Ett = 15,
  Byggestrøm = 5,
  Telekom = 22,
  Jernbane = 25,
  Admin = 19,
  Elektro = 29,
}

export enum Roles {
  Admin = 1,
  AppOwner = 2,
  Saksbehandler = 3,
  RessursAnsvarlig = 9,
}

export enum Storage {
  SuggestedProjects = 'SuggestedProjects',
}

export enum RegistrationStatusIds {
  Created = 100,
  Rejected = 150,
  FeedbackSent = 160,
  Approved = 200,
  TransferReady = 300,
  TransferFailed = 320,
  TransferReceived = 350,
  TransferSuccess = 400,
}

export enum ProjectTypes {
  Administrasjon = 11,
  AdminIntegrasjon = 78,
  Ressurs = 62,
}

export enum ProjectProperties {
  RessursPrioritet = 54,
  RessursKommentar = 55,
  PlanlagtStart = 64,
  PlanlagtFerdig = 66,
  RessursTidsbruk = 67,
  RessursMontør = 68,
}

export enum TenantFeatures {
  OutlookIntegration = 'ExternalOutlook',
}
