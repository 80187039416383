<script setup lang="ts">
import { computed } from 'vue';
import { moduleLinks } from '/@stores/app';
import { useUserStore } from '/@stores/user';
import { Modules, Roles } from '/@types/ids';
import { storeToRefs } from 'pinia';
import { hasRoles } from '/@utilities/user';
import { useTenantStore } from '/@stores/tenant';
import { hasTenantFeature, TenantFeatures } from '/@utilities/tenant';
import { useTimerStore } from '/@stores/timer';

const props = defineProps<{
  collapsed: boolean;
}>();

const emit = defineEmits(['update:collapsed']);

const moduleBaseUrl = import.meta.env.VITE_WEBAPP_URL;

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const availableModules = computed(() => moduleLinks.filter(({ id }) => userStore.hasModule(id)));

const timerStore = useTimerStore();
const rejectedCount = computed(() => timerStore.rejectedRegistrations.size);

const navSections = computed(() => {
  return [
    ...(!userStore.hasModule(Modules.Timer)
      ? []
      : [
          {
            label: 'Timer',
            items: [
              {
                label: 'Uke',
                icon: 'f784',
                to: '/timer/week',
              },
              {
                label: 'Dag',
                icon: 'f783',
                to: '/timer/day',
              },
              ...(user.value && hasRoles(user.value, [Roles.Admin, Roles.Saksbehandler])
                ? [
                    {
                      label: 'Godkjenn',
                      icon: 'f560',
                      to: '/timer/approval',
                    },
                  ]
                : []),
            ],
          },
        ]),
    ...(!userStore.hasModule(Modules.Ressurs)
      ? []
      : [
          {
            label: 'Ressurs',
            items: [
              {
                label: 'Alle avtaler',
                icon: 'f133',
                to: '/resources/all',
              },
              {
                label: 'Mine avtaler',
                icon: 'f4fd',
                to: '/resources/events',
              },
              ...(user.value != null && hasRoles(user.value, [Roles.Admin, Roles.Saksbehandler])
                ? [
                    {
                      label: 'Planlegg avtaler',
                      icon: 'e0d2',
                      to: '/resources/schedule',
                    },
                  ]
                : []),
              ...(user.value != null &&
              tenant.value != null &&
              hasRoles(user.value, [Roles.Saksbehandler]) &&
              hasTenantFeature(tenant.value, TenantFeatures.Qualifications)
                ? [
                    {
                      label: 'Filtrér avtaler',
                      icon: 'e0d6',
                      to: '/resources/schedulefilter',
                    },
                  ]
                : []),
              {
                label: 'Søk avtaler',
                icon: 'f002',
                to: '/resources/search',
              },
            ],
          },
        ]),
    {
      divider: true,
      items: [
        {
          label: 'Innstillinger',
          icon: 'f013',
          to: '/settings',
        },
      ],
    },
  ];
});
</script>

<template>
  <nav class="app-nav" :class="{ 'app-nav--collapsed': collapsed }">
    <div class="app-nav__modules">
      <df-dropdown no-overlap>
        <template v-slot:trigger="{ toggle }">
          <button class="nav-item" v-on:click="toggle">
            <img class="nav-item__img" src="/images/time-icon-05x.png" alt="timer logo" />
            <template v-if="!collapsed">
              Time
              <df-icon code="f107" />
            </template>
          </button>
        </template>

        <df-dropdown-item
          v-for="{ name, link, icon, id, external } in availableModules"
          :key="id"
          :href="external ? link : `${moduleBaseUrl}${link}`"
        >
          <template v-slot:icon>
            <img style="width: 1.5rem" :src="icon" :alt="`${name} logo`" />
          </template>

          {{ name }}
        </df-dropdown-item>
      </df-dropdown>
    </div>

    <button class="nav-item nav-item--collapse" v-on:click="$emit('update:collapsed', !collapsed)">
      <df-icon :code="collapsed ? 'f324' : 'f323'" fit />
      <template v-if="!collapsed">Lukk sidemeny</template>
    </button>

    <ul class="app-nav__list">
      <li v-if="userStore.user != null">
        <router-link class="nav-item" active-class="nav-item--active" to="/user">
          <df-icon code="f007" fit />
          <span class="text-oneline" v-if="!collapsed">{{ userStore.user.name }}</span>
        </router-link>
      </li>

      <li>
        <router-link class="nav-item" to="/notifications">
          <df-icon code="f0f3" fit />
          <span class="text-oneline">Varsler</span>
          <div class="count" :class="{ 'count--collapsed ': collapsed }" v-if="rejectedCount">
            {{ rejectedCount }}
          </div>
        </router-link>
      </li>
    </ul>

    <template v-for="{ label, items, divider } in navSections" :key="label">
      <hr class="app-nav__divider" v-if="divider && !collapsed" />

      <h2 class="app-nav__title title-topic" v-if="label && !collapsed">{{ label }}</h2>

      <ul class="app-nav__list">
        <li v-for="{ label, to, icon } in items" :key="label">
          <router-link class="nav-item" active-class="nav-item--active" :to="to">
            <df-icon :code="icon" fit />
            <template v-if="!collapsed">{{ label }}</template>
          </router-link>
        </li>
      </ul>
    </template>

    <button
      class="nav-item nav-item--collapse"
      style="margin-top: auto"
      v-on:click="$emit('update:collapsed', !collapsed)"
    >
      <df-icon :code="collapsed ? 'f324' : 'f323'" fit />
      <template v-if="!collapsed">Lukk sidemeny</template>
    </button>
  </nav>
</template>

<style scoped>
.app-nav {
  background-color: var(--color-cardbg);
  border-right: 1px solid var(--color-divider);
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  overflow-y: auto;

  @media (min-width: 1000px) {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 999.5px) {
    height: calc(100% - var(--shortcut-height));
    width: 80%;
    padding-top: env(safe-area-inset-top);
  }
}

.app-nav__modules {
  border-bottom: 1px solid var(--color-divider);
}

.app-nav__divider {
  border: none;
  height: 0;
  border-top: 1px solid var(--color-divider);
}

.app-nav__title {
  margin: var(--gap-md) var(--gap-md) 0;
}

.app-nav__list {
  list-style: none;
  padding: 0;
}

.nav-item {
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  position: relative;

  display: grid;
  grid-template-columns: [icon] 2rem [text] 1fr;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);
  color: inherit;
  padding: var(--gap-md);
  text-decoration: none;
  align-items: center;

  &:hover {
    background-color: var(--color-hover);
  }
}

@media (max-width: 999.5px) {
  .nav-item--collapse {
    display: none;
  }
}

.nav-item--active {
  background-color: var(--color-bg);
  font-weight: 500;
  border-right: 3px solid var(--color-text);
}
.nav-item__img {
  height: 1.25rem;
  place-self: center;
}

.count {
  background-color: var(--color-warn_bg);
  color: var(--color-warn_text);
  width: 20px;
  height: auto;
  aspect-ratio: 1 / 1;
  display: flex;
  place-content: center;
  border-radius: var(--radius-sm);
  font-size: 0.8rem;
  box-shadow: var(--shadow-md);

  &.count--collapsed {
    position: absolute;
    top: var(--gap-sm);
    right: var(--gap-sm);
  }
}
</style>
