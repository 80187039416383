import { createGuid } from './guid';
import { DefaultWorkingHours, ResourceEvent, ResourceType } from '/@types/resources';

export type EventForm = {
  guid: string;
  fromDate: Date;
  toDate: Date;
  resourceGuid: string;
  resourceType: ResourceType;
  userName?: string;
  users: Array<string>;
  projectId: number | null;
  comment: string;
  // will create separate events per user
  additionalUsers?: string[];
};

export async function createEventForm(
  event: Partial<ResourceEvent>,
  resources,
  defaultWorkingHours: DefaultWorkingHours,
): Promise<EventForm> {
  const resource = resources.find(({ guid }) => guid === event.resourceGuid);

  // Create the fromDate and toDate based on the default working hours
  const fromDate = new Date(event.fromDate ?? new Date());
  fromDate.setHours(
    defaultWorkingHours.startTime.hours,
    defaultWorkingHours.startTime.minutes,
    0,
    0,
  );

  const toDate = new Date(event.toDate ?? new Date());
  toDate.setHours(defaultWorkingHours.endTime.hours, defaultWorkingHours.endTime.minutes, 0, 0);

  return {
    guid: event.guid ?? createGuid(),
    fromDate: fromDate,
    toDate: toDate,
    projectId: event.projectId ?? null,
    comment: event.comment ?? '',

    // if `resource` is not found, it most likely means it's not a group, and therefor it's safe to default to an empty users array
    resourceGuid: resource?.guid ?? event.resourceGuid,
    resourceType: resource?.type ?? event.resourceType,
    userName: resource?.name ?? event.userName, // This is useful so we don't have to look it up, but it's not required for the submission
    users: resource?.subresources.map(({ guid }) => guid) ?? [],
  };
}
