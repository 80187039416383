import { Project, ProjectProperty, ProjectType, Valuelist, ValuelistItem } from './project.types';

type FromApi = any;

export function mapProject(res: any): Project {
  return {
    id: res.Id,
    name: res.Name,
    address: res.Address,
    typeId: res.ProjectTypeId,
    statusId: res.ProjectStatus.Id,
    statusName: res.ProjectStatus.Name,
    caseworkerId: res.CaseworkerId,
    caseworkerName: res.Caseworker,
    installer: res.Installer,
    description: res.Description,
    ref1: res.ReferenceNo1,
    ref2: res.ReferenceNo2,
    colorId: res.ColorId,
    colorHex: res.ColorHex,
    createdAt: new Date(res.CreatedDate),
    updatedAt: new Date(res.UpdatedAt),
    folder: res.ProjectFolder?.Name,
    department: res.Department?.Name,
    contactCompanyName: res.ContactCompanyName,
    deadlineAt: res.DeadlineDate ? new Date(res.DeadlineDate) : null,
    registrationsLocked: res.RegistrationsLockedAt === null ? false : true,
  };
}

export function mapProjectTable(p: FromApi): Project {
  return {
    id: p.Id,
    name: p.Name,
    address: p.Address,
    typeId: p.ProjectTypeId,
    statusId: p.StatusId,
    statusName: p.StatusName,
    caseworkerId: p.CaseworkerId,
    caseworkerName: p.CaseworkerName,
    installer: p.Installer,
    description: p.Description,
    ref1: p.Ref1,
    ref2: p.Ref2,
    colorId: p.ColorId,
    colorHex: p.ColorHex,
    createdAt: new Date(p.CreatedAt),
    updatedAt: new Date(p.UpdatedAt),
    properties: new Map(
      p.ProjectTypeProperties.map((prop: any) => [
        prop.Id,
        { dataType: prop.DataType, value: prop.Value },
      ]),
    ),
    folder: p.FolderName,
    department: p.DepartmentName,
    deadlineAt: p.DeadlineAt,
    contactCompanyName: p.ContactCompanyName,
  };
}

export function mapProjectProperty(p: FromApi): ProjectProperty {
  return {
    id: p.Id,
    name: p.Name,
    sortIndex: p.SortIndex,
    value: p.Value,
    dataType: p.DataType,
  };
}

export function mapProjectType(type: FromApi): ProjectType {
  return {
    id: type.Id,
    name: type.Name,
    properties: type.ProjectTypeProperties.map((p: any) => ({
      id: p.Id,
      name: p.Name,
      showAlways: p.ShowAlways,
      valuelistGuid: p.ValueListGuid,
    })),
  };
}

export function mapValuelist(valuelistGuid: string, list: FromApi): Valuelist {
  return {
    guid: list.Guid,
    valuelistItems: new Map(
      list.ValueListItems.sort((a: any, b: any) => a.SortIndex - b.SortIndex).map((i: any) => [
        i.ValueId,
        mapValuelistItem(i),
      ]),
    ),
  };
}

export function mapValuelistItem(item: FromApi): ValuelistItem {
  return {
    id: item.ValueId,
    name: item.Name,
    sortIndex: item.SortIndex,
  };
}
