<script lang="ts" setup>
import DfCalendar from '/@elements/DfCalendar/DfCalendar.vue';

import ResourceItem from './ResourceItem.vue';
import { useResourceEvents } from './UseResourceEvents';

defineProps({
  view: {
    type: String,
    default: 'dfListDay',
  },
});

const { loading, selectedDate, events, dateChange } = useResourceEvents({ byUser: true });
</script>

<template>
  <div class="page page--wide">
    <h1>Mine avtaler</h1>

    <df-loading v-if="loading > 0" />

    <df-calendar
      class="page__calendar"
      :view="view"
      buttons="dfListWeek,dfListDay"
      :events="events"
      v-on:change=""
      v-on:date-change="dateChange($event)"
      v-on:day-click="selectedDate = $event"
      ref="kek"
    >
      <template v-slot:event="{ event, close }">
        <ResourceItem :event="event" :close="close" :day="selectedDate" />
      </template>
    </df-calendar>
  </div>
</template>

<style scoped>
.page {
  padding-top: calc(var(--gap-md) + env(safe-area-inset-top));
  padding-left: calc(var(--gap-md) + env(safe-area-inset-left));
  padding-right: calc(var(--gap-md) + env(safe-area-inset-right));
  padding-bottom: var(--gap-md);
  display: grid;
  grid-template-rows: max-content 1fr;
}

.page,
.page__calendar {
  max-width: 120ch;
  margin: 0 auto;
  min-height: 100%;
}
</style>
