<script setup lang="ts">
import { nextTick, provide, ref, watch } from 'vue';

const props = defineProps<{
  label?: string;

  elevate?: boolean;
  active?: boolean;
  primary?: boolean;
  danger?: boolean;
  disabled?: boolean;

  grid?: number;
  noCaret?: boolean;
}>();

const emit = defineEmits(['menu-change', 'close', 'open']);

const observer = ref<MutationObserver | null>(null);
const isOpen = ref(false);
const menu = ref('');
const pushX = ref(0);
const pushY = ref(0);
const top = ref(0);
const right = ref(0);
const currentFocus = ref(-1);

watch(menu, () => {
  emit('menu-change', menu.value);
});

function toggle() {
  if (props.disabled) return;
  isOpen.value ? close() : open();
}

function close() {
  observer.value?.disconnect();

  isOpen.value = false;
  menu.value = '';
  pushX.value = 0;
  pushY.value = 0;
  top.value = 0;
  right.value = 0;

  emit('close');

  removeEventListener('keyup', events, true);
}

const el = ref<HTMLDivElement | null>(null);

function open() {
  const rect = el.value?.getBoundingClientRect();

  observer.value?.observe(el.value, { childList: true, subtree: true });

  isOpen.value = true;
  top.value = rect.top;
  right.value = document.body.clientWidth - rect.right;

  emit('open');

  addEventListener('keyup', events, true);
}

function openMenu(iMenu: string) {
  menu.value = iMenu;
}

const menuRef = ref();

function events(event) {
  switch (event.key) {
    case 'Escape':
      currentFocus.value = 0;
      close();
      break;
    case 'ArrowDown': {
      const nodeList = [...(<Array<HTMLElement>>menuRef.value.childNodes)].filter(
        (node: HTMLElement) => node.className === 'item',
      );
      currentFocus.value =
        currentFocus.value === nodeList.length - 1 ? currentFocus.value : currentFocus.value + 1;
      nextTick(() => {
        nodeList[currentFocus.value].focus();
      });
      break;
    }
    case 'ArrowUp': {
      const nodeList = [...(<Array<HTMLElement>>menuRef.value.childNodes)].filter(
        (node) => node.className === 'item',
      );
      currentFocus.value = currentFocus.value <= 0 ? 0 : currentFocus.value - 1;
      nextTick(() => {
        nodeList[currentFocus.value].focus();
      });
      break;
    }
  }
}

function initialize() {
  observer.value = new MutationObserver((mutations) => {
    if (isOpen.value) {
      const rect = menuRef.value.getBoundingClientRect();
      const top = rect.top - pushY.value;
      const bottom = rect.bottom - pushY.value;
      const left = rect.left - pushX.value;
      const right = rect.right - pushX.value;
      const margin = 8; // Must match CSS max-width and max-height margins

      if (top < 0) {
        pushY.value = top * -1 + margin;
      } else if (bottom > innerHeight) {
        pushY.value = innerHeight - bottom - margin;
      } else {
        pushY.value = 0;
      }

      if (left < 0) {
        pushX.value = left * -1 + margin;
      } else if (right > innerWidth) {
        pushX.value = innerWidth - right - margin;
      } else {
        pushX.value = 0;
      }
    }
  });
}

initialize();

provide('menu', menu);
provide('openMenu', openMenu);
provide('close', close);
</script>

<template>
  <div ref="el" class="dropdown" :class="{ 'dropdown--open': isOpen }">
    <button
      class="resetbtn"
      v-on:click="toggle()"
      aria-haspopup
      ref="button"
      type="button"
      :aria-expanded="isOpen"
    >
      <label
        class="dropdown__button"
        :class="{
          'dropdown__button--elevate': elevate,
          'dropdown__button--fit': !label,
          'dropdown__button--fit-icon': !noCaret && !$slots.icon,
          'dropdown__button--disabled': disabled,
          'dropdown__button--primary': primary,
          'dropdown__button--active': active,
        }"
      >
        <div class="dropdown__label" v-if="label || $slots.default">{{ label }}</div>

        <div class="dropdown__text" v-if="$slots.text">
          <slot name="text"></slot>
        </div>

        <div class="dropdown__icon">
          <slot name="icon">
            <df-icon code="f107" />
          </slot>
        </div>
      </label>
    </button>

    <div class="dropdown__overlay" v-if="isOpen">
      <div
        class="overlay__menu menu"
        role="menu"
        :aria-label="menu || label"
        ref="menuRef"
        :class="{ 'menu--sub': menu !== '', 'menu--grid': grid != null }"
        :style="{
          top: `${top}px`,
          right: `${right}px`,
          transform: `translate(${pushX}px, ${pushY}px)`,
          'grid-template-columns': `repeat(${grid}, 1fr)`,
        }"
        tabindex="0"
      >
        <div class="menu__header header">
          <button class="header__button" v-if="menu !== ''" v-on:click="menu = ''">
            <df-icon code="f060" />
          </button>

          <div class="header__title">
            {{ menu || label }}
          </div>

          <button class="header__button" v-on:click="close()">
            <df-icon code="f00d" />
          </button>
        </div>

        <slot></slot>
      </div>

      <div class="overlay__backdrop" v-on:click="close()"></div>
    </div>
  </div>
</template>

<style scoped>
@keyframes in {
  from {
    clip-path: polygon(calc(100% - 2.5rem) 0, 100% 0, 100% 2.5rem, calc(100% - 2.5rem) 2.5rem);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.dropdown {
  position: relative;
  display: grid;
}

.dropdown__button {
  position: relative;
  border: 1px solid var(--color-border);
  display: grid;
  align-content: center;
  width: 100%;
  min-height: 2.5rem;
  border-radius: var(--radius-sm);
  padding: var(--gap-md) var(--gap-md);
  grid-template-areas: 'label icon' 'text icon';
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content max-content;
  grid-column-gap: var(--gap-md);
  &:not(.dropdown__button--disabled):hover {
    background: var(--color-hover);
  }
}

.dropdown__button--fit {
}

.dropdown__button--fit-icon {
}

.dropdown__button--no-text {
  grid-template-areas: 'icon';
  grid-template-columns: 1fr;
}

.dropdown__button--elevate {
  border-color: transparent;
  background-color: var(--color-cardbg);
  box-shadow: var(--shadow-md);
}

.dropdown__button--disabled {
  background-color: var(--color-darken);
}

.dropdown__button--primary {
  background-color: var(--color-primary);
  color: white;
}

.dropdown__button--active {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.dropdown__label {
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
}

.dropdown__text {
  grid-area: text;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__icon {
  grid-area: icon;
  display: grid;
  place-items: center;
}

.dropdown__caret {
  grid-area: icon;
  display: grid;
  align-self: center;
}

.dropdown__overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000000000; /* Dropdown menu should always be on top - no exceptions */
  filter: drop-shadow(var(--shadow-lg));
}

.overlay__menu {
  position: fixed;
  width: 14rem;
  max-width: calc(100vw - var(--gap-md) * 2);
  max-height: calc(100vh - var(--gap-md) * 2);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  animation: in 0.2s;
  transition: transform 0.2s;
  border: 1px solid var(--color-shadow);
  outline: none;
}

.overlay__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 1;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  background-color: var(--color-cardbg);
  border-bottom: 1px solid var(--color-divider);
}

.menu--grid {
  display: grid;
}

.menu--sub .header {
  grid-template-columns: max-content 1fr max-content;
}

.header {
  grid-column: 1 / -1;
}

.header__button {
  padding: var(--gap-md);
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;

  &:first-child {
    border-right: 1px solid var(--color-divider);
  }

  &:last-child {
    border-left: 1px solid var(--color-divider);
  }

  &:hover {
    background-color: var(--color-hover);
  }
}

.header__title {
  padding: var(--gap-md);
}

.resetbtn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

button {
  color: inherit;
}
</style>
