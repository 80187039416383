<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';
import { Project, ProjectProperty } from '/@features/project/project.types';
import { Resource, ResourceEvent } from '/@types/resources';
import { date } from '/@utilities/intl';
import { isNightTime } from '/@utilities/resources';
import { set } from 'date-fns';
import {
  createResourceEvent,
  deleteResourceEvent,
  loadResourceEventsByDate,
  loadResourceEventsByGuid,
  unlinkResourceEvent,
  updateResourceEvent,
} from '/@stores/resources';
import { toast } from '/@utilities/toast';
import { createGuid } from '/@utilities/guid';
import { EventForm } from '/@utilities/events';

import { validateForm, ValidationRule } from '/@utilities/form';
import { useAlert } from '/@elements/DfAlert/DfAlert.vue';
import { useProjectStore } from '/@features/project';
import { projectsLinkFromModuleIds } from '/@features/project/project.tools';
import { ProjectProperties } from '/@types/ids';

const props = defineProps<{
  event: ResourceEvent;

  resources: Resource[];

  day?: Date;
  close: Function;
  setActiveEvent: Function;
  // used instead of emit as it does not work inside cmp > cmp
  update: Function;
  delete: Function;
  unlink: Function;
}>();

const emit = defineEmits(['newEvent']);

const projectStore = useProjectStore();

const { event, resources } = toRefs(props);

const eventForm = ref(populateEventForm(event.value));

const showAll = ref(false);

const activeEvents = ref([]);

watch(
  () => props.event,
  (val) => {
    eventForm.value = populateEventForm(val);
  },
);

watch(
  () => eventForm.value,
  (newVal) => {
    if (isNaN(newVal.fromDate.getTime())) {
      eventForm.value.fromDate =
        props.event.fromDate ??
        set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
    }
    if (isNaN(newVal.toDate.getTime())) {
      eventForm.value.toDate =
        props.event.toDate ??
        set(new Date(), { hours: 16, minutes: 0, seconds: 0, milliseconds: 0 });
    }
  },
  { deep: true },
);

function populateEventForm(event: Partial<ResourceEvent>): EventForm {
  const resource = resources.value.find(({ guid }) => guid === event.resourceGuid);

  return {
    guid: event.guid ?? createGuid(),
    fromDate:
      event.fromDate ?? set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }),
    toDate: event.toDate ?? set(new Date(), { hours: 16, minutes: 0, seconds: 0, milliseconds: 0 }),
    projectId: event.projectId ?? null,
    comment: event.comment ?? '',
    // if `resource` is not found, it most likely means it's not a group, and therefore it's safe to default to an empty users array
    resourceGuid: resource?.guid ?? event.resourceGuid,
    resourceType: resource?.type ?? event.resourceType,
    userName: resource?.name ?? event.userName, // This is useful so we don't have to look it up, but it's not required for the submission
    users: resource?.subresources.map(({ guid }) => guid) ?? [],
  };
}

const submittingForm = ref(false);
const submittingFormError = ref(false);
const loading = ref(0);

const loadingEvent = ref(new Set());

function getEvent(eventGuid: string) {
  loadingEvent.value.add(eventGuid);
  return loadResourceEventsByGuid(eventGuid).then((event) => {
    if (!event) return;
    eventForm.value = populateEventForm(event);
    loadingEvent.value.delete(eventGuid);
    return eventGuid;
  });
}

const alert = useAlert();

function submitEvent(form: EventForm, create = false) {
  submittingForm.value = true;
  submittingFormError.value = false;

  const validation: Array<ValidationRule> = [
    [form.fromDate > form.toDate, 'Fra tid må være før Til tid'],
  ];

  const { ok, message } = validateForm(validation);

  if (message) {
    alert.write(message);
    submittingForm.value = false;
    return;
  }

  const request = {
    guid: form.guid,
    resourceGuid: form.resourceGuid,
    resourceType: form.resourceType,
    users: form.users,
    fromDate: form.fromDate,
    toDate: form.toDate,
    projectId: form.projectId,
    comment: form.comment,
  };

  return (
    create ? createResourceEvent(form.guid, request) : updateResourceEvent(form.guid, request)
  )
    .then(() => {
      props.update(form.guid);
      loading.value--;
      toast(`Avtale ${create ? 'opprettet' : 'lagret'}`);
      eventForm.value = populateEventForm(event.value);
    })
    .catch(() => {
      submittingFormError.value = true;
    })
    .finally(() => {
      submittingForm.value = false;
      lastSaved.value = form.guid;
    });
}

function copyEvent(event: ResourceEvent, resourceGuid: string) {
  const guid = createGuid();
  createResourceEvent(guid, {
    projectId: event.projectId,
    resourceGuid,
    resourceType: event.resourceType,
    users: [],
    fromDate: event.fromDate,
    toDate: event.toDate,
    comment: event.comment ?? '',
  }).then(() => {
    props.update(guid);
    toast('Avtale kopiert');
  });
}

function unlinkEvent(eventGuid: string) {
  unlinkResourceEvent(eventGuid).then(() => {
    toast('Koblet av avtalen');
    props.unlink(eventGuid);
  });
}

function deleteEvent(eventGuid: string) {
  deleteResourceEvent(eventGuid).then(() => {
    toast('Avtale slettet');
    props.delete(eventGuid);
  });
}

const isEditable = computed(() => {
  if (event.value.resourceType === 'group') return true;
  if (event.value.linkedByGuid != null) return false;
  if (submittingForm.value || loading.value > 0) return false;
  return true;
});

const isDirty = computed(() => {
  if (eventForm.value.comment !== (event.value.comment ?? '')) return true;
  if (eventForm.value.fromDate !== event.value.fromDate) return true;
  if (eventForm.value.toDate !== event.value.toDate) return true;
  if (eventForm.value.projectId !== event.value.projectId) return true;

  return false;
});

const showProjectInfo = ref(false);
const project = ref<Project | null>(null);
const projectProperties = ref<Map<number, ProjectProperty>>(new Map());

const projectInfo = ref<Array<{ label: string; value: string }>>([]);

async function showProjectInfoData(projectId: number) {
  showProjectInfo.value = true;

  if (project != null && project.value?.id !== projectId) {
    project.value = null;

    Promise.all([
      projectStore.loadProjectById(projectId),
      projectStore.loadProjectProperties(projectId),
    ]).then(([proj, properties]) => {
      project.value = proj;
      projectProperties.value = properties;

      projectInfo.value = [
        { label: 'Status', value: project.value.statusName },
        { label: 'Saksbehandler', value: project.value.caseworkerName },
        { label: 'Adresse', value: project.value.address },
        { label: 'Oppdragsgiver', value: project.value.contactCompanyName },
        { label: 'Kontrakts-/ordrenummer', value: project.value.ref1 },
        { label: 'Internnummer', value: project.value.ref2 },
        { label: 'Ansvarlig montør', value: project.value.installer },
        { label: 'Avtale opprettet av', value: props.event.createdByUserName },
        ...[...properties]
          .filter(([id, p]) =>
            [ProjectProperties.RessursPrioritet, ProjectProperties.RessursKommentar].includes(id),
          )
          .map(([id, p]) => ({ label: p.name, value: p.value })),
        { label: 'Beskrivelse', value: project.value.description, large: true },
      ];
    });
  }
}

const lastSaved = ref(null);

function requestNewEvent() {
  emit('newEvent');
}

const selectedResources = ref(new Set());

function toggleResource(guid) {
  selectedResources.value.has(guid)
    ? selectedResources.value.delete(guid)
    : selectedResources.value.add(guid);
}

function copyResources() {
  if (selectedResources.value.size === 0) return;
  Promise.all(
    [...selectedResources.value].map((guid: string) => copyEvent(event.value, guid)),
  ).then(() => {
    props.close?.();
    selectedResources.value = selectedResources.value = new Set();
  });
}

const initialize = () => {
  const event = props.event;
  loading.value++;
  loadResourceEventsByDate(event.fromDate, event.toDate)
    .then((res) => {
      if (!res) return;

      const a = res
        .filter((event2) => event2.projectId === event.projectId)
        .map((event) => {
          return {
            ...event,
            isSelectedDate: true,
          };
        })
        .sort((a, b) => +a.fromDate - +b.fromDate);

      activeEvents.value = a;
    })
    .finally(() => {
      loading.value--;
    });
};

initialize();
</script>

<template>
  <form class="dialog" v-on:submit.prevent="submitEvent(eventForm)">
    <header class="head">
      <df-button v-on:click="close()">
        <template v-slot:icon>
          <df-icon code="f060" />
        </template>
      </df-button>

      <h1 class="title-md">{{ event.projectName }}</h1>
    </header>

    <div
      class="card card--warn alert"
      v-if="event.linkedByGuid != null && event.resourceType === 'user'"
    >
      Denne avtalen er styrt av en felles avtale, og kan ikke redigeres.

      <df-button v-on:click="requestNewEvent()" :disabled="loadingEvent.has(event.mainEventGuid)">
        Åpne

        <template v-slot:icon v-if="loadingEvent.has(event.mainEventGuid)">
          <div class="loading-icon"></div>
        </template>
      </df-button>
    </div>

    <div
      class="card card--info alert"
      v-if="event.linkedByGuid != null && event.resourceType === 'group'"
    >
      Denne avtalen er en felles avtale og styrer flere andre avtaler.

      <df-button
        v-on:click="
          unlinkEvent(event.guid);
          close();
        "
      >
        Koble fra
      </df-button>
    </div>

    <df-alert v-model="alert" />

    <div class="dl card" style="padding: 0">
      <div class="dl__group dl__group--large dl__group--actions">
        <h2 class="dl__topic">Prosjekt</h2>
        <p class="dl__description">{{ event.projectName }}</p>

        <div class="dl__actions">
          <df-button
            v-on:click="
              showProjectInfo
                ? (showProjectInfo = !showProjectInfo)
                : showProjectInfoData(event.projectId)
            "
            target="_blank"
            :active="showProjectInfo"
          >
            <template v-slot:icon>
              <df-icon code="f05a" />
            </template>
          </df-button>

          <template v-if="event.projectAccess && event.projectModuleIds.length > 0">
            <!-- This is a single item loop so we prevent calling the function twice (once for v-if and another for :href) -->
            <df-button
              v-for="link in [
                projectsLinkFromModuleIds(event.projectModuleIds, event.projectId),
              ].filter((x) => x)"
              target="_blank"
              :href="link"
            >
              <template v-slot:icon>
                <df-icon code="f08e" />
              </template>
            </df-button>
          </template>
        </div>
      </div>

      <template v-if="showProjectInfo && projectInfo == null">
        <div class="dl__group dl__group--large dl__group--horizontal">
          <div class="dl__description">
            <div class="loading-icon"></div>
          </div>
        </div>
      </template>

      <template v-else-if="showProjectInfo">
        <template v-for="entry in projectInfo" :key="entry.label">
          <div class="dl__group dl__group--sub" :class="{ 'dl__group--large': entry.large }">
            <h2 class="dl__topic">{{ entry.label }}</h2>
            <p class="dl__description">{{ entry.value || '-' }}</p>
          </div>
        </template>
      </template>

      <div
        class="dl__group dl__group--large dl__group--horizontal"
        v-if="event.projectColorHex != null || isNightTime(event) || event.linkedByGuid != null"
      >
        <!-- <h2 class="dl__topic">Badges</h2> -->
        <div class="dl__description" v-if="event.projectColorHex != null">
          <div
            class="badge"
            :style="{ 'background-color': `#${event.projectColorHex}`, color: 'white' }"
          >
            {{ event.projectName }}
          </div>
        </div>

        <div class="dl__description" v-if="isNightTime(event)">
          <div class="badge" style="background-color: var(--color-event-overtime); color: white">
            <df-icon code="f755" />
            Nattetid
          </div>
        </div>

        <div class="dl__description" v-if="event.linkedByGuid != null">
          <div class="badge" style="background-color: var(--color-event-grouped); color: white">
            <df-icon code="f0c1" />
            Felles
          </div>
        </div>
      </div>

      <div class="dl__group dl__group--large" v-if="event.userName">
        <h2 class="dl__topic">Ressurs</h2>
        <p class="dl__description">{{ event.userName }}</p>
      </div>

      <label class="dl__group ghost-label">
        <h2 class="dl__topic">Fra</h2>
        <input
          class="dl__description ghost-input"
          type="datetime-local"
          v-on:input="
            eventForm.fromDate = new Date(
              $event.target.valueAsNumber + eventForm.fromDate.getTimezoneOffset() * 1000 * 60,
            )
          "
          :valueAsNumber="
            eventForm.fromDate.getTime() - eventForm.fromDate.getTimezoneOffset() * 1000 * 60
          "
          :disabled="!isEditable"
        />
      </label>

      <label class="dl__group ghost-label">
        <h2 class="dl__topic">Til</h2>
        <input
          class="dl__description ghost-input"
          type="datetime-local"
          v-on:input="
            eventForm.toDate = new Date(
              $event.target.valueAsNumber + eventForm.toDate.getTimezoneOffset() * 1000 * 60,
            )
          "
          :valueAsNumber="
            eventForm.toDate.getTime() - eventForm.toDate.getTimezoneOffset() * 1000 * 60
          "
          :disabled="!isEditable"
        />
      </label>

      <label class="dl__group dl__group--large ghost-label">
        <h2 class="dl__topic">Avtalekommentar</h2>
        <textarea
          class="dl__description ghost-input"
          v-model="eventForm.comment"
          :disabled="!isEditable"
          placeholder="Ingen kommentar til avtalen"
        ></textarea>
      </label>

      <template
        v-for="entry in [
          { label: 'Opprettet av', value: event.createdByUserName, show: true },
          { label: 'Opprettet', value: date(event.createdAt), show: true },
          {
            label: 'Sist endret av',
            value: event.updatedByUserName,
            show: event.updatedAt > event.createdAt,
          },
          {
            label: 'Sist endret',
            value: date(event.updatedAt),
            show: event.updatedAt > event.createdAt,
          },
        ]"
        :key="entry.label"
      >
        <div class="dl__group" v-if="entry.show">
          <h2 class="dl__topic">{{ entry.label }}</h2>
          <p class="dl__description">{{ entry.value }}</p>
        </div>
      </template>

      <div class="dl__footer dl__footer--aligned-start" v-if="isDirty">
        <df-button
          type="submit"
          title="Lagre"
          primary
          elevate
          :disabled="loading > 0 || submittingForm"
        >
          Lagre
          <template v-slot:icon>
            <df-icon code="f00c" />
          </template>
        </df-button>

        <df-button v-on:click="eventForm = populateEventForm(event)" title="Avbryt">
          <template v-slot:icon>
            <df-icon code="f00d" />
          </template>
        </df-button>

        <div class="save-status" v-if="submittingForm">
          <div class="loading-icon"></div>
          Lagrer
        </div>

        <div
          class="save-status save-status--error"
          v-else-if="lastSaved === event.guid && submittingFormError"
        >
          <df-icon code="f057" />
          Feil. Kunne ikke oppdatere avtale.
        </div>

        <div class="save-status" v-else-if="loading > 0">
          <div class="loading-icon"></div>
          Oppdaterer
        </div>
      </div>
    </div>

    <div class="card user-list" v-if="activeEvents.length > 1">
      <header class="user-list-header">
        <h2 class="title-sm">Personer på samme avtale</h2>

        <df-checkbox v-model="showAll">Alle</df-checkbox>
      </header>

      <ul>
        <li
          v-for="event in activeEvents.filter((e) => (showAll ? true : e.isSelectedDate))"
          :class="{ '--bold': event.isSelectedDate }"
        >
          <div>{{ event.userName }}</div>
          <div>
            {{
              event.fromDate.getDate() === event.toDate.getDate()
                ? date(event.fromDate, { short: true })
                : [date(event.fromDate, { short: true }), date(event.toDate, { short: true })].join(
                    ' - ',
                  )
            }}
          </div>
        </li>
      </ul>
    </div>

    <div class="actions">
      <df-dropdown>
        <template v-slot:trigger="{ toggle }">
          <df-button v-on:click="toggle()">
            Kopiér
            <template v-slot:icon>
              <df-icon code="f0c5" />
            </template>
          </df-button>
        </template>

        <template v-slot:default>
          <template v-for="resource in resources" :key="resource.guid">
            <component
              :is="resource.subresources.length > 0 ? 'df-dropdown-item-block' : 'df-dropdown-item'"
              v-on:click="toggleResource(resource.guid)"
              :no-close="true"
            >
              <div :class="{ 'title-sm': resource.subresources.length > 0 }">
                {{ resource.name }}
              </div>

              <template v-slot:icon v-if="resource.subresources.length === 0">
                <df-icon :code="selectedResources.has(resource.guid) ? 'f14a' : 'f0c8'" />
              </template>
            </component>

            <df-dropdown-item
              v-for="subresource in resource.subresources"
              :key="resource.guid"
              v-on:click="toggleResource(subresource.guid)"
              :no-close="true"
            >
              <template v-slot:icon>
                <df-icon :code="selectedResources.has(subresource.guid) ? 'f14a' : 'f0c8'" />
              </template>
              {{ subresource.name }}
            </df-dropdown-item>
          </template>

          <df-dropdown-item-block no-icon close-on-click>
            <df-button v-on:click="copyResources()" primary elevate>Kopiér</df-button>
          </df-dropdown-item-block>
        </template>
      </df-dropdown>

      <df-confirm-modal variant="danger">
        <template v-slot:trigger="{ toggle }">
          <df-button :key="`toggle-delete-${event.guid}`" danger v-on:click="toggle()">
            Slett
            <template v-slot:icon>
              <df-icon code="f2ed" />
            </template>
          </df-button>
        </template>

        <template v-slot:text>
          Du er i ferd med å slette avtalen. <br />
          Denne handlingen kan ikke angres.
        </template>

        <template v-slot:confirm="{ confirm }">
          <df-button
            :key="`confirm-delete-${event.guid}`"
            v-on:click="
              confirm(deleteEvent(event.guid));
              close();
            "
            danger
            elevate
          >
            Slett avtale
          </df-button>
        </template>
      </df-confirm-modal>
    </div>
  </form>
</template>

<style scoped>
.ghost-label:focus-within {
  box-shadow: inset 0 0 0 1px black;
}

.ghost-label .ghost-input {
  outline: none;
}

.ghost-input {
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  resize: none;
}

.actions {
  gap: var(--gap-md);
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.alert {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.save-status {
  align-self: center;
  display: flex;
  gap: var(--gap-sm);
  align-items: center;
}

ul {
  font-size: 0.8rem;
  max-height: 300px;
  overflow: auto;
}

li {
  display: flex;
  justify-content: space-between;
  padding: var(--gap-sm);
  opacity: 0.5;
}

.--bold {
  font-weight: 500;
  opacity: 1;
}

.user-list-header {
  display: flex;
  justify-content: space-between;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}
</style>
