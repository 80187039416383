<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  elevate?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits(['confirm']);

const showConfirm = ref(false);

function toggleConfirm() {
  showConfirm.value = !showConfirm.value;
}

function confirm() {
  emit('confirm');
  showConfirm.value = false;
}
</script>

<template>
  <div class="delete" role="group" aria-label="delete">
    <df-button
      v-if="showConfirm"
      v-on:click="confirm"
      danger
      :elevate="elevate"
      aria-label="Bekreft sletting"
      :disabled="disabled"
    >
      <template v-slot:icon>
        <df-icon code="f00c" />
      </template>
    </df-button>

    <df-button
      v-on:click="toggleConfirm"
      :danger="!showConfirm"
      :elevate="elevate"
      :disabled="disabled"
    >
      <template v-slot:default v-if="$slots.default && !showConfirm">
        <slot></slot>
      </template>

      <template v-slot:icon>
        <df-icon code="f00d" v-if="showConfirm" />

        <template v-else>
          <slot name="icon">
            <df-icon code="f2ed" />
          </slot>
        </template>
      </template>
    </df-button>
  </div>
</template>

<style scoped>
.delete {
  display: grid;
  grid-auto-flow: column;
  gap: var(--gap-sm);
}
</style>
