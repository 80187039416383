import { computed, defineComponent, ref } from 'vue';
import { clearFilter, getFilterValue, replaceFilterValues, toggleFilterValue } from '/@utilities/opt';
import { useTenantStore } from '/@stores/tenant';
import { loadResourceFilter } from '/@stores/resources';

enum FilterKeys {
  CaseworkerIds = 'projectCaseworkerIds',
  ProjectTypeIds = 'projectTypeIds',
  DepartmentIds = 'projectDepartmentIds',
  FilterIds = 'filterIds',
  QualificationIds = 'userQualificationIds',

  FromDate = 'fromDate',
  ToDate = 'toDate',

  Projectname = 'projectName',
  UserName = 'userName',

  Limit = 'limit',
}

enum FilterTypes {
  Search = 'search',
  Select = 'select',
}

function shorten(arr) {
  return arr.length > 1 ? `${arr[0]} +${arr.length - 1}` : arr[0];
}

export default defineComponent({
  name: 'FilterComp',

  components: {},

  props: {
    opt: {
      type: Object,
    },
  },

  setup(props) {
    // stores
    const tenantStore = useTenantStore();
    const resourceFilters = ref([]);

    const filters = computed(() => [
      {
        label: 'Avtaler',
        entries: [
          {
            placeholder: 'Søk prosjektnavn',
            filterKey: FilterKeys.Projectname,
            filterType: FilterTypes.Search,
          },
          {
            label: 'Saksbehandler',
            filterKey: FilterKeys.CaseworkerIds,
            list: [...tenantStore.caseworkers].map(([id, { userName }]) => [id, userName]),
            filterType: FilterTypes.Select,
            text: shorten(
              getFilterValue(props.opt.filters, FilterKeys.CaseworkerIds).map(
                (i) => tenantStore.caseworkers.get(i)?.userName
              )
            ),
          },
          {
            label: 'Avdeling',
            filterKey: FilterKeys.DepartmentIds,
            filterType: FilterTypes.Select,
            list: tenantStore.departments,
            text: shorten(
              getFilterValue(props.opt.filters, FilterKeys.DepartmentIds).map((i) =>
                tenantStore.departments.get(i)
              )
            ),
          },
          // {
          //   label: 'Prosjekttype',
          //   filterKey: FilterKeys.ProjectTypeIds,
          //   filterType: FilterTypes.Select,
          //   list: null,
          //   text: null,
          // },
        ],
      },
      {
        label: 'Brukere',
        entries: [
          {
            placeholder: 'Søk brukernavn',
            filterKey: FilterKeys.UserName,
            filterType: FilterTypes.Search,
          },
          {
            label: 'Gruppe',
            filterKey: FilterKeys.FilterIds,
            filterType: FilterTypes.Select,
            list: resourceFilters.value.map(({ id, name }) => [id, name]),
            text: shorten(
              getFilterValue(props.opt.filters, FilterKeys.FilterIds).map(
                (i) => resourceFilters.value.find((ii) => i === ii.id)?.name
              )
            ),
          },
          {
            label: 'Kompetanse',
            filterKey: FilterKeys.QualificationIds,
            filterType: FilterTypes.Select,
            list: tenantStore.qualifications,
            text: shorten(
              getFilterValue(props.opt.filters, FilterKeys.QualificationIds).map((i) =>
                tenantStore.qualifications.get(i)
              )
            ),
          },
        ],
      },
    ]);

    function initialLoad() {
      Promise.all([
        loadResourceFilter(),
        tenantStore.loadCaseworkers(),
        tenantStore.loadQualifications(),
        tenantStore.loadDepartments(),
      ]).then(([filters, i2, i3, i4]) => {
        resourceFilters.value = filters || [];
      });
    }

    initialLoad();

    return {
      filters,

      FilterTypes,
      getFilterValue,
      replaceFilterValues,
      toggleFilterValue,
      clearFilter,
    };
  },
});
