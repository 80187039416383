<script setup lang="ts">
import { computed, inject, Ref } from 'vue';

const props = defineProps<{
  toMenu?: string;
  inMenu?: string;
  /** Padding to align with checkbox and radio dropdown-item variants */
  padStart?: boolean;
  disabled?: boolean;
  noClose?: boolean;

  to?: string;
  href?: string;
  target?: string;
}>();

const emit = defineEmits(['click']);

const isVisible = computed(() => {
  if (props.inMenu) {
    return menu.value === props.inMenu;
  } else {
    return menu.value === '';
  }
});

const menu = inject('menu') as Ref<string>;
const openMenu = inject('openMenu') as (menu: string) => void;
const close = inject('close') as () => void;

function click() {
  if (props.toMenu) {
    openMenu(props.toMenu);
  } else if (!props.noClose) {
    close();
  }

  emit('click');
}
</script>

<template>
  <router-link
    v-if="isVisible && to"
    :to="to"
    class="item"
    :class="{ 'item--disabled': disabled, 'item--pad-start': padStart }"
    v-on:click="click"
    role="menuitem"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon"></slot>
  </router-link>

  <a
    v-else-if="isVisible && href"
    :href="href"
    :target="target"
    class="item"
    :class="{ 'item--disabled': disabled, 'item--pad-start': padStart }"
    v-on:click="click"
    role="menuitem"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon"></slot>
  </a>

  <!-- <button> smaker ikke så godt som det koster (spesialregler pga. grid) ... -->
  <div
    v-else-if="isVisible"
    class="item"
    :class="{
      'item--disabled': disabled,
      'item--to-menu': toMenu,
      'item--pad-start': padStart,
    }"
    v-on:click="click"
    role="menuitem"
    v-on:keyup.enter="click"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon">
      <df-icon v-if="toMenu" code="f054" />
    </slot>
  </div>
</template>

<style scoped>
.item {
  padding: var(--gap-md);
  display: grid;
  align-items: center;
  grid-template-columns: minmax(min-content, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-md);
  color: inherit;
  font: inherit;
  text-decoration: none;
  text-align: left;
  word-wrap: break-word;

  &:not(a) {
    cursor: default;
  }

  &:hover {
    background-color: var(--color-hover);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-divider);
  }
}

.item--disabled {
  pointer-events: none;
  color: var(--color-muted);
  background-color: var(--color-darken);
}

.item--to-menu {
  font-weight: 600;
}

.item--pad-start {
  padding-left: calc(1.5em + (var(--gap-md) * 2));
}
</style>
