<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import DfCalendar from '/@elements/DfCalendar/DfCalendar.vue';
import FilterComp from './FilterComp/FilterComp.vue';
import EventDialog from '/@pages/SchedulePage/EventDialog/EventDialog.vue';
import {
  loadResourceEvents,
  loadResourceEventsByGuid,
  loadResourceFilter,
  loadResourceUsers,
} from '/@stores/resources';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { optFromLocalStorage, optToLocalStorage } from '/@utilities/opt';
import { isEqual, cloneDeep } from 'lodash-es';
import { OptType } from '/@types/opt';

import ResourceDialog from '/@pages/SchedulePage/ResourceDialog/ResourceDialog.vue';
import CreateEvent from '/@pages/SchedulePage/CreateEvent/CreateEvent.vue';
import { createEventForm } from '/@utilities/events';
import { add } from 'date-fns';
import { toast } from '/@utilities/toast';
import useEvents from '/@composables/useEvents';
import { DefaultWorkingHours } from '/@types/resources';

const tenantStore = useTenantStore();

const baseOpt = {
  dateRange: {
    start: new Date(),
    end: new Date(),
  },
  filters: [],
};

const resources = ref([]);
const { tenant } = storeToRefs(tenantStore);

const defaultWorkingHours = ref<DefaultWorkingHours | void>({
  startTime: {
    hours: 8,
    minutes: 0,
  },
  endTime: {
    hours: 16,
    minutes: 0,
  },
});

const opt = ref({
  ...baseOpt,
  ...optFromLocalStorage(OptType.List, 'search/opt'),
});

const loading = ref(0);

const events = useEvents();

watch(
  () => cloneDeep(opt.value),
  (currentValue, oldValue) => {
    if (isEqual(oldValue, currentValue)) return;

    optToLocalStorage('search/opt', currentValue);

    loading.value++;

    Promise.all([loadResourceUsers(currentValue), loadResourceEvents(currentValue)]).then(
      ([resourceEntries, eventEntries]) => {
        resources.value = resourceEntries;
        events.events.value = eventEntries;
        loading.value--;
      },
    );
  },
  { deep: true },
);

const filteredResources = computed(() => {
  const a = events.events.value
    .filter(({ resourceType }) => resourceType === 'user')
    .reduce((acc, cv) => {
      acc.add(cv.resourceGuid);
      return acc;
    }, new Set());

  const b = resources.value.filter((r) => a.has(r.guid));
  return b;
});

const selected = ref(null);

const filteredResources2 = computed(() => {
  return resources.value.filter((r) => {
    const a = selected.value ? r.filters.find(({ id }) => id === selected.value) : true;
    const b = showHidden.value ? true : events.events.value.some((i) => i.resourceGuid === r.guid);
    return a && b;
  });
});

const filters = ref([]);

function changeDate({ start, end }) {
  opt.value.dateRange = { start, end };
}

const showHidden = ref(false);

const showCreateEvent = ref(false);

const selectedResource = ref(null);

function setSelectedResource(event) {
  createEventForm(
    {
      toDate: add(event.date, { hours: 8 }),
      fromDate: event.date,
      resourceGuid: event.resource.guid,
      resourceType: event.resource.type,
      userName: event.resource.name,
    },
    resources.value,
    defaultWorkingHours.value,

  ).then((res) => {
    selectedResource.value = res;
    showCreateEvent.value = true;
  });
}

function submitWrapper(fn: any, create = false) {
  loading.value++;

  return fn
    .then(() => {
      toast(`Avtale ${create ? 'opprettet' : 'oppdatert'}`);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value--;
    });
}

function getEvent(eventGuid: string) {
  if (events.events.value.some(({ guid }) => guid === eventGuid)) return Promise.resolve();

  return loadResourceEventsByGuid(eventGuid).then((event) => {
    if (!event) return;
    events.events.value.push(event);
  });
}

function load() {
  loading.value++;

  return Promise.all([loadResourceUsers(opt.value), loadResourceEvents(opt.value)]).then(
    ([resourceEntries, eventEntries]) => {
      resources.value = resourceEntries;
      events.events.value = eventEntries;
      loading.value--;
    },
  );
}

async function initialLoad() {
  await awaitTenant;

  defaultWorkingHours.value = tenantStore.getDefaultWorkingHours();
  
  load().then(() => {
    loadResourceFilter().then((i) => (filters.value = i || []));
  });
}

initialLoad();
</script>

<template>
  <div class="page--wide">
    <h1 class="title title-lg">
      Filtrér avtaler
      <div class="badge">Beta</div>
    </h1>

    <df-loading v-if="loading > 0" />

    <section class="aa">
      <filter-comp :opt="opt">
        <df-checkbox v-model="showHidden" elevate>Vis brukere uten avtaler</df-checkbox>
      </filter-comp>
    </section>

    <df-calendar
      :events="events.events.value"
      :resources="filteredResources2"
      view="dfResourceWeekEaDay"
      buttons="dfResourceMonth,dfResourceWeekEaDay,dfResourceWeekEaHour"
      v-on:date-change="changeDate($event)"
      v-on:date-click="setSelectedResource($event)"
      v-on:event-edit="submitWrapper(events.fromExisting($event))"
    >
      <template v-slot:resource="{ resource, close }">
        <resource-dialog :resource="resource" :resource-filters="filters" :close="close" />
      </template>

      <template v-slot:event="{ event, close, setActiveEvent }">
        <event-dialog
          :event="event"
          :close="close"
          :resources="filteredResources2"
          :set-active-event="setActiveEvent"
          :update="load"
          v-on:newEvent="
            getEvent(event.mainEventGuid).then(() =>
              setActiveEvent(events.events.value.find(({ guid }) => event.mainEventGuid === guid)),
            )
          "
        />
      </template>
    </df-calendar>

    <df-modal v-if="showCreateEvent">
      <create-event
        :selected-resource="selectedResource"
        v-on:create="submitWrapper(events.fromForm($event, true), true)"
        v-on:close="showCreateEvent = false"
      />
    </df-modal>
  </div>
</template>

<style scoped>
.page--wide {
  display: grid;
  grid-template-columns: 30ch 1fr;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
  height: 100vh;
  align-content: start;
  grid-template-rows: max-content 1fr;
}

.map {
  z-index: 0;
}

.badges {
  display: flex;
  gap: var(--gap-sm);
  flex-wrap: wrap;
}

.badge {
  cursor: default;
}

.--active {
  background-color: var(--color-primary);
}

.title {
  grid-column: span 2;
}
</style>
