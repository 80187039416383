import { ref } from 'vue';
import { defineStore } from 'pinia';
import { api, genericApiErrorHandler } from '/@utilities/api';

type Activity = {
  name: string;
};

function mapActivity(activity: any): Activity {
  return {
    name: activity.ActivityName,
  };
}

export const useActivitiesStore = defineStore('activities', () => {
  const activities = ref<Map<number, { name: string }>>(new Map());

  function loadActivities(projectId: number) {
    return api
      .get(`projects/${projectId}/activities`)
      .then(({ data }) => {
        activities.value = new Map(data.map((a: any) => [a.ActivityId, mapActivity(a)]));
      })
      .catch(genericApiErrorHandler);
  }

  return { activities, loadActivities };
});
