<script setup lang="ts">
import { computed } from 'vue';

type ArticleLabels = 'Normaltid' | 'Overtid' | 'Utgift' | 'Fravær';

const props = defineProps<{
  types: Set<ArticleLabels>;
}>();

const list = computed(() => {
  if (props.types.has('Normaltid')) return ['var(--color-event)', 'Normaltid'];
  if (props.types.has('Overtid')) return ['var(--color-red)', 'Overtid'];
  if (props.types.has('Utgift')) return ['var(--color-expense)', 'Utgift'];
  if (props.types.has('Fravær')) return ['var(--color-day)', 'Fravær'];
  return ['', ''];
});

const [color, value] = list.value;
</script>

<template>
  <div class="badge" :style="{ '--color-badge-bg': color }">
    <slot name="default">{{ value }}</slot>
  </div>
</template>

<style scoped>
.badge {
  --color-badge-bg: var(--color-darken);
  background-color: var(--color-badge-bg);
}
</style>
