import { Valuelist, ValuelistItem } from '/@types/valuelist';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';

function mapValuelist(list: any, valuelistGuid: string | null = null): Valuelist {
  return {
    id: list.Id,
    guid: list.Guid,
    name: list.Name,
    folder: list.Folder,
    // used with NVDB
    externalId: list.ExternalId,
    externalSource: list.ExternalSource,

    valuelistItems: new Map(
      list.ValueListItems.map((obj: any) => [
        obj.ValueId,
        mapValuelistItem(obj, valuelistGuid),
      ]).sort((a: any, b: any) => a[1].sortIndex - b[1].sortIndex),
    ),
    valuelistGuid,

    deleted: list.Deleted,
    isTenantSpecific: list.TsTenantSpecific,
    projectTypes: list.ProjectTypes,
  };
}

function mapValuelistItem(obj: any, valuelistGuid = null): ValuelistItem {
  return {
    id: obj.ValueId,
    name: obj.Name,
    valuelistId: obj.ValueListId,
    valuelistGuid,
    sortIndex: obj.SortIndex,
    // used with NVDB
    externalId: obj.ExternalId,
    externalSource: obj.ExternalSource,
    ownedByTenantId: obj.OwnedByTenantId,
  };
}

export function loadValuelistByGuid({
  valuelistGuid,
  partnerId = null,
}: {
  valuelistGuid: string;
  partnerId?: number | null;
}) {
  const queries = createQueryParams(new Map([['partnerId', partnerId]]));
  return api
    .get(`/tenants/valuelists/${valuelistGuid}?${queries}`)
    .then(({ data: valuelist }) => mapValuelist(valuelist, valuelistGuid))
    .catch(genericApiErrorHandler);
}
