<script setup lang="ts">
import { computed, ref } from 'vue';
import { useUserStore } from '/@stores/user';
import { UserForm } from '/@types/user';
import { toast } from '/@utilities/toast';
import { logout } from '/@utilities/api';

const userStore = useUserStore();

const userForm = ref<UserForm>({
  name: null,
  phoneNumber: null,
});

function populateUserForm() {
  userForm.value = {
    name: userStore.user.name,
    phoneNumber: userStore.user.phoneNumber,
  };
}

Promise.all([userStore.loadUser(true)]).then(() => {
  populateUserForm();
});

const validationRules = computed(() => {
  return [[!userForm.value.name, 'Fult navn kreves']];
});

const formData = computed(() => {
  return {
    formValues: userForm.value,
    formValidation: validationRules.value,
    submit: () => userStore.updateUser(userForm.value),
    onSuccess: () => {
      toast('Bruker oppdatert');
    },
  };
});
</script>

<template>
  <section class="sub-page">
    <df-header>Min informasjon</df-header>

    <df-form
      :submit="formData.submit"
      :form-values="formData.formValues"
      :form-validation="formData.formValidation"
      :on-success="formData.onSuccess"
    >
      <df-input label="Fult navn" v-model="userForm.name" elevate />

      <df-input label="Telefon" v-model="userForm.phoneNumber" elevate />
    </df-form>

    <div class="info" v-if="userStore.user">
      <h2 class="title-md">Mine roller</h2>

      <div class="badge-group">
        <div class="badge" v-for="{ id, name } in userStore.user.roles" :key="id">
          {{ name }}
        </div>
      </div>
    </div>

    <h2>Logg ut</h2>

    <div>
      <df-button v-on:click="logout" elevate>Logg ut</df-button>
    </div>
  </section>
</template>

<style scoped>
.settings {
  display: grid;
  gap: var(--gap-md);
}

.badge-group {
  display: flex;
  gap: var(--gap-sm);
  flex-direction: row;
  flex-wrap: wrap;
}

.info {
  display: grid;
  gap: var(--gap-md);
}
</style>
