export interface ArticlesStore {
  articles: Map<number, Article>;
}

export enum ArticleOptions {
  Label = 'label',
  Time = 'time',
}

export enum ArticleUnit {
  Time = 'time',
}

export enum ArticleGroup {
  Time = 'Timesatser',
  Utgifter = 'Utgifter',
}

export interface Article {
  articleNumber: string;
  description: string | null;
  id: number;
  name: string;
  options: Array<ArticleOptions>;
  pricePerUnit: number;
  productGroupName: string;
  sortOrder: number | null;
  unit: string;
  projectId: number | null;
  childArticleId: number | null;

  generatedExternally: boolean;

  isTime: boolean;
  // articleOptions.red red badge
  isLabel: boolean;
  // article unit = 'dag', purple badge
  isDay: boolean;
  // article category = 'utgifter' green badge
  isExpense: boolean;

  labels: Set<string>;
}
