<script setup lang="ts">
import { ref } from 'vue';
import { ListOpt } from '/@types/opt';
import { clearFilter, getFilterValue, toggleFilterValue } from '/@utilities/opt';
import { ProjectColumn } from '/@features/project/project.types';

const props = defineProps<{
  filterItems: ProjectColumn[];
  projectOpt: ListOpt;
}>();

type propKey = string | number;

const emit = defineEmits(['update', 'close']);

function getValue(propertyKey: propKey) {
  return getFilterValue(props.projectOpt.filters, propertyKey);
}

function replaceValue(propertyKey: propKey, value: any) {
  emit('update', toggleFilterValue(props.projectOpt.filters, propertyKey, value));
}

function clear(propertyKey: propKey) {
  emit('update', clearFilter(props.projectOpt.filters, propertyKey));
}

function formatValue(item: ProjectColumn) {
  const value = getValue(item.id);
  if (value.length === 0) return 'Alle';
  const items =
    typeof item.id === 'number'
      ? value
      : item?.filterList(item._resolvedDependencies).filter((i) => value.includes(i.value));

  return items.length === 0
    ? 'Alle'
    : `${items[0]?.label || items[0]}${items.length > 1 ? ` +${items.length - 1}` : ''}`;
}

type FilterListItem = {
  id: number | string;
  label: string;
  value: any;
  count: number;
};

const loadingList = ref(false);
const filterList = ref<FilterListItem[]>([]);
const showHidden = ref(false);

async function open(col: ProjectColumn) {
  loadingList.value = true;
  filterList.value = [];
  if (col.filterListCount && col.filterList) {
    const counts = await col.filterListCount(props.projectOpt, props.filterItems, col);
    const list = await col.filterList(col._resolvedDependencies);

    const isDynamic = typeof col.filterKey === 'number';
    const dynamic = (id) => (typeof col.filterKey === 'number' ? id : String(id));

    const mapCounts = new Map(counts.map((i) => [i.Value, i]));
    const mapList = new Map(list.map((i) => [isDynamic ? i.label : i.id, i]));

    filterList.value = [...mapList].map(([id, i]) => ({
      id: i.id,
      label: i.label,
      value: i.value,
      count: mapCounts.get(dynamic(id))?.ValueCount || 0,
    }));
  }
  loadingList.value = false;
}
</script>

<template>
  <template v-for="col in filterItems" :key="col.id">
    <slot name="col" :col="col">
      <df-select v-if="col.filterList" :label="col.label" v-on:open="open(col)">
        <template v-slot:text>
          {{ formatValue(col) }}
        </template>

        <df-dropdown2-item v-if="loadingList" disabled>
          Laster inn
          <df-loading no-float />
        </df-dropdown2-item>

        <template v-else>
          <df-dropdown2-item v-on:click="clear(col.id)">Alle</df-dropdown2-item>

          <template v-for="category in ['show', 'hidden']">
            <df-dropdown2-item
              v-if="category === 'hidden'"
              v-on:click="showHidden = !showHidden"
              no-close
            >
              Se tomme
              <template v-slot:icon><df-icon :code="showHidden ? 'f077' : 'f078'" /></template>
            </df-dropdown2-item>

            <df-dropdown2-item
              v-if="category === 'hidden' ? showHidden : true"
              v-for="entry in filterList.filter((i) => (category === 'show' ? i.count : !i.count))"
              :key="entry.id"
              v-on:click="replaceValue(col.id, typeof col.id === 'number' ? entry.label : entry.id)"
              :disabled="category === 'hidden'"
              no-close
            >
              <div class="flex-item">
                <df-icon
                  :code="
                    getValue(col.id)
                      .flat()
                      .includes(typeof col.id === 'number' ? entry.label : entry.id)
                      ? 'f14a'
                      : 'f0c8'
                  "
                />

                <div>{{ entry.label }}</div>
              </div>

              <template v-slot:icon>
                <div class="badge">{{ entry.count }}</div>
              </template>
            </df-dropdown2-item>
          </template>
        </template>
      </df-select>
    </slot>
  </template>
</template>

<style scoped>
.flex-item {
  display: flex;
  gap: var(--gap-md);
  align-items: center;
}
</style>
