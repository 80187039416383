<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { EventUpdate, Resource, ResourceEvent } from '/@types/resources';
import FullCalendar from '@fullcalendar/vue3';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import adaptivePlugin from '@fullcalendar/adaptive';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import nbLocale from '@fullcalendar/core/locales/nb';
import { createGuid } from '/@utilities/guid';
import { isNightTime, isSubEvent } from '/@utilities/resources';
import { setHours, getHours, getMinutes } from 'date-fns';
import { time } from '/@utilities/intl';

const views: { [viewId: string]: any } = {
  dfListWeek: {
    type: 'list',
    duration: { weeks: 1 },
    buttonText: 'Uke',
  },
  dfListDay: {
    type: 'list',
    duration: { days: 1 },
    buttonText: 'Dag',
  },
  dfResourceQuarter: {
    type: 'resourceTimeline',
    duration: { months: 4 },
    slotDuration: { days: 1 },
    slotLabelFormat: [{ week: 'long' }, { day: 'numeric' }],
    buttonText: 'Kvartal',
  },
  dfResourceMonth: {
    type: 'resourceTimeline',
    duration: { months: 1 },
    slotDuration: { days: 1 },
    slotLabelFormat: [{ week: 'long' }, { day: 'numeric' }],
    buttonText: 'Måned',
  },
  dfResourceWeekEaDay: {
    type: 'resourceTimelineWeek',
    duration: { weeks: 1 },
    slotDuration: { days: 1 },
    slotLabelFormat: [{ week: 'long' }, { weekday: 'long', month: 'numeric', day: 'numeric' }],
    buttonText: 'Uke (pr. dag)',
  },
  dfResourceWeekEaHour: {
    type: 'resourceTimelineWeek',
    duration: { weeks: 1 },
    slotDuration: { hours: 1 },
    slotLabelFormat: [{ weekday: 'long', month: 'numeric', day: 'numeric' }, { hour: '2-digit' }],
    buttonText: 'Uke (pr. time)',
  },
};

function createEventUpdate(event, revert: () => void): EventUpdate {
  return {
    id: event.id,
    title: event.title,
    start: event.allDay ? setHours(event.start, 8) : event.start,
    end: event.allDay ? setHours(event.end, 16) : event.end,
    data: event.extendedProps,
    resources: event.getResources().map((resource) => ({
      id: resource.id,
      title: resource.title,
      props: resource.extendedProps as Resource,
    })),
    revert,
  };
}

function mapResource(resource: Resource) {
  return {
    id: resource.guid,
    title: resource.name,
    sortIndex: resource.sortIndex,
    extendedProps: resource,
    ...(resource.subresources.length > 0
      ? { children: resource.subresources.map((res) => mapResource(res)) }
      : {}),
  };
}

function getCalenderColor(event: ResourceEvent): string {
  if (event.source === 'outlook') {
    return 'var(--color-event-outlook)';
  }

  if (!event.editable) {
    return 'var(--color-event-locked)';
  }

  if (event.projectColorHex != null) {
    return `#${event.projectColorHex}`;
  }

  if (isSubEvent(event)) {
    return 'var(--color-event-grouped)';
  }

  if (isNightTime(event)) {
    return 'var(--color-event-overtime)';
  }

  return 'var(--color-event)';
}

const props = withDefaults(
  defineProps<{
    view: string;
    events: ResourceEvent[];
    resources?: Resource[];
    buttons?: string;
    displayUser?: boolean;
  }>(),
  {
    view: 'dayGridWeek',
    resources: () => [],
  },
);

const emit = defineEmits([
  'date-change',
  'date-click',
  'event-add',
  'event-edit',
  'event-active',
  'day-click',
]);

const activeEvent = ref<ResourceEvent | null>(null);
const activeResource = ref<Resource | null>(null);

const groups = computed(() =>
  props.events.filter((event) => event.resourceType === 'group').map((event) => event.linkedByGuid),
);

const showWeekends = computed((): boolean => {
  const ls = localStorage.getItem('resourceWeekends');
  return ls ? JSON.parse(ls) : true;
});

const options = computed(() => ({
  plugins: [dayGridPlugin, listPlugin, interactionPlugin, resourceTimelinePlugin, adaptivePlugin],
  initialView: props.view,
  editable: true,
  droppable: true,
  nowIndicator: true,
  // NOTE: Disabling scrolltimereset prevents scroll reset every time options is recomputed (on new events, or editing group events), however it also disables scroll reset when changing view
  // Bug in fullcalendar: https://github.com/fullcalendar/fullcalendar-vue/issues/121
  scrollTimeReset: false,
  locale: nbLocale,
  height: '100%',
  schedulerLicenseKey: import.meta.env.VITE_FULLCALENDAR_LICENCE as string,
  resourceAreaWidth: '36ch',
  resourceOrder: '__nosort', // Makes fullcalender sort on a non existent prop so we can provide our own more advanced sort order
  weekends: showWeekends.value,

  headerToolbar: {
    left: 'today prev,next',
    // center: 'title',
    right: props.buttons,
  },

  views,

  events: props.events.map((event) => ({
    id: event.guid,
    resourceId: event.resourceGuid,
    extendedProps: event,
    title: event.projectName,
    start: event.fromDate,
    end: event.toDate,
    editable: event.editable,
    resourceEditable: event.editable,

    color: getCalenderColor(event),
    ...(isSubEvent(event)
      ? {
          editable: false,
          startEditable: false,
          durationEditable: false,
          resourceEditable: false,
        }
      : {}),
  })),

  resources: props.resources.map((resource) => mapResource(resource)),

  dateClick({ date, allDay, resource }) {
    emit('date-click', {
      date: allDay ? setHours(date, 8) : date,
      resource: resource?.extendedProps,
    });
  },

  eventClick(e) {
    const event = props.events.find(({ guid }) => e.event.id === guid) ?? null;
    if (event.clickable === false) return;
    activeEvent.value = event;
    emit('day-click', e.el.fcSeg.start);
  },

  eventReceive({ event, revert }) {
    event.setProp('id', createGuid());

    emit('event-add', createEventUpdate(event, revert));
  },

  eventDrop({ event, revert }) {
    emit('event-edit', createEventUpdate(event, revert));
  },

  eventResize({ event, revert }) {
    emit('event-edit', createEventUpdate(event, revert));
  },

  datesSet({ start, end }) {
    emit('date-change', { start, end });
  },

  resourceLabelContent({ resource }) {
    if (resource.extendedProps.type === 'group') {
      if (resource.extendedProps.groupType) {
        const text = document.createTextNode(resource.title);
        const badge = document.createElement('span');

        badge.textContent = resource.extendedProps.groupType;
        badge.className = 'badge';

        return { domNodes: [text, badge] };
      }

      return resource.title;
    }

    const text = document.createTextNode(resource.title);
    const button = document.createElement('button');

    button.addEventListener('click', () => {
      activeResource.value = resource.extendedProps as Resource;
    });
    button.textContent = '\uf05a';
    button.className = 'button fa-icon';

    return { domNodes: [button, text] };
  },

  resourceLaneClassNames({ resource }) {
    if (resource.extendedProps.type === 'group') return 'resource-lane--group';
    return '';
  },

  resourceLabelClassNames({ resource }) {
    if (resource.extendedProps.type === 'group') return 'resource-label--group';
    return '';
  },

  resourceAreaHeaderContent() {
    return 'Ressurser';
  },

  eventContent({ event, view }) {
    const text = document.createTextNode(event.title);
    const time = document.createElement('strong');
    let domNodes: Array<Node> = [time, text];

    if (!view.type.toLowerCase().includes('list')) {
      time.textContent = [String(getHours(event.start))]
        .concat(((t) => (t ? [String(t).padStart(2, '0')] : []))(getMinutes(event.start)))
        .join(':');
    }

    if (
      event.extendedProps.resourceType === 'group' ||
      isSubEvent(event.extendedProps as ResourceEvent)
    ) {
      const icon = document.createElement('span');
      const index = groups.value.findIndex((guid) => guid === event.extendedProps.linkedByGuid);

      icon.textContent = '\uf0c1';
      icon.className = 'event-icon fa-icon';
      icon.style.color = index >= 0 ? 'white' : 'var(--color-text)';
      icon.style.backgroundColor =
        index >= 0 ? `var(--color-${(index % 20) + 3})` : 'var(--color-border)';

      domNodes = [icon, ...domNodes];
    }

    if (props.displayUser && event.extendedProps.userName) {
      const div = document.createElement('div');

      div.textContent = event.extendedProps.userName;

      domNodes = domNodes.concat([div]);
    }

    return { domNodes };
  },

  eventDidMount({ el, event }) {
    el.title = `${event.title}\n${time(event.start)} - ${time(event.end)}\n\n${
      event.extendedProps.comment ?? ''
    }`;
    el.style.setProperty('--color', event.backgroundColor);
  },

  noEventsContent({ view }) {
    return `Ingen avtaler å vise ${view.title}`;
  },
}));

watch(activeEvent, (event) => {
  emit('event-active', event);
});

watch(
  () => props.events,
  (events) => {
    if (activeEvent.value != null) {
      const active = events.find((event) => event.guid === activeEvent.value.guid);
      activeEvent.value = active == null ? null : active;
    }
  },
);
</script>

<template>
  <div class="calendar">
    <full-calendar :options="options" :key="options.initialView" />

    <df-modal v-if="activeEvent != null">
      <slot
        name="event"
        :event="activeEvent"
        :close="() => (activeEvent = null)"
        :setActiveEvent="(event) => (activeEvent = event)"
      >
        <button v-on:click="activeEvent = null">Lukk</button>
      </slot>
    </df-modal>

    <df-modal v-if="activeResource != null">
      <slot name="resource" :resource="activeResource" :close="() => (activeResource = null)">
        <button v-on:click="activeResource = null">Lukk</button>
      </slot>
    </df-modal>
  </div>
</template>

<style scoped>
.calendar {
  --fc-border-color: var(--color-divider);
  --fc-neutral-bg-color: var(--color-bg);
  --fc-list-event-hover-bg-color: var(--color-hover);

  --fc-button-bg-color: var(--color-cardbg);
  --fc-button-text-color: var(--color-text);
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: var(--color-hover);
  --fc-button-hover-border-color: transparent;
  --fc-button-active-bg-color: var(--color-active);
  --fc-button-active-border-color: transparent;
  --fc-button-active-text-color: var(--color-text);
}

:deep(.button) {
  border-radius: var(--radius-sm);
  background-color: var(--color-cardbg);
  width: 1.5em;
  height: 1.5em;
  display: inline-grid;
  place-items: center;
  border: 0 solid transparent;
  box-shadow: var(--shadow-md);

  &:hover {
    background-color: var(--color-hover);
  }
}

:deep(.resource-lane--group) {
  background-color: var(--color-hover);
}

:deep(.resource-label--group) {
  background-color: var(--color-hover);
  font-weight: 500;
}

:deep(.event-icon) {
  border-radius: var(--radius-sm);
  font-size: 1em;
  padding: 0.75em;
}

:deep(:is(:not(.fc-button-group) > .fc-button, .fc-button-group)) {
  box-shadow: var(--shadow-md);
}

:deep(.fc-icon) {
  font-size: 1em !important;
  vertical-align: initial !important;

  &.fc-icon-chevron-left:before {
    content: '\f104';
  }

  &.fc-icon-chevron-right:before {
    content: '\f105';
  }

  &.fc-icon-minus-square:before {
    content: '\f107';
  }

  &.fc-icon-plus-square:before {
    content: '\f105';
  }
}

:deep(.fc-datagrid-cell-cushion) {
  padding: 0 var(--gap-sm);
}

:deep(.fc-timeline-event) {
  padding: 0;
  border-width: 0;
}

:deep(:is(.fc-event-main, .fc-datagrid-cell-main)) {
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
  gap: var(--gap-sm);
  align-items: center;
  padding: var(--gap-sm);

  &.fc-datagrid-cell-main {
    padding: var(--gap-md) var(--gap-sm);
  }
}

@media (max-width: 1000px) {
  :deep(.fc-list-event-title),
  :deep(.fc-list-event-time) {
    font-size: 0.85rem;
  }
}
</style>
