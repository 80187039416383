import { ref } from 'vue';
import { DefaultWorkingHours, EventUpdate, ResourceEvent } from '/@types/resources';
import resourcesStore from '/@stores/resources';
import { useUserStore } from '/@stores/user';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { EventForm } from '/@utilities/events';
import { createGuid } from '/@utilities/guid';

export default function useEvents() {
  const events = ref<Array<ResourceEvent>>([]);

  const tenantStore = useTenantStore();

  const defaultWorkingHours = ref<DefaultWorkingHours | void>({
    startTime: {
      hours: 8,
      minutes: 0,
    },
    endTime: {
      hours: 16,
      minutes: 0,
    },
  });

  const userStore = useUserStore();

  // mutates event - should find a better way to combine these

  function updateEventItems(guid: string) {
    resourcesStore.loadResourceEventsByGuid(guid).then((res) => {
      if (!res) return;
      Promise.all(
        res.linkedBookingGuids.map((g) => resourcesStore.loadResourceEventsByGuid(g)),
      ).then((ress) => {
        if (!ress) return;
        [...ress, res].forEach((r) => {
          if (!r) return;
          const index = events.value.findIndex((e) => e.guid === r.guid);
          index !== -1 ? (events.value[index] = r) : events.value.push(r);
          events.value = Array.from(events.value);
        });
      });
    });
  }

  function deleteEventItem(guid: string) {
    const event = events.value.find((i) => i.guid === guid);
    if (!event) return;
    [...event.linkedBookingGuids, event.guid].forEach((e) => {
      events.value.splice(
        events.value.findIndex((ee) => ee.guid === e),
        1,
      );
    });
  }

  function unlinkEventItem(guid: string) {
    const event = events.value.find((i) => i.guid === guid);
    if (!event) return;
    Promise.all(
      event.linkedBookingGuids.map((g) => resourcesStore.loadResourceEventsByGuid(g)),
    ).then((res) => {
      events.value.splice(
        events.value.findIndex((ee) => ee.guid === guid),
        1,
      );
      res.forEach((r) => {
        if (!r) return;
        const index = events.value.findIndex((e) => e.guid === r.guid);
        index !== -1 ? (events.value[index] = r) : events.value.push(r);
      });
    });
  }

  // creates or updates events based on various methods

  async function fromExisting(event: EventUpdate, create = false) {
    if (
      event.data.resourceType === 'group' &&
      !event.resources.some((r) => r.id === event.data.resourceGuid)
    ) {
      event.revert();
      throw {
        isCustom: true,
        message: 'Fellesavtale kan ikke flyttes utenfor gruppe uten å først koble fra avtalen.',
      };
    }

    function updateEvent(resourceEvent) {
      const e = events.value[events.value.findIndex((ii) => ii.guid === event.id)];
      e.editable = false;
      e.fromDate = event.start;
      e.toDate = event.end;

      const payload = {
        resourceGuid: resourceEvent.id,
        resourceType: resourceEvent.props.type,
        projectId: event.data.projectId,
        users: resourceEvent.props.subresources.map(({ guid }) => guid),
        fromDate: event.start,
        toDate: event.end,
        comment: event.data.comment ?? '',
      };

      return (
        create
          ? resourcesStore.createResourceEvent(event.id, payload)
          : resourcesStore.updateResourceEvent(event.id, payload)
      ).then(() => updateEventItems(event.id));
    }

    return Promise.all(event.resources.map((resource) => updateEvent(resource)));
  }

  function fromDrop(event: EventUpdate, create = false) {
    // Create the fromDate and toDate based on the default working hours
    const fromDate = new Date(event.start);
    fromDate.setHours(
      defaultWorkingHours.value.startTime.hours,
      defaultWorkingHours.value.startTime.minutes,
      0,
      0,
    );
    const toDate = new Date(event.end);
    toDate.setHours(
      defaultWorkingHours.value.endTime.hours,
      defaultWorkingHours.value.endTime.minutes,
      0,
      0,
    );

    function updateEvent(resourceEvent) {
      // return new Promise((res, rej) => rej());
      events.value.push({
        guid: event.id,
        projectId: event.data.projectId,
        projectName: event.data.projectName,
        projectTypeId: event.data.projectTypeId,
        projectModuleIds: [],
        projectColorHex: event.data.projectColorHex,
        projectAccess: false,
        resourceType: resourceEvent.props.type,
        resourceGuid: resourceEvent.id,
        linkedByGuid: null,
        mainEventGuid: null,
        userName: '',
        fromDate,
        toDate,
        comment: event.data.comment ?? '',
        createdAt: new Date(),
        createdByUserId: userStore.user.id,
        createdByUserName: userStore.user.name,
        updatedAt: new Date(),
        updatedByUserId: userStore.user.id,
        updatedByUserName: userStore.user.name,
        editable: false,
        linkedBookingGuids: [],
      });

      const payload = {
        resourceGuid: resourceEvent.id,
        resourceType: resourceEvent.props.type,
        projectId: event.data.projectId,
        users: resourceEvent.props.subresources.map(({ guid }) => guid),
        fromDate,
        toDate,
        comment: event.data.comment ?? '',
      };

      return (
        create
          ? resourcesStore.createResourceEvent(event.id, payload)
          : resourcesStore.updateResourceEvent(event.id, payload)
      )
        .then(() => {
          return updateEventItems(event.id);
        })
        .catch(() => {
          events.value.splice(
            events.value.findIndex((e) => e.guid === event.id),
            1,
          );
        });
    }

    return Promise.all(event.resources.map((resource) => updateEvent(resource)));
  }

  function fromForm(form: EventForm, create = false) {
    const payload = {
      guid: form.guid,
      resourceGuid: form.resourceGuid,
      resourceType: form.resourceType,
      users: form.users,
      fromDate: form.fromDate,
      toDate: form.toDate,
      projectId: form.projectId,
      comment: form.comment,
    };

    if (form.additionalUsers && form.additionalUsers.length > 0) {
      return Promise.all(
        form.additionalUsers.map((guid) => {
          const resourceGuid = createGuid();
          return resourcesStore
            .createResourceEvent(resourceGuid, { ...payload, resourceGuid: guid })
            .then(() => updateEventItems(resourceGuid));
        }),
      );
    }

    return (
      create
        ? resourcesStore.createResourceEvent(form.guid, payload)
        : resourcesStore.updateResourceEvent(form.guid, payload)
    ).then(() => updateEventItems(form.guid));
  }

  async function initialize() {
    await awaitTenant;

    defaultWorkingHours.value = tenantStore.getDefaultWorkingHours();
  }

  initialize();

  return {
    events,
    fromExisting,
    fromDrop,
    fromForm,
    updateEventItems,
    deleteEventItem,
    unlinkEventItem,
  };
}
