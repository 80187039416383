import { api, createQueryParams } from './api';

export function shorten(i: string, length = 10) {
  return i.length > length ? i.slice(0, length - 3) + '...' : i;
}

export function downloadExcel({ weekNo, year, caseworkerId }) {
  const baseUrl = null;
  const accessToken = null;
  const queries = createQueryParams(
    new Map([
      ['weekNo', weekNo],
      ['year', year],
      ['caseworkerUserId', caseworkerId || 0],
      ['onlyCaseworkerRegistrations', false],
    ]),
  );

  return api
    .get(`timer/registrations/caseworkers/${caseworkerId || 0}/excel?${queries}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/xlsx',
      },
      responseType: 'blob',
    })
    .then(({ data: blob }) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Timeliste`;
      a.click();
    });
}

export function isInViewport(element) {
  const bounding = element.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export function search(query: string, target: string) {
  return query ? target.toLocaleLowerCase().includes(query.toLocaleLowerCase()) : true;
}

export function mergeMap(map1: Map<any, any>, map2: Map<any, any>): Map<any, any> {
  return new Map([...map1, ...map2]);
}

export function diag(i) {
  console.log(i);

  return i;
}
