<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useArticlesStore } from '/@stores/articles';
import { useTimerStore } from '/@stores/timer';
import { useActivitiesStore } from '/@stores/timer-activities';
import { awaitUser, useUserStore } from '/@stores/user';
import { RegistrationStatusIds } from '/@types/ids';
import { RegistrationForm } from '/@types/timer';
import { dateToString, formatHours, formatMsToNumUp, getDiffInMs } from '/@utilities/date';
import { date, time } from '/@utilities/intl';
import { toast } from '/@utilities/toast';
import { populateRegistrationForm, useRegistrations, maskHours } from '/@utilities/registrations';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { useAlert } from '/@elements/DfAlert/DfAlert.vue';
import { useProjectStore } from '/@features/project';
import { debounce } from 'lodash-es';
import { RolePermission } from '/@types/user';
import { Return } from '/@types/helpers';
import TypeBadge from '/@elements/TypeBadge.vue';
import ActivityItem from './ActivityItem.vue';
import { useStorage } from '@vueuse/core';

function check(form: RegistrationForm) {
  const from = form.fromDateTime?.split(':').join('');
  const to = form.toDateTime?.split(':').join('');
  if (!from || !to) return false;
  return from > to;
}

const props = defineProps<{
  projectId?: number;
  userId?: number;
  createCopy?: boolean;
  selectUser?: boolean;
}>();

const activitiesStore = useActivitiesStore();
const articlesStore = useArticlesStore();
const projectStore = useProjectStore();
const tenantStore = useTenantStore();
const timerStore = useTimerStore();

const { configureDay } = useRegistrations();

const loading = ref(false);
const loadingArticles = ref(false);
const route = useRoute();
const router = useRouter();

const alert = useAlert();

const storedHoursCount = ref(0);

const today = new Date();

type SuggestedProject = {
  projectId: number;
  projectName: string;
  articleId: number;
  articleName: string;
};

const suggestedProjects = useStorage<SuggestedProject[]>('SuggestedProjects', []);

function updateSuggestedProject(suggested: SuggestedProject) {
  const alreadyExist = suggestedProjects.value.some(
    (s) => s.projectId === suggested.projectId && s.articleId === suggested.articleId,
  );

  if (alreadyExist) return;
  if (suggestedProjects.value.length === 2) {
    suggestedProjects.value.splice(0, 1);
  }
  suggestedProjects.value.push(suggested);
}

const isEdit = computed(() => registrationId && !props.createCopy);

const isCorrection = computed(() => {
  if (!registration.value || props.createCopy) return false;
  return registration.value.statusId >= RegistrationStatusIds.Approved;
});

const correctionType = computed(() => {
  return isCorrection.value && tenantStore.tenant?.elsmartIntegration ? 'elsmart' : null;
});

const uiText = computed(() => {
  return {
    title: isCorrection.value ? 'Korriger' : isEdit.value ? 'Rediger' : 'Opprett',
  };
});

const updatedRemainingHours = computed(() => {
  const dayData = configureDay(
    form.value.date,
    timerStore.userRegistrations,
    timerStore.finishedWeek,
  );

  const remainingHours = Number(String(dayData.remainingCount).replace(',', '.'));

  if (form.value.hours === null || article.value?.isExpense) return remainingHours;
  if (isEdit.value) return remainingHours + storedHoursCount.value - form.value.hours;
  return remainingHours - form.value.hours;
});

const form = ref<RegistrationForm>({
  date: route.query.date ? new Date(String(route.query.date)) : today,
  hours: null,
  articleId: null,
  projectId: props.projectId,
  description: '',
  fromDateTime: null,
  toDateTime: null,
  activities: [],
  userId: null,
  articleIdCopy: null,
});

watch(
  () => form.value.articleId,
  () => {
    // manage from - to time
    if (article.value && article.value.isTime) {
      form.value.fromDateTime = '08:00';
    } else {
      form.value.fromDateTime = null;
      form.value.toDateTime = null;
    }

    // reset copy
    form.value.articleIdCopy = null;
  },
);

watch(
  () => form.value.projectId,
  (val) => {
    loadingArticles.value = true;
    if (!val) return;

    setupArticle(val);
    setupActivity(val);
  },
);

watch(
  () => form.value.activities,
  (val) => {
    if (form.value.activities.length === 0) return;
    form.value.hours = form.value.activities.reduce((acc, cv) => acc + cv.hours, 0);
  },
  { deep: true },
);

watch(
  () => [form.value.fromDateTime, form.value.toDateTime],
  debounce(() => {
    if (!form.value.fromDateTime || !form.value.toDateTime) return;
    form.value.hours = formatMsToNumUp(getDiffInMs(form.value.fromDateTime, form.value.toDateTime));
  }, 500),
);

const article = computed(() => filteredArticles.value.get(Number(form.value.articleId)));

const validationRules = computed(() => {
  const rules = [
    [!form.value.projectId, 'Mangler prosjekt'],
    [!form.value.articleId, 'Mangler artikkel'],
    [form.value.hours == null, 'Antall timer må være ført'],
    [!isCorrection.value && form.value.hours === 0, 'Ugyldig antall timer'],
    [!form.value.date, 'Mangler dato'],
  ];

  if (article.value && article.value.isTime) {
    if (form.value.activities.length > 0) {
      rules.push(
        [!form.value.activities.every((a) => a.fromDateTime), 'Mangler fra-tid'],
        [!form.value.activities.every((a) => a.toDateTime), 'Mangler til-tid'],
      );
    } else {
      rules.push(
        [!form.value.fromDateTime, 'Mangler fra-tid'],
        [!form.value.toDateTime, 'Mangler til-tid'],
        [check(form.value), 'Til tid må være senere enn fra tid'],
      );
    }
  }

  if (form.value.activities.length > 0) {
    rules.push(
      [!form.value.activities.every((a) => a.id), 'Mangler aktivitet'],
      [!form.value.activities.every((a) => a.hours), 'Mangler tid på aktivitet'],
      [
        !form.value.activities.every((a) => a.description?.trim()),
        'Mangler beskrivelse på aktivitet',
      ],
    );
  } else {
    rules.push([!form.value.description.trim(), 'Mangler kommentar']);
  }

  if (props.selectUser) {
    rules.splice(0, 0, [!form.value.userId, 'Mangler bruker']);
  }

  return rules;
});

const goToCopy = ref(false);

const formData = computed(() => {
  const formValues = form.value;
  const formValidation = validationRules.value;

  if (correctionType.value === 'elsmart') {
    return {
      formValues,
      formValidation,
      submit: () => timerStore.createCorrection(registrationId),
      onSuccess: async (res: any) => {
        toast('Korrigering opprettet');
        if (goToCopy.value) {
          await router.push({ name: route.name, query: { createCopy: 'true' } });
          window.location.reload();
        } else {
          router.back();
        }
      },
      onError: (res: any) => {
        return 'Ukjent feil: Korrigering ble ikke opprettet';
      },
    };
  }

  return !registrationId || isCorrection.value || props.createCopy
    ? {
        formValues,
        formValidation,
        submit: () => timerStore.createRegistration(form.value),
        onSuccess: (res: any) => {
          const project = userProjects.value.get(Number(form.value.projectId));
          const article = filteredArticles.value.get(Number(form.value.articleId));

          if (project && article) {
            updateSuggestedProject({
              projectId: project.id,
              projectName: project.name,
              articleId: article.id,
              articleName: article.name,
            });
          }

          form.value.hours = 0;
          form.value.articleId = null;
          form.value.projectId = null;
          form.value.description = '';
          form.value.fromDateTime = null;
          form.value.toDateTime = null;

          router.back();

          toast('Registrering opprettet', {
            actions: [
              {
                label: 'Lag kopi',
                onClick: () => {
                  router.push({
                    path: `/timer/registration/${res.data}/edit`,
                    query: {
                      createCopy: 'true',
                    },
                  });
                },
              },
            ],
          });
        },
        onError: ({ response }: any) => {
          if (response?.status !== 400) return 'Uventet feil.';
          if (response?.data.errors) return 'Et eller flere valideringer feilet.';
          if (response?.data.includes(`older than '14' days`))
            return 'Kan ikke opprette registrering eldre enn 14 dager.';
          if (response?.data.includes(`project was locked`)) {
            const regexProjectLockedDate = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/; // pattern: digitx4, dash, digitx2, dash, digitx2, T, digitx2, colon, digitx2
            const projectLockedDate = new Date(response.data.match(regexProjectLockedDate));

            return `Prosjektet ble låst for timeregistrering
            ${date(projectLockedDate)}
            kl.
            ${time(projectLockedDate)}.`;
          }

          return `Uventet feil: Registering feilet.`;
        },
      }
    : {
        formValues,
        formValidation,
        submit: () => timerStore.updateRegistration(registrationId, form.value),
        onSuccess: () => {
          toast('Registrering oppdatert');
          router.back();
        },
        onError: ({ response }) => {
          if (response.data.includes('outside bound of allowed modification')) {
            return 'Kan ikke redigere registrering eldre enn 14 dager.';
          }

          return response.data;
        },
      };
});

const hourSuggestions = ref([2.5, 4, 7.5]);

const filteredArticles = computed(() => {
  return form.value.projectId && articlesStore.articles.size > 0
    ? articlesStore.articles
    : articlesStore.allArticles;
});

const filteredActivities = computed(() => activitiesStore.activities);

const registrationId = Number(route.params.registrationId);

const registration = computed(() => timerStore.registrations.get(registrationId));

const canDelete = computed(() => {
  const permission =
    registration.value?.userId === userStore.user?.id ||
    userStore.hasRolePerm(RolePermission.RegistrationDelete);

  const openRegistration =
    registration.value?.statusId === RegistrationStatusIds.Created && !props.createCopy;

  return permission && openRegistration;
});

function deleteRegistrationAction() {
  timerStore.deleteRegistration(registrationId).then(() => {
    router.go(-2);
    toast('Registrering slettet');
  });
}

const userStore = useUserStore();

const userProjects = ref<Return<typeof projectStore.loadProjectsByUser>>(new Map());

const projectsFiltered = computed(() => {
  return new Map(
    [...userProjects.value]
      .filter(([id, p]) => !p.registrationsLocked)
      .sort((a, b) =>
        a[1].hasUserFilter === b[1].hasUserFilter ? 0 : a[1].hasUserFilter ? -1 : 1,
      ),
  );
});

function selectSuggestedProject(suggestion: SuggestedProject) {
  const { projectId, projectName, articleId } = suggestion;

  if (!projectsFiltered.value.has(projectId)) {
    alert.write(`Prosjekt ${projectName} er utilgjengelig for timeregistrering.`);
  } else {
    form.value.projectId = projectId;
    form.value.articleId = articleId;
  }
}

function addActivity() {
  form.value.activities.push({ id: null, hours: 0, description: '' });
}

function toggleActivitiy(index?: number) {
  if (isCorrection.value) return;
  if (filteredActivities.value.size === 0) return;

  if (isNaN(Number(index))) {
    if (form.value.activities.length === 0) {
      form.value.activities.push({ id: null, hours: 0, description: '' });
    }
  } else {
    form.value.activities.splice(Number(index), 1);
  }
}

const activityError = ref(false);

async function setupActivity(projectId: number) {
  await activitiesStore.loadActivities(projectId);

  if (!registrationId) {
    form.value.activities = [];
    toggleActivitiy();
    return;
  }

  if (form.value.activities.length === 0) {
    if (filteredActivities.value.size === 0) return;
    form.value.activities.push({
      id: null,
      hours: form.value.hours || 0,
      description: form.value.description,
    });
    return;
  }

  form.value.activities.forEach((a) => {
    if (!filteredActivities.value.has(Number(a.id))) {
      activityError.value = true;
      form.value.activities.splice(form.value.activities.indexOf(a), 1);
    }
  });

  toggleActivitiy();
}

const iUserId = computed(() => form.value.userId || userStore.user?.id);

async function setupArticle(projectId: number) {
  await articlesStore.loadProjectArticles(Number(iUserId.value), projectId);
  loadingArticles.value = false;
  if (isCorrection.value) return;
  if (!filteredArticles.value.has(Number(form.value.articleId))) {
    form.value.articleId = null;
  }
}

function setupProjects() {
  return projectStore.loadProjectsByUser(Number(iUserId.value)).then((projects) => {
    if (projects) userProjects.value = projects;
    if (props.projectId && !userProjects.value.has(props.projectId)) {
      alert.write(
        'Fant ikke prosjektet. Dette kan være fordi din bruker ikke er tilknyttet prosjektet. Kontakt prosjektleder hvis dette er feil.',
      );
      form.value.projectId = null;
    }
  });
}

function resetData() {
  form.value.projectId = null;
  form.value.articleId = null;
  form.value.fromDateTime = null;
  form.value.toDateTime = null;
  setupProjects();
}

async function load() {
  loading.value = true;

  await awaitUser;
  await awaitTenant;

  if (registrationId) {
    await timerStore.loadRegistration(registrationId);
    if (!registration.value) return;
    form.value = populateRegistrationForm({
      ...registration.value,
      hours: isCorrection.value ? registration.value.hours * -1 : registration.value.hours,
    });
    if (form.value.hours === null) return;
    storedHoursCount.value = form.value.hours;
  }

  Promise.all([
    setupProjects(),
    articlesStore.loadAllArticles(),
    tenantStore.loadUsers(),
    timerStore.loadRegistrations({
      fromDate: dateToString(form.value.date),
      toDate: dateToString(form.value.date),
    }),
  ])
    .then(async () => {
      if (props.projectId) {
        await Promise.all([
          projectStore.loadProjectById(props.projectId),
          setupArticle(props.projectId),
          setupActivity(props.projectId),
        ]);
      }
    })
    .catch(() => {
      alert.write(`En ukjent feil oppstod`);
    })
    .finally(() => {
      loading.value = false;
    });
}

load();

const articleSelected = computed(() => articlesStore.allArticles.get(Number(form.value.articleId)));

watch(articleSelected, () => {
  if (articleSelected.value?.isDay) form.value.hours = 1;
});

const articleCopy = computed(
  () => articlesStore.allArticles.get(Number(articleSelected.value?.childArticleId))?.name,
);
</script>

<template>
  <div class="page">
    <df-header back>
      <template v-slot:default>{{ uiText.title }} registrering</template>

      <template v-slot:subtitle>{{ date(form.date) }}</template>

      <template v-slot:actions>
        <df-confirm-modal v-if="canDelete" variant="danger">
          <template v-slot:trigger="{ toggle }">
            <df-button danger v-on:click="toggle()">
              Slett
              <template v-slot:icon>
                <df-icon code="f2ed" />
              </template>
            </df-button>
          </template>

          <template v-slot:text>
            Ved sletting av registrering vil den ikke være mulig å hente tilbake igjen.
          </template>

          <template v-slot:confirm="{ confirm }">
            <df-button
              v-on:click="
                deleteRegistrationAction();
                confirm();
              "
              danger
              elevate
            >
              Slett registrering
            </df-button>
          </template>
        </df-confirm-modal>
      </template>
    </df-header>

    <df-loading v-if="loading" />

    <df-alert v-model="alert" />

    <df-alert v-if="isCorrection" type="warning" persistent>
      <template v-if="correctionType === 'elsmart'">
        <p>
          Du kan her nullstille den opprinnelige timeregistreringen totalt, ved å velge 'Lagre'.
          Dersom det skal føres nytt antall timer på det samme prosjektet, velger du 'Lagre og
          kopier til ny".
        </p>
      </template>

      <template v-else>
        <p>Korrigering: Registrering er overført til eksternt system.</p>
        <p>Ved lagring vil det opprettes en ny registrering for å korrige forrige.</p>
      </template>
    </df-alert>

    <df-alert v-if="createCopy" type="info" persistent>
      <p>Lagring oppretter ny registrering</p>
    </df-alert>

    <df-alert v-if="selectUser" type="warning" persistent>
      <p>Opprett registrering på bruker</p>
    </df-alert>

    <section>
      <df-form
        :submit="formData.submit"
        :form-values="formData.formValues"
        :form-validation="formData.formValidation"
        :on-success="formData.onSuccess"
        :on-error="formData.onError"
      >
        <div
          class="project-suggestions"
          v-if="suggestedProjects.length > 0 && !registrationId && !selectUser"
        >
          <div class="title-sm">Forslag</div>

          <button
            class="project-suggestions__item"
            type="button"
            v-for="(suggestion, index) in suggestedProjects"
            :key="index"
            v-on:click="selectSuggestedProject(suggestion)"
          >
            <div style="font-weight: bold">{{ suggestion.projectName }}</div>
            <div>{{ suggestion.articleName }}</div>
          </button>
        </div>

        <df-select3
          v-if="selectUser"
          v-model="form.userId"
          v-on:update:modelValue="resetData"
          label="Bruker"
          :entries="tenantStore.users"
          display="name"
          elevate
        />

        <df-datepicker v-if="!isCorrection" label="Dato" v-model="form.date" required elevate />

        <df-select3
          v-model="form.projectId"
          label="Prosjekt"
          :entries="projectsFiltered"
          display="name"
          :use-sort="false"
          :disabled="isCorrection"
          elevate
        />

        <df-select3
          v-model="form.articleId"
          label="Artikkel"
          :entries-list="articlesStore.allArticles"
          :entries="filteredArticles"
          display="name"
          :disabled="!form.projectId || isCorrection"
          :filter-items="['Normaltid', 'Overtid', 'Utgift', 'Fravær']"
          elevate
        >
          <template v-slot:extra="{ entry }">
            <TypeBadge v-if="entry.labels.size > 0" :types="entry.labels" />
          </template>

          <template v-slot:icon v-if="loadingArticles">
            <df-loading no-float />
          </template>
        </df-select3>

        <df-alert v-if="!isCorrection" type="info" persistent>
          Gjenstående normal arbeidstid:
          <strong>{{
            `${maskHours(updatedRemainingHours)} time${
              updatedRemainingHours === 1 ? '' : updatedRemainingHours === -1 ? '' : 'r'
            }`
          }}</strong>
        </df-alert>

        <df-checkbox
          v-if="articleSelected?.childArticleId && !isCorrection"
          :model-value="!!form.articleIdCopy"
          v-on:update="
            form.articleIdCopy = form.articleIdCopy ? null : articleSelected.childArticleId
          "
          :value="articleSelected.childArticleId"
          style="justify-self: flex-start"
          no-border
        >
          Opprett lik registrering på <span class="text--bold">{{ articleCopy }}</span>
        </df-checkbox>

        <df-alert v-if="activityError" show persistent>
          Registrering hadde aktivitet som nå ikke finnes på prosjekt. Denne er fjernet.
        </df-alert>

        <div class="activities" v-if="filteredActivities.size > 0">
          <div class="activities-entries">
            <div class="title-sm">Aktiviteter</div>

            <div class="title-sm" style="grid-column: span 2">Timer</div>

            <activity-item
              v-for="(activity, index) in form.activities"
              :key="index"
              v-model="form.activities[index]"
              :index
              :require-from-to-date="article?.isTime"
              :is-correction="isCorrection"
              :can-remove="form.activities.length > 1"
              v-on:remove="toggleActivitiy($event)"
            >
              <template v-slot:default>
                <div
                  class="hour-suggestions"
                  v-if="!articleSelected?.isDay && !articleSelected?.isTime"
                >
                  <div
                    class="pill"
                    v-for="hour in hourSuggestions"
                    :key="hour"
                    v-on:click="form.activities[index].hours = hour"
                  >
                    {{ formatHours(hour) }}
                  </div>
                </div>
              </template>
            </activity-item>
          </div>

          <df-button
            v-if="registrationId ? form.activities.length < 1 : true"
            v-on:click="addActivity()"
            style="justify-self: end"
          >
            Legg til aktivitet
          </df-button>

          <div class="timer-count">
            <div class="title">Antall timer</div>
            <div>{{ maskHours(form.hours) }}</div>
          </div>
        </div>

        <template v-else>
          <div class="input-group" v-if="article && article.isTime">
            <div class="small">Artikkel krever fra- til-tid</div>

            <df-time label="Fra" v-model="form.fromDateTime" elevate />

            <df-time label="Til" v-model="form.toDateTime" elevate />
          </div>

          <df-counter
            :step="0.5"
            :min-value="isCorrection ? null : 0"
            :label="`Antall ${articleSelected?.isDay ? 'dager' : 'timer'}`"
            :disabled="articleSelected?.isDay || correctionType === 'elsmart'"
            v-model="form.hours"
            elevate
          />

          <div
            class="hour-suggestions"
            v-if="
              !articleSelected?.isDay && !articleSelected?.isTime && correctionType !== 'elsmart'
            "
          >
            <div
              class="pill"
              v-for="hour in hourSuggestions"
              :key="hour"
              v-on:click="form.hours = hour"
            >
              {{ formatHours(hour) }}
            </div>
          </div>
        </template>

        <df-input
          v-if="filteredActivities.size === 0"
          label="Kommentar"
          v-model="form.description"
          multiline
          :rows="1"
          :disabled="isCorrection"
          elevate
        />

        <div class="emphasis">Alle felter må fylles ut</div>

        <template v-slot:buttons="{ disabled }" v-if="correctionType === 'elsmart'">
          <df-button v-on:click="goToCopy = true" type="submit" elevate :disabled>
            Lagre og kopiér til ny
          </df-button>
        </template>
      </df-form>
    </section>
  </div>
</template>

<style scoped>
.input-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-md);
}

.small {
  font-size: 0.8rem;
  grid-column: span 2;
}

.project-suggestions {
  display: grid;
  gap: var(--gap-md);
  color: var(--color-info_text);
  background-color: var(--color-info_bg);
  padding: var(--gap-md);
  margin: 0 calc(var(--gap-md) * -1);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.project-suggestions__item {
  border: 1px solid var(--color-info_text);
  padding: var(--gap-sm);
  font-size: 0.8rem;
  cursor: default;
  border-radius: var(--radius-sm);
  color: var(--color-info_text);
  background-color: inherit;
  text-align: inherit;
}

.project-suggestions__item:hover {
  background-color: var(--color-info_text);
  border-color: var(--color-info_text);
  color: var(--color-info_bg);
}

.hour-suggestions {
  display: flex;
  gap: var(--gap-md);
}

.title {
  font-size: 0.8rem;
}

.activities {
  display: grid;
  gap: var(--gap-md);
}

.timer-count {
  display: grid;
  grid-template-columns: 1fr max-content;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);
  padding: var(--gap-md);
  font-weight: bold;
  align-items: center;
}

.activities-entries {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  gap: var(--gap-md);
}

.emphasis {
  font-style: italic;
  font-size: 0.9rem;
}
</style>
