import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

function determineEnvironment() {
  const href = location.href;
  if (href.includes('localhost')) return 'local';
  if (href.includes('vercel.app')) return 'feature';
  if (href.includes('test')) return 'test';
  if (href.includes('uat')) return 'uat';
  return 'production';
}

export function useSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: 'https://8a1d71dd3417981b7f39c4a4666975ac@o4507056448143360.ingest.de.sentry.io/4507191401316432',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    trackComponents: true,

    environment: determineEnvironment(),

    beforeSend: function (event, hint) {
      if (event.extra) {
        event.fingerprint = [event.extra.requestMethod, event.extra.error?.status];
      }

      const { response, message } = hint.originalException;

      if (
        response?.status === 401 ||
        response?.status === 400 ||
        message?.includes('Failed to fetch dynamically imported module') ||
        message?.includes('Network Error') ||
        message?.includes('Fetch is aborted') ||
        message?.includes('canceled')
      ) {
        return null;
      }

      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dokflyt\.no\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function sendError(error: any, extra?: Object) {
  Sentry.captureException(error, { extra });
}
