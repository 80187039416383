import {
  DefaultWorkingHours,
  Resource,
  ResourceEvent,
  ResourceFilter,
  ResourceGroup,
  ResourceType,
} from '/@types/resources';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';
import { compare } from '/@utilities/intl';
import { toast } from '/@utilities/toast';

function mapResource(res: any, { filterId = null } = {}): Resource {
  return {
    guid: res.Guid,
    type: (res.Type ?? 'user').toLowerCase(),
    name: res.Name,
    color: res.Color,
    sortIndex:
      res.SortIndex ??
      (filterId != null ? res.Filters.find(({ Id }) => Id === filterId)?.sortIndex : null),
    groupType: res.GroupType,
    filters: res.Filters.map(({ Id, SortNumber, Name }) => ({
      id: Id,
      sortIndex: SortNumber,
      name: Name,
    })),
    groupIds: res.Groups == null ? [] : res.Groups.map(({ Id }) => Id),
    subresources:
      res.Resources == null
        ? []
        : res.Resources.map((res) => mapResource(res)).sort((a, b) => a.sortIndex - b.sortIndex),
  };
}

function mapResourceEvent(res: any): ResourceEvent {
  return {
    guid: res.Guid,
    resourceGuid: res.ResourceGuid,
    resourceType: res.ResourceType.toLowerCase(),
    linkedByGuid: res.LinkedByGuid,
    mainEventGuid: res.MainBookingGuid,
    projectId: res.ProjectId,
    projectName: res.ProjectName,
    projectTypeId: res.ProjectTypeId ?? -1,
    projectModuleIds: res.ProjectTypeModuleIds,
    projectColorHex: res.ProjectColorHex,
    projectAccess: res.HasAccessToProject,
    fromDate: new Date(res.FromDate),
    toDate: new Date(res.ToDate),
    comment: res.Comment,
    userName: res.BookedUserFullName,
    createdByUserId: res.CreatedByUserId,
    createdByUserName: res.CreatedByFullName,
    createdByUserEmployeeNo: res.BookedUserEmployeeNumber,
    createdAt: res.CreatedAt,
    updatedByUserId: res.UpdatedByUserId,
    updatedByUserName: res.UpdatedByFullName,
    updatedAt: res.UpdatedAt,
    editable: true,
    linkedBookingGuids: res.LinkedBookings?.map((l) => l.Guid) || [],
  };
}

function mapResourceFilter(res: any): ResourceFilter {
  return {
    id: res.Id,
    name: res.Name,
    type: res.FilterType,
    isUserSpecific: res.IsUserSpecific,
    resources: res.FilterUsers.map((user) => ({
      guid: user.Guid,
      name: user.Name,
      sortIndex: user.SortNumber,
    })).sort((a, b) => a.sortIndex - b.sortIndex),
  };
}

function mapResourceGroup(res: any): ResourceGroup {
  return {
    id: res.Id,
    resourceGuid: res.ResourceGuid,
    name: res.Name,
    type: res.GroupType,
    filterId: res.ResourceFilterId,
    resources: res.GroupUsers.map((user) => ({
      guid: user.Guid,
      name: user.Name,
      sortIndex: user.SortNumber,
    })).sort((a, b) => a.sortIndex - b.sortIndex),
  };
}

export function loadResourceEvents(q) {
  const queries = createQueryParams(
    new Map([
      ['fromDate', q.dateRange.start.toISOString()],
      ['toDate', q.dateRange.end.toISOString()],
      ...q.filters.map((i) => [i.columnId, i.values]),
    ]),
  );

  return api
    .get(`/ressurs/bookings?${queries}`)
    .then(({ data }) => {
      return data.map(mapResourceEvent);
    })
    .catch(genericApiErrorHandler);
}

export function loadResourceUsers(q) {
  const queries = createQueryParams(
    new Map([
      ['fromDate', q.dateRange.start.toISOString()],
      ['toDate', q.dateRange.end.toISOString()],
      ...q.filters.map((i) => [i.columnId, i.values]),
    ]),
  );

  return api
    .get(`/ressurs/resources/users?${queries}`)
    .then(({ data }) => {
      return data.map((res) => mapResource(res));
    })
    .catch(genericApiErrorHandler);
}

export function loadResources({ filterId = null, ungrouped = false } = {}) {
  const query = new URLSearchParams();

  if (filterId != null) {
    query.append('filterid', filterId);
  }

  return api
    .get(`${ungrouped ? '/ressurs/resources/users' : '/ressurs/resources'}?${query}`)
    .then(({ data }): Array<Resource> => data.map((res) => mapResource(res, { filterId })))
    .catch(genericApiErrorHandler);
}

export function loadResourceEventsByGuid(eventGuid: string): Promise<ResourceEvent> {
  return api
    .get(`/ressurs/bookings/${eventGuid}`)
    .then(({ data }) => mapResourceEvent(data))
    .catch(genericApiErrorHandler);
}

export function loadResourceEventsByDate(from: Date, to: Date, filterId: number | null = null) {
  const query = new URLSearchParams({
    fromDate: from.toISOString(),
    toDate: to.toISOString(),
  });

  if (filterId != null) {
    query.append('filterid', filterId);
  }

  return api
    .get(`/ressurs/bookings/bydaterange?${query}`)
    .then(({ data }): Array<ResourceEvent> => data.map(mapResourceEvent))
    .catch(genericApiErrorHandler);
}

export function loadResourceEventsByDateForCurrentUser(
  from: Date,
  to: Date,
  { filterId = null } = {},
) {
  const query = new URLSearchParams({
    fromDate: from.toISOString(),
    toDate: to.toISOString(),
  });

  if (filterId != null) {
    query.append('filterid', filterId);
  }

  return api
    .get(`/ressurs/bookings/bydaterangeforuser?${query}`)
    .then(({ data }) => data.map(mapResourceEvent))
    .catch(genericApiErrorHandler);
}

export function loadResourceEventsBySearch({
  projectName,
  userName,
}: {
  projectName: string;
  userName: string;
}): Promise<ResourceEvent[]> {
  const query = new URLSearchParams({
    searchProjectName: projectName,
    searchUserFullName: userName,
  });

  return api
    .get(`/ressurs/bookings/search?${query}`)
    .then(({ data }) => data.map(mapResourceEvent));
}

interface ResourceEventOptions {
  resourceGuid: string;
  resourceType: ResourceType;
  projectId: number;
  fromDate: Date;
  toDate: Date;
  comment: string;
  users: Array<string>;
}

function resourceTypeToServerType(type: ResourceType): string {
  switch (type) {
    case 'group':
      return 'Group';
    case 'user':
      return 'User';
  }
}

export function createResourceEvent(guid: string, options: ResourceEventOptions) {
  return api
    .post('/ressurs/bookings', {
      BookingGuid: guid,
      ResourceGuid: options.resourceGuid,
      ResourceType: resourceTypeToServerType(options.resourceType),
      GroupUserGuids: options.users,
      ProjectId: options.projectId,
      Comment: options.comment,
      fromDate: options.fromDate.toISOString(),
      toDate: options.toDate != null ? options.toDate.toISOString() : null,
    })
    .catch(genericApiErrorHandler);
}

export function updateResourceEvent(guid: string, options: ResourceEventOptions) {
  return api
    .put(`/ressurs/bookings/${guid}`, {
      BookingGuid: guid,
      ResourceGuid: options.resourceGuid,
      ResourceType: resourceTypeToServerType(options.resourceType),
      GroupUserGuids: options.users,
      ProjectId: options.projectId,
      Comment: options.comment,
      fromDate: options.fromDate.toISOString(),
      toDate: options.toDate != null ? options.toDate.toISOString() : null,
    })
    .catch(genericApiErrorHandler);
}

export function deleteResourceEvent(guid: string) {
  return api.delete(`/ressurs/bookings/${guid}`).catch(genericApiErrorHandler);
}

export function unlinkResourceEvent(guid: string) {
  return api.delete(`/ressurs/bookings/${guid}/unlink`).catch(genericApiErrorHandler);
}

export function loadResourceFilter() {
  return api
    .get('/ressurs/filters')
    .then(
      ({ data }): Array<ResourceFilter> =>
        data.sort((a, b) => compare(a.Name, b.Name)).map((res) => mapResourceFilter(res)),
    )
    .catch(genericApiErrorHandler);
}

export function createResourceFilter(
  name: string,
  { isUserSpecific }: { isUserSpecific: boolean },
) {
  return api
    .post(isUserSpecific ? '/ressurs/filters?isuserspecific=true' : '/ressurs/filters', {
      Name: name,
      Users: [],
    })
    .then(({ data }) => api.get(`/ressurs/filters/${data}`))
    .then(({ data }) => mapResourceFilter(data))
    .catch(genericApiErrorHandler);
}

export function updateResourceFilter(
  filterId: number,
  { name, type }: { name: string; type: string },
) {
  return api
    .put(`/ressurs/filters/${filterId}`, {
      Name: name,
      FilterType: type,
    })
    .catch(genericApiErrorHandler);
}

export function sortResourceFilter(filterId: number, sortedResourceGuids: Array<string>) {
  return api
    .put(`/ressurs/filters/${filterId}/sort`, {
      ResourceGuids: sortedResourceGuids,
    })
    .catch(genericApiErrorHandler);
}

export function removeResourceFilter(filterId: number) {
  return api.delete(`/ressurs/filters/${filterId}`).catch(genericApiErrorHandler);
}

export function addResourceToFilter(filterId: number, resourceGuid: string) {
  return api
    .post(
      `/ressurs/filters/${filterId}/resources/add`,
      {
        ResourceGuid: resourceGuid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .catch(genericApiErrorHandler);
}

export function removeResourceFromFilter(filterId: number, resourceGuid: string) {
  return api
    .delete(`/ressurs/filters/${filterId}/resources/${resourceGuid}`)
    .catch(genericApiErrorHandler);
}

export function updateResourceInFilter(filterId: number, resourceGuid: string, filter) {
  return api
    .put(`/ressurs/filters/${filterId}/resources/${resourceGuid}`)
    .catch(genericApiErrorHandler);
}

export function loadResourceGroups({ filterId = null }: { filterId: number | null }) {
  // TODO: Implement filterId on get groups API-endpoint
  return api
    .get('/ressurs/groups')
    .then(
      ({ data }): Array<ResourceGroup> =>
        data.map((res) => mapResourceGroup(res)).filter((group) => filterId === group.filterId),
    )
    .catch(genericApiErrorHandler);
}

export function createResourceGroup(name: string, filterId: number) {
  return api
    .post('/ressurs/groups', {
      Name: name,
      ResourceFilterId: filterId,
      Users: [],
    })
    .then(({ data }) => api.get(`/ressurs/groups/${data}`))
    .then(({ data }) => mapResourceGroup(data))
    .catch(genericApiErrorHandler);
}

export function updateResourceGroup(
  groupId: number,
  { name, type, filterId }: { name: string; type: string; filterId: number },
) {
  return api
    .put(`/ressurs/groups/${groupId}`, {
      Name: name,
      GroupType: type,
      ResourceFilterId: filterId,
    })
    .catch(genericApiErrorHandler);
}

export function sortResourceGroup(groupId: number, sortedResourceGuids: Array<string>) {
  return api
    .put(`/ressurs/groups/${groupId}/sort`, {
      ResourceGuids: sortedResourceGuids,
    })
    .catch(genericApiErrorHandler);
}

export function removeResourceGroup(groupId: number) {
  return api.delete(`/ressurs/groups/${groupId}`).catch(genericApiErrorHandler);
}

export function addResourceToGroup(groupId: number, resourceGuid: string) {
  return api
    .post(
      `/ressurs/groups/${groupId}/resources/add`,
      {
        ResourceGuid: resourceGuid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .catch(genericApiErrorHandler);
}

export function removeResourceFromGroup(groupId: number, resourceGuid: string) {
  return api
    .delete(`/ressurs/groups/${groupId}/resources/${resourceGuid}`)
    .catch(genericApiErrorHandler);
}

export function updateResourceInGroup(groupId: number, resourceGuid: string, group) {
  return api
    .put(`/ressurs/groups/${groupId}/resources/${resourceGuid}`)
    .catch(genericApiErrorHandler);
}

export function sortResourceGroupInFilter(filterId: number, sortedResourceGuids: Array<string>) {
  return api
    .put(`/ressurs/filters/${filterId}/groups/sort`, {
      ResourceGuids: sortedResourceGuids,
    })
    .catch(genericApiErrorHandler);
}

export function saveDefaultWorkingHours(
  tenantId: number | undefined,
  workhours: DefaultWorkingHours | void,
) {
  return api
    .put(`/Tenants/${tenantId}/WorkHours`, workhours)
    .then(() => {
      toast('Standard arbeidstid lagret');
    })
    .catch((error) => {
      toast('Feil: Kunne ikke lagre arbeidstid');
      genericApiErrorHandler(error);
    });
}

export default {
  createResourceEvent,
  updateResourceEvent,
  loadResourceEventsByGuid,
};
