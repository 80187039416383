<script setup lang="ts">
import { computed, inject, ref, Ref } from 'vue';

const props = defineProps<{
  noClose?: boolean;
  noIcon?: boolean;
  disabled?: boolean;
  danger?: boolean;
  inMenu?: string;
  toMenu?: string;
}>();

const emit = defineEmits(['confirm']);

const close = inject('dropdown-close') as () => void;
const menu = inject('dropdown-menu') as Ref<string>;
const isConfirming = ref(false);

const isVisible = computed(() => {
  if (!menu.value && !props.inMenu) return true;
  if (menu.value == props.inMenu) return true;
  return false;
});

function onConfirm() {
  emit('confirm');
  if (props.toMenu) menu.value = props.toMenu;
  else if (!props.noClose) close();
  isConfirming.value = false;
}
</script>

<template>
  <button
    class="dropdown-item"
    v-if="isVisible && !isConfirming"
    v-on:click="isConfirming = true"
    :class="{
      'dropdown-item--no-icon': noIcon,
      'dropdown-item--disabled': disabled,
      'dropdown-item--danger': danger,
    }"
  >
    <slot name="icon" v-if="!noIcon">
      <div class="icon-spacer"></div>
    </slot>

    <slot></slot>
  </button>

  <div class="dropdown-confirm" v-else-if="isVisible">
    <button
      class="dropdown-confirm__button"
      v-on:click="onConfirm()"
      :class="{ 'dropdown-confirm__button--danger': danger }"
    >
      <df-icon code="f00c" />
    </button>

    <button class="dropdown-confirm__button" v-on:click="isConfirming = false">
      <df-icon code="f00d" />
    </button>
  </div>
</template>

<style scoped>
.dropdown-item {
  display: grid;
  grid-template-columns: [icon] 2rem [content] 1fr;
  width: 100%;
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  text-align: left;
  padding: var(--gap-md);
  gap: var(--gap-md);

  &:hover {
    background-color: var(--color-hover);
  }
}

.dropdown-item--no-icon {
  grid-template-columns: [content] 1fr;
}

.dropdown-item--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown-confirm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dropdown-confirm__button {
  display: flex;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  padding: var(--gap-md);
  gap: var(--gap-md);

  &:hover {
    background-color: var(--color-hover);
  }
}

.dropdown-item--danger,
.dropdown-confirm__button--danger {
  color: var(--color-danger_text);
}
</style>
