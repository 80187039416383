/*
 * OPT "OPTIONS"
 * Opt is a common interface for working with big sortable data
 *
 * See @shared/utilities/opt.ts for helper functions
 *
 * Examples:
 * - List of users
 * - List of projects
 * - Map of projects
 */

export interface Column {
  id: number | string;
  name: string;
  shortName?: string;
  category?: string;
  numeric?: boolean;
  width?: number;
  startOpen?: boolean;
  sortKey?: string;
  filterKey?: string;
  filterList?: Map<any, { name: string; count: number }>;
  filterListGuid?: string;
  filterListOnly?: boolean;
  filterMultiple?: boolean;

  /** Passes the type to backend filtering to limit the filtering to specific types */
  typeId?: number;
  hidden?: boolean;
  show?: (opt: Opt) => boolean;
  infoBlocks?: Array<{
    color: string;
    icon: string;
    title: string;
    description: string;
  }>;
}

export type FilterType = 'lte' | 'gte' | 'lt' | 'gt'; // <= >= < >

export type OptFilter2 = {
  columnId: number;
  type: FilterType;
  value: string;
};

export type ColumnId = number | string;

export enum OptType {
  List,
  Map,
}

export interface OptFilter {
  // TODO: make it possible to say that a filter only applies to a certain source. Required when same opt is used for both Request and MO in single map.
  /** ID of column the filter applies to */
  columnId: ColumnId;

  /** Values used for filtering. Filtering multiple values is treated with OR operator. */
  values: Array<number | string>;
}

export interface OptCommonParts {
  /** Which tenant to request the data from. If the value is null, do not specify tenant (Usually the API makes this your own data) */
  tenantId: number | null;

  /** Filter items based on a single string. In VO this means customId and address. */
  search: string;

  /** Filter items to these properties. */
  filters: Array<OptFilter>;

  /** New implementation that allows comparison operators */
  filters2: Array<OptFilter2>;

  /** IDs of enabled columns */
  columns: Array<number>;

  /** IDs of marked rows/items. */
  marked: Array<number>;

  /** allows us to clear opt if out of date */
  version: number
}

export interface ListOpt extends OptCommonParts {
  /** Type of interface */
  type: OptType.List;

  /** Sort items descending (true) or acending (false) */
  sortDesc: boolean;

  /** Sort items by property. Value depends on what the API-endpoint exposes. */
  sortBy: string;

  /** Offset the items for pagination. */
  offset: number;

  /** Limit results for pagination. */
  limit: number;
}

export interface MapOpt extends OptCommonParts {
  /** Type of interface */
  type: OptType.Map;

  /** [Longitude, Latitude] where the center of the map is. */
  coordinates: [number, number];

  /** Map zoom level */
  zoom: number;
}

export type Opt = ListOpt | MapOpt;

export type OptT<T> = T extends OptType.List ? ListOpt : T extends OptType.Map ? MapOpt : never;
