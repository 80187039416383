<script setup lang="ts">
import { ref, watch } from 'vue';

// Dangerous buttons should be on right side

const props = defineProps<{
  variant?: 'danger' | 'warning' | 'success';
  show?: boolean;
}>();

const emit = defineEmits(['update:show', 'confirm']);

const iShow = ref(!!props.show);

watch(
  () => props.show,
  (val) => {
    iShow.value = val;
  },
);

function update(val?: boolean) {
  iShow.value = !iShow.value;
  emit('update:show', val ?? !iShow.value);
}

function confirm() {
  emit('confirm');
  update();
}
</script>

<template>
  <slot name="trigger" :toggle="() => update()"></slot>

  <df-modal v-if="iShow">
    <div class="dialog-content">
      <header
        class="dialog-message"
        :class="{
          '-warn': variant === 'warning',
          '-danger': variant === 'danger',
          '-success': variant === 'success',
        }"
      >
        <df-icon code="f05a" />

        <slot name="text"></slot>
      </header>

      <div class="dialog-actions">
        <slot name="cancel" :cancel="() => update(false)">
          <df-button v-on:click="update(false)">
            <slot name="cancel-text">Tilbake</slot>
          </df-button>
        </slot>

        <slot name="confirm" :confirm="confirm"></slot>

        <slot name="default"></slot>
      </div>
    </div>
  </df-modal>
</template>

<style scoped>
.dialog-content {
}

.dialog-message {
  padding: var(--gap-md);
  display: grid;
  gap: var(--gap-sm);
  grid-template-columns: max-content 1fr;
  background-color: var(--color-info_bg);
  color: var(--color-info_text);
  font-weight: 500;
}

.dialog-actions {
  padding: var(--gap-md) var(--gap-md);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: var(--gap-md);
}

.-danger {
  background-color: var(--color-danger_bg);
  color: var(--color-danger_text);
}

.-warn {
  background-color: var(--color-warn_bg);
  color: var(--color-warn_text);
}

.-success {
  background-color: var(--color-success_bg);
  color: var(--color-success_text);
}
</style>
