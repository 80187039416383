import { RouteRecordRaw } from 'vue-router';

import RegistrationWeekPage from '/@pages/RegistrationWeekPage/RegistrationWeekPage.vue';
import RegistrationDayPage from '/@pages/RegistrationDayPage/RegistrationDayPage.vue';
import RegistrationCreatePage from '/@pages/RegistrationCreatePage/RegistrationCreatePage.vue';
import RegistrationItemPage from '/@pages/RegistrationItemPage/RegistrationItemPage.vue';
import RegistrationApprovalPage from '/@pages/RegistrationApprovalPage/RegistrationApprovalPage.vue';
import RegistrationErrorPage from '/@pages/RegistrationErrorPage/RegistrationErrorPage.vue';

export const timerRoutes: RouteRecordRaw[] = [
  {
    path: '/timer',
    redirect: '/timer/week',
  },

  {
    path: '/timer/week',
    component: RegistrationWeekPage,
    children: [
      {
        path: ':registrationId',
        component: RegistrationItemPage,
        meta: { isModal: true },
      },
    ],
  },

  {
    path: '/timer/day',
    component: RegistrationDayPage,
    children: [
      {
        path: ':registrationId',
        component: RegistrationItemPage,
        meta: { isModal: true },
      },
    ],
  },

  {
    name: 'NewRegistration',
    path: '/timer/new',
    component: RegistrationCreatePage,
    props: ({ query }) => ({
      projectId: query.projectId != null ? Number(query.projectId) : null,
      createCopy: query.createCopy === 'true',
      userId: query.userId,
      selectUser: query.selectUser === 'true',
    }),
  },

  {
    path: '/timer/registration',
    component: RegistrationCreatePage,
  },

  {
    path: '/timer/registration/:registrationId/edit',
    component: RegistrationCreatePage,
    props: ({ query }) => ({
      createCopy: query.createCopy === 'true',
    }),
  },

  {
    path: '/timer/approval',
    component: RegistrationApprovalPage,
    children: [
      {
        path: ':registrationId',
        component: RegistrationItemPage,
        meta: { isModal: true },
      },
    ],
  },

  {
    path: '/timer/admin',
    component: RegistrationErrorPage,
    children: [
      {
        path: ':registrationId',
        component: RegistrationItemPage,
        meta: { isModal: true },
      },
    ],
  },
];
