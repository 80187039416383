const localeCompare = new Intl.Collator('nb');

const localeDateWeekday = new Intl.DateTimeFormat('nb', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  weekday: 'long',
});

const localeDate = new Intl.DateTimeFormat('nb', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

const localeDateShort = new Intl.DateTimeFormat('nb', {
  month: 'numeric',
  day: 'numeric',
  year: '2-digit',
});

const localTime = new Intl.DateTimeFormat('nb', {
  hour: '2-digit',
  minute: '2-digit',
});

const localTimeDetailed = new Intl.DateTimeFormat('nb', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export function compare(a, b) {
  return localeCompare.compare(a, b);
}

export function isValidDate(date: Date | string) {
  const inputDate = new Date(date);
  return isNaN(inputDate.getTime()) ? false : !!inputDate.getTime();
}

export function date(
  date: Date | string | null,
  { weekday = true, short = false, capitalize = false } = {},
) {
  if (!date || !isValidDate(date)) return;

  let locale = localeDate;

  if (weekday) locale = localeDateWeekday;
  if (short) locale = localeDateShort;

  const returnValue = locale.format(new Date(date));

  if (capitalize) return returnValue.charAt(0).toUpperCase() + returnValue.slice(1);

  return returnValue;
}

export function time(dateString: Date | string, includeSeconds = false) {
  if (!dateString || !isValidDate(dateString)) return;
  if (includeSeconds) return localTimeDetailed.format(new Date(dateString));
  return localTime.format(new Date(dateString));
}
