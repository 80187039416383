<script setup lang="ts">
import { computed, inject } from 'vue';
import { useUserStore } from '/@stores/user';
import { Modules } from '/@types/ids';
import { useTimerStore } from '/@stores/timer';

const toggleNav = inject('toggle-app-nav') as () => void;
const userStore = useUserStore();

const timerStore = useTimerStore();
const rejectedCount = computed(() => timerStore.rejectedRegistrations.size);

const navItems = computed(() => [
  ...(userStore.hasModule(Modules.Timer)
    ? [
        {
          label: 'Uke',
          icon: 'f784',
          to: '/timer/week',
        },
        {
          label: 'Dag',
          icon: 'f783',
          to: '/timer/day',
        },
      ]
    : []),
  ...(userStore.hasModule(Modules.Ressurs) && userStore.hasModule(Modules.Timer)
    ? [
        {
          label: 'Avtaler',
          icon: 'f4fd',
          to: '/resources/events',
        },
      ]
    : []),
  ...(userStore.hasModule(Modules.Ressurs) && !userStore.hasModule(Modules.Timer)
    ? [
        {
          label: 'Alle avtaler',
          icon: 'f4fd',
          to: '/resources/all',
        },
        {
          label: 'Mine avtaler',
          icon: 'f4fd',
          to: '/resources/events',
        },
      ]
    : []),
]);
</script>

<template>
  <nav class="app-shortcuts">
    <button class="shortcut shortcut-menu" v-on:click="toggleNav()">
      <div class="notification-icon" v-if="rejectedCount">{{ rejectedCount }}</div>

      <df-icon code="f0c9" />
    </button>

    <router-link
      class="shortcut"
      v-for="{ label, icon, to } in navItems"
      :key="label"
      :to="to"
      active-class="shortcut--active"
    >
      <df-icon class="shortcut-icon" :code="icon" />

      <div class="shortcut-text">{{ label }}</div>
    </router-link>
  </nav>
</template>

<style scoped>
.app-shortcuts {
  display: grid;
  grid-template-columns: max-content;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: var(--color-cardbg);
  border-top: 1px solid var(--color-divider);
  box-shadow: 0 0 1rem #0004;
  height: calc(var(--shortcut-height) + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
}

.app-shortcuts > *:not(:last-child) {
  border-right: 1px solid var(--color-border);
}

.shortcut {
  display: grid;
  text-transform: uppercase;
  font-weight: 600;
  background: none;
  border: none;
  color: inherit;
  text-decoration: inherit;
  place-items: center;
  align-items: center;
  padding: var(--gap-sm);
  position: relative;
}

.shortcut-text {
  font-size: 0.8rem;
}

.shortcut-icon {
}

.shortcut--active {
  background-color: var(--color-bg);
}

.shortcut-menu {
  width: 4rem;
}

.notification-icon {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--radius-sm);
  font-size: 0.8rem;
  display: flex;
  place-content: center;
  background-color: var(--color-warn_bg);
  color: var(--color-warn_text);
  width: 20px;
  height: auto;
  aspect-ratio: 1 / 1;
}
</style>
